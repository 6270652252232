import React from "react";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import moment, { Moment } from "moment";

import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";

import CheckoutItem from "../CheckoutItem/CheckoutItem";
import TimeCard from "./TimeCard/TimeCard";
import {
  InfoCircleOutlinedIcon,
  LoadingIcon,
  RefreshIcon,
  NoteIcon,
} from "../../CustomIcons/CustomIcons.component";
import WorkHoursTooltip from "./WorkHoursTooltip/WorkHoursTooltip";
import NextWorkDayWidget from "../../WorkDay/NextWorkDayWidget/NextWorkDayWidget";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getTotalDuration } from "../../../utils/time-conversion.util";
import { getWorkShiftSchedule } from "../../../services/work-shift.service";
import { startPopulateWorkTimes } from "../../../actions/work-times.action";

interface WorkTimesProps {
  date: string;
  mode?: CheckoutPanelMode;
  hasExternalTracking: boolean;
}

type WorkShiftSchedule = {
  id: string;
  start_datetime: Moment;
  end_datetime: Moment;
  work_time_seconds: number;
  schedule_notes: string;
};

/**
 *
 * The <WorkTimes /> is the component to display the different types of time
 * for a given day. Each time is represented as "timeSlots" object which contains
 * the "title", "duration", "color" and other additional info for a time slot.
 * The timeSlots array is used by <TimeCard /> component to show the details.
 */
const WorkTimes: React.FC<WorkTimesProps> = ({
  date,
  mode,
  hasExternalTracking,
}) => {
  const dispatch = useDispatch();
  const [workShiftSchedules, setWorkShiftSchedules] = React.useState<
    WorkShiftSchedule[]
  >([]);
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const [workTimes, workTimesLoading] = useWorkTimesByDate(date);
  const [scheduleUpdatedAt, setScheduleUpdatedAt] = React.useState<Moment>();
  const [scheduleLoading, setScheduleLoading] = React.useState(false);
  const [scheduleRefreshing, setScheduleRefreshing] = React.useState(false);
  const [scheduleLoadFailed, setScheduleLoadFailed] = React.useState(false);

  React.useEffect(() => {
    dispatch(startPopulateWorkTimes(date));
  }, [date, dispatch]);

  React.useEffect(() => {
    if (userId) {
      setScheduleLoading(true);
      setScheduleLoadFailed(false);
      getWorkShiftSchedule(date, date, userId)
        .then((response) => {
          if (response.status === 200) {
            setWorkShiftSchedules(response.data);
            setScheduleUpdatedAt(moment());
          } else {
            setScheduleLoadFailed(true);
          }
          setScheduleLoading(false);
        })
        .catch((e) => {
          setScheduleLoading(false);
          setScheduleLoadFailed(true);
        });
    }
  }, [date, userId]);

  const unallocatedTime = workTimes.work_time - workTimes.tasks_allocated_time;

  const inactiveTime =
    workTimes.work_time -
    workTimes.active_time -
    workTimes.manual_time -
    workTimes.processing_time;

  const officeHoursSlots: TimeSlot[] = [
    {
      title: "Work",
      durationInSeconds: workTimes.work_time,
      color: "#40BF00",
    },
    {
      title: "Break",
      durationInSeconds: workTimes.break_time,
      color: "#CCE6FF",
      borderColor: "#0079F2",
    },
  ];
  const tasksHoursSlots: TimeSlot[] = [
    {
      title: "Allocated",
      durationInSeconds: workTimes.tasks_allocated_time,
      color: "#40BF00",
    },
    {
      title: "Unallocated",
      durationInSeconds: unallocatedTime,
      color: "#BFBFBF",
      textColor: "#0000007F",
      hasNote: workTimes.tasks_allocated_time > 0 && unallocatedTime > 0,
      hasWarning: workTimes.tasks_allocated_time === 0,
    },
  ];
  const trackedSlots: TimeSlot[] = [
    {
      title: "Tracked",
      color: "#40BF00",
      children: [
        {
          title: "Active",
          durationInSeconds: workTimes.active_time,
          color: "#00BFBF",
          priority: 1,
        },
        {
          title: "Manual",
          durationInSeconds: workTimes.manual_time,
          color: "#FFAA00",
          priority: 2,
        },
        {
          title: "Processing",
          durationInSeconds: workTimes.processing_time,
          color: "#BFBFBF",
          borderColor: "#0000007F",
          stripe: true,
          priority: 4,
        },
      ],
    },
    {
      title: "Inactive",
      durationInSeconds: inactiveTime,
      color: "#BFBFBF",
      textColor: "#0000007F",
      hasNote: inactiveTime > 0,
      priority: 3,
    },
  ];

  const totalScheduleSeconds = workShiftSchedules.reduce(
    (acc, cur) => acc + cur.work_time_seconds,
    0
  );
  // show the warning icon on all today's schedule if the gap between today's total
  // scheduled hours and the actual work hours is larger than 30 minutes.
  const hasScheduleWarning =
    totalScheduleSeconds - workTimes.work_time >= 30 * 60;
  const todaySlots: TimeSlot[] = workShiftSchedules.flatMap((schedule) => {
    const startTime = moment(schedule.start_datetime);
    const endTime = moment(schedule.end_datetime);
    return [
      {
        title: "Hours",
        durationInSeconds: schedule.work_time_seconds,
        color: "#000",
        hasNote: hasScheduleWarning,
      },
      {
        title: "Shift",
        color: "#000",
        text: startTime.format("HH:mm") + " - " + endTime.format("HH:mm"),
      },
      {
        title: "Note",
        color: "#000",
        text: schedule.schedule_notes,
      },
    ];
  });

  const handleRefresh = async () => {
    if (userId) {
      setScheduleRefreshing(true);
      setScheduleLoadFailed(false);
      try {
        const response = await getWorkShiftSchedule(date, date, userId);
        if (response.status === 200) {
          setWorkShiftSchedules(response.data);
          setScheduleUpdatedAt(moment());
        } else {
          setScheduleLoadFailed(true);
        }
        setScheduleRefreshing(false);
      } catch (e) {
        setScheduleLoading(false);
        setScheduleLoadFailed(true);
      }
    }
  };

  // action to reload the schedule card
  const scheduleAction = scheduleUpdatedAt && (
    <Tooltip
      title={`${
        scheduleLoadFailed ? "Calendar import failed" : "Last import"
      } at ${scheduleUpdatedAt.format(
        "YYYY-MM-DD HH:mm:ss"
      )} - Click to Reload`}
      overlayInnerStyle={{
        fontSize: "12px",
        lineHeight: "17px",
      }}
    >
      <div className="WorkTimes__Action">
        {scheduleLoadFailed ? (
          <NoteIcon
            onClick={handleRefresh}
            className="WorkTimes__Action__Warning"
          />
        ) : (
          <RefreshIcon
            onClick={handleRefresh}
            className={cn("WorkTimes__Action__Refresh", {
              "WorkTimes__Action__Refresh-loading": scheduleRefreshing,
            })}
          />
        )}
      </div>
    </Tooltip>
  );

  return (
    <div className="WorkTimes">
      <CheckoutItem
        className="WorkTimes__WorkHours"
        contentClassName="WorkTimes__Content"
        title="Today's hours"
        icon={
          <Tooltip
            title={
              <WorkHoursTooltip
                work_time={workTimes.work_time}
                allocated_time={workTimes.tasks_allocated_time}
                tracked_time={
                  hasExternalTracking ? workTimes.tracked_time : undefined
                }
              />
            }
          >
            <InfoCircleOutlinedIcon style={{ color: "#0000007F" }} />
          </Tooltip>
        }
      >
        {workTimesLoading ? (
          <LoadingIcon className="WorkTimes__LoadingIcon" />
        ) : (
          getTotalDuration(moment.duration(workTimes.work_time, "seconds"))
        )}
      </CheckoutItem>
      <CheckoutItem className="WorkTimes__Card" title="Office">
        <TimeCard timeSlots={officeHoursSlots} loading={workTimesLoading} />
      </CheckoutItem>
      <CheckoutItem className="WorkTimes__Card" title="Tasks">
        <TimeCard timeSlots={tasksHoursSlots} loading={workTimesLoading} />
      </CheckoutItem>
      {hasExternalTracking && (
        <CheckoutItem
          className="WorkTimes__Card"
          title="Tracked"
          icon={
            <Tooltip
              overlayInnerStyle={{ fontSize: "12px", lineHeight: "17px" }}
              title="Imported from Worksnaps"
            >
              <InfoCircleOutlinedIcon style={{ color: "#0000007F" }} />
            </Tooltip>
          }
        >
          <TimeCard timeSlots={trackedSlots} loading={workTimesLoading} />
        </CheckoutItem>
      )}

      <CheckoutItem
        className="WorkTimes__Card"
        title="Today's Schedule"
        action={scheduleAction}
      >
        {!scheduleLoading && todaySlots.length === 0 ? (
          <div className="WorkTimes__Card__Empty">No Schedule</div>
        ) : (
          <TimeCard
            showTimeBar={false}
            timeSlots={todaySlots}
            loading={scheduleLoading}
          />
        )}
      </CheckoutItem>
      {mode === "leave_the_office" && <NextWorkDayWidget date={date} />}
    </div>
  );
};

export default WorkTimes;
