import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { find, isEmpty } from "lodash";
import { useFeature } from "flagged";

import { history } from "../../../routers/AppRouter";

import useConsolidateTask from "../../../hooks/useConsolidateTask.hook";
import useAsyncConsolidateUserTasks from "../../../hooks/useAsyncConsolidateUserTasks.hook";
import useRedirect from "../../../hooks/useRedirect.hook";

import { InTheOfficeViewContext } from "../../../views/InTheOffice/InTheOffice.view";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import Details from "../../InTheOffice/Details/Details";
import ProjectCardList from "../../Projects/ProjectCardList/ProjectCardList";
import TodayEmptyState from "../../Tasks/TodayEmptyState/TodayEmptyState";
import TaskDetails from "../../Tasks/TaskDetails/TaskDetails";
import TaskPanelHeader from "../TaskPanelHeader/TaskPanelHeader";

import { setTaskDetails } from "../../../actions/task-details.action";
import { TASK_PAGES } from "../../../constants/constants";
import {
  selectTaskDetailsState,
  taskDetailsReducerDefaultState,
} from "../../../reducers/task-details.reducer";
import { selectListOfTask } from "../../../selectors/list.selector";
import { formatUrlTitle } from "../../../utils/url.util";
import { fetchTaskRecordService } from "../../../services/tasks.service";

/**
 * Panel for [Today, Tasks] navigation
 *
 * Default Display
 * Displays ProjectCardList component
 *
 * Functionality
 * Passes down received [filter] prop to child components
 */
const TaskPanel: React.FC<MyDeskTaskPanelProps> = ({ taskPage }) => {
  const redirect = useRedirect();

  const { taskId: paramsTaskId, taskName: paramsTaskName } = useParams<{
    taskId?: string;
    taskName?: string;
  }>();

  const dispatch = useDispatch();

  const taskDetails = useSelector(selectTaskDetailsState);

  const { todayTasks, nextWorkDayTasks, starredTasks } = React.useContext(
    InTheOfficeViewContext
  );

  const [paramsTask, setParamsTask] = React.useState<TaskObject>();
  const [paramsTaskLoading, setParamsTaskLoading] = React.useState(false);

  const useConsolidateTaskArg = React.useMemo(() => {
    return paramsTask ? [paramsTask] : undefined;
  }, [paramsTask]);

  const [
    paramsConsolidatedTaskArray,
    paramsConsolidatedTaskArrayLoading,
  ] = useConsolidateTask(useConsolidateTaskArg);

  const paramsConsolidatedTask = React.useMemo(() => {
    return paramsConsolidatedTaskArray && paramsConsolidatedTaskArray.length > 0
      ? paramsConsolidatedTaskArray[0]
      : undefined;
  }, [paramsConsolidatedTaskArray]);

  const fetchParamsTask = React.useCallback(async () => {
    if (paramsTaskId) {
      setParamsTask(undefined);
      try {
        setParamsTaskLoading(true);
        const response = await fetchTaskRecordService(paramsTaskId);
        if (response.status === 200) {
          setParamsTaskLoading(false);
          setParamsTask(response.data as TaskObject);
        } else {
          throw new Error();
        }
      } catch (e) {
        setParamsTaskLoading(false);
      }
    }
  }, [paramsTaskId]);

  const [
    consolidatedTodayTasks,
    consolidatedTodayTasksLoading,
  ] = useAsyncConsolidateUserTasks(todayTasks);
  const [
    consolidatedNextWorkDayTasks,
    consolidatedNextWorkDayTasksLoading,
  ] = useAsyncConsolidateUserTasks(nextWorkDayTasks);
  const [
    consolidatedStarredTasks,
    consolidatedStarredTasksLoading,
  ] = useAsyncConsolidateUserTasks(starredTasks);

  const isLoading =
    consolidatedTodayTasksLoading ||
    consolidatedNextWorkDayTasksLoading ||
    consolidatedStarredTasksLoading;
  const urlTaskPage = new URLSearchParams(history.location.search).get("p");

  const showTodayEmptyState = React.useMemo(() => {
    return (
      taskPage === TASK_PAGES.TODAY && !!todayTasks && todayTasks.length === 0
    );
  }, [taskPage, todayTasks]);

  const paramsConsolidatedTaskInURLTaskPage = React.useMemo(() => {
    // check if task in param exists in correct my desk page
    if (!!paramsConsolidatedTask && !!urlTaskPage) {
      switch (urlTaskPage) {
        case TASK_PAGES.TODAY:
          return consolidatedTodayTasks!.find(
            ({ id }) => id === paramsConsolidatedTask.id
          );
        case TASK_PAGES.NEXT_SHIFT:
          return consolidatedNextWorkDayTasks!.find(
            ({ id }) => id === paramsConsolidatedTask.id
          );
        case TASK_PAGES.STARRED:
          return consolidatedStarredTasks!.find(
            ({ id }) => id === paramsConsolidatedTask.id
          );
        default:
      }
    }
  }, [
    paramsConsolidatedTask,
    urlTaskPage,
    consolidatedTodayTasks,
    consolidatedNextWorkDayTasks,
    consolidatedStarredTasks,
  ]);

  React.useEffect(() => {}, [
    useConsolidateTaskArg,
    paramsConsolidatedTaskArray,
  ]);

  React.useEffect(() => {
    fetchParamsTask();
  }, [fetchParamsTask]);

  const handleTaskSelect = ({ id: taskId, title: taskTitle }: TaskObject) => {
    redirect({
      path: `/t/${taskId}/${formatUrlTitle(taskTitle)}/activity`,
      searchParams: `p=${taskPage}`,
    });
  };

  React.useEffect(() => {
    if (
      (!paramsConsolidatedTaskArrayLoading &&
        !!paramsConsolidatedTaskInURLTaskPage) ||
      !paramsTask ||
      !urlTaskPage
    )
      dispatch(setTaskDetails(taskDetailsReducerDefaultState));
  }, [
    dispatch,
    paramsConsolidatedTaskInURLTaskPage,
    paramsConsolidatedTaskArrayLoading,
    paramsTask,
    urlTaskPage,
  ]);

  React.useEffect(() => {
    if (
      !paramsConsolidatedTaskArrayLoading &&
      !!paramsConsolidatedTaskInURLTaskPage
    ) {
      dispatch(setTaskDetails(paramsConsolidatedTaskInURLTaskPage));
    } else if (
      !paramsConsolidatedTaskArrayLoading &&
      !paramsConsolidatedTaskInURLTaskPage
    ) {
      const taskList = paramsConsolidatedTaskInURLTaskPage
        ? selectListOfTask(paramsConsolidatedTaskInURLTaskPage)
        : undefined;
      if (taskList) {
        const { id: listId, title: listTitle } = taskList;
        redirect({
          path: `/l/${listId}/${formatUrlTitle(
            listTitle
          )}/${paramsTaskId}/${paramsTaskName}/activity`,
        });
      }
    }
  }, [
    dispatch,
    paramsConsolidatedTaskInURLTaskPage,
    paramsConsolidatedTaskArrayLoading,
    paramsTaskId,
    paramsTaskName,
    redirect,
  ]);

  return (
    <>
      <div className="TaskPanel">
        <TaskPanelHeader />
        {isLoading && (
          <div className="TaskPanel__Loading">
            <LoadingIcon />
          </div>
        )}
        {!isLoading && showTodayEmptyState && <TodayEmptyState />}
        {!isLoading && !showTodayEmptyState && (
          <ProjectCardList
            onTaskSelect={handleTaskSelect}
            taskPage={taskPage}
          />
        )}
      </div>

      {!paramsTaskLoading && !paramsConsolidatedTaskArrayLoading ? (
        !isEmpty(taskDetails) && <TaskDetails task={taskDetails} />
      ) : (
        <Details
          loading={paramsTaskLoading || paramsConsolidatedTaskArrayLoading}
        />
      )}
    </>
  );
};

export { TaskPanel as default };
