import React from "react";

import { getTasksListService } from "../services/tasks.service";

export default function useAsyncSearchTaskArrayByTitle(
  search: TaskObject["title"]
) {
  const [searchedTaskArray, setSearchedTaskArray] = React.useState<TaskArray>(
    []
  );
  const [loading, setLoading] = React.useState<LoadingState>("false");

  React.useEffect(() => {
    async function fetchSearchTaskList() {
      try {
        setLoading("true");
        const response = await getTasksListService({
          search,
        });
        if (response.status === 200 && response.data.results.length > 0) {
          setLoading("success");
          const taskArray: TaskArray = response.data.results;
          if (taskArray.length > 0) {
            setSearchedTaskArray(
              taskArray.sort((a, b) => {
                return a.title.localeCompare(b.title);
              })
            );
          } else {
            setSearchedTaskArray([]);
          }
        } else {
          setLoading("error");
        }
      } catch (e) {
        setLoading("error");
      }
    }
    if (search) {
      fetchSearchTaskList();
    } else {
      setSearchedTaskArray([]);
    }
  }, [search]);

  const returnValue: [TaskArray, string] = [searchedTaskArray, loading];

  return returnValue;
}
