import { Select, Button } from "antd";
import React from "react";
import { groupBy } from "lodash";
import { useSelector } from "react-redux";

import CheckoutStatusMessage from "./CheckoutStatusMessage";
import { selectCheckoutChecksState } from "../../../reducers/checkout.reducer";

const { Option } = Select;
const CheckoutStatusMessages: React.FC<CheckoutStatusMessagesProps> = ({
  statuses,
  onDismiss,
  onClose,
}) => {
  const checkoutChecks = useSelector(selectCheckoutChecksState).data;
  const [dismissing, setDismissing] = React.useState(false);
  const [filter, setFilter] = React.useState<string>("all");

  const groups = groupBy(statuses, "work_day.work_date");
  const messages: CheckoutStatusMessage[] = Object.keys(groups).map((date) => {
    const msgs = groups[date];
    return {
      date,
      messages: msgs
        .filter((msg) => !!msg.issue_json)
        .map((msg) => ({
          id: msg.id,
          checkoutCheckId: msg.checkout_check,
          message: msg.issue_json!.checkout_failed_message,
        })),
    };
  });

  const handleFilterChange = (value: any) => {
    setFilter(value);
  };

  const handleDismissAll = async () => {
    setDismissing(true);
    const allIds = statuses.map(s => s.id);
    await onDismiss(allIds);
    setDismissing(false);
    onClose();
  };

  const messagesJsx = messages
    .map((item) => ({
      ...item,
      messages: item.messages.filter(
        (msg) => filter === "all" || msg.checkoutCheckId === filter
      ),
    }))
    .filter((item) => item.messages.length > 0)
    .map((item) => (
      <CheckoutStatusMessage
        key={item.date}
        message={item}
        onDismiss={onDismiss}
      />
    ));

  const options = checkoutChecks.map((type) => (
    <Option key={type.id} value={type.id}>
      {type.title}
    </Option>
  ));
  return (
    <div className="CheckoutStatusMessages">
      <div className="CheckoutStatusMessages__Header">
        <span className="CheckoutStatusMessages__Title">
          Checkout status messages
        </span>
        <div className="CheckoutStatusMessages__Extra">
          <Select
            className="CheckoutStatusMessages__Select"
            defaultValue="all"
            onChange={handleFilterChange}
          >
            <Option value="all">All types</Option>
            {options}
          </Select>
          <Button
            className="CheckoutStatusMessages__Button"
            size="small"
            loading={dismissing}
            onClick={handleDismissAll}
          >
            Dismiss all
          </Button>
        </div>
      </div>
      <div className="CheckoutStatusMessages__Messages">{messagesJsx}</div>
    </div>
  );
};

export default CheckoutStatusMessages;
