import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";

import { history } from "../../../routers/AppRouter";

import HistoryTabPane from "./HistoryTabPane/HistoryTabPane";
import ScheduleTabPane from "./ScheduleTabPane/ScheduleTabPane";
import TodayTabPane from "./TodayTabPane/TodayTabPane";

import { teamPanelTabs } from "../../../constants/team.constant";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getObservableUsersService } from "../../../services/observable_users.service";

import ws from "../../../sockets/websockets";
import { isEmpty } from "lodash";

const { TabPane } = Tabs;

const getTabPaneContent = (tab: string) => {
  switch (tab) {
    case "Today":
      return <TodayTabPane />;
    case "History":
      return <HistoryTabPane />;
    case "Schedule":
    default:
      return <ScheduleTabPane />;
  }
};

export const TeamPanelContext = React.createContext<TeamPanelContext>(
  {} as TeamPanelContext
);

const TeamPanel = () => {
  const userDetails = useSelector(selectUserDetailsState);

  const [
    observableUsers,
    setObservableUsers,
  ] = React.useState<ObservableUserArray>([]);
  const [
    selectedObservableUser,
    setSelectedObservableUser,
  ] = React.useState<ObservableUserObject>();

  const shownObservableUsers = React.useMemo(() => {
    if (selectedObservableUser) {
      return [selectedObservableUser];
    } else {
      return observableUsers.filter(({ shown_yn }) => shown_yn);
    }
  }, [observableUsers, selectedObservableUser]);

  const fetchObservableUsers = React.useCallback(async () => {
    try {
      const response = await getObservableUsersService();
      if (response.status === 200) {
        setObservableUsers(response.data);
      }
    } catch (e) {}
  }, []);

  React.useEffect(() => {
    fetchObservableUsers();

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType.trim()) {
          case "created_or_updated_observable_user":
            fetchObservableUsers();
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);
    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchObservableUsers]);

  React.useEffect(() => {
    if (
      !isEmpty(userDetails.data) &&
      !userDetails.data.team_page_access_granted_yn
    ) {
      history.goBack();
    }
  }, [userDetails.data]);

  return (
    <TeamPanelContext.Provider
      value={{
        selectedObservableUser,
        setSelectedObservableUser,
        observableUsers,
        setObservableUsers,
        shownObservableUsers,
      }}
    >
      <div className="TeamPanel">
        <Tabs className="TeamPanel__Tabs" defaultActiveKey="1">
          {teamPanelTabs.map((tab, index) => (
            <TabPane className="TeamPanel__Tabs__TabPane" tab={tab} key={index}>
              {getTabPaneContent(tab)}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </TeamPanelContext.Provider>
  );
};

export default TeamPanel;
