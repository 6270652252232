import { Button } from "antd";
import React from "react";
import moment from "moment";

import { CheckoutPanelContext } from "../CheckoutPanel/CheckoutPanel";
import { workDayDateFormat } from "../../../constants/constants";

const CheckoutStatusMessage: React.FC<CheckoutStatusMessageProps> = ({
  message,
  onDismiss,
}) => {
  const checkoutPanelContext = React.useContext(CheckoutPanelContext);
  const { setCheckoutDate } = checkoutPanelContext;
  const [visible, setVisible] = React.useState(false);
  const [dismissing, setDismissing] = React.useState(false);

  const messages = message.messages.map((item, index) => (
    <div key={index} className="CheckoutStatusMessage__Message">
      {item.message}
    </div>
  ));

  const handleDateClick = () => {
    setCheckoutDate(moment(message.date).format(workDayDateFormat));
  };
  const handleDismiss = async () => {
    setDismissing(true);
    const ids = message.messages.map((msg) => msg.id);
    await onDismiss(ids);
    setDismissing(false);
  };
  return (
    <div
      className="CheckoutStatusMessage"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="CheckoutStatusMessage__Content">
        <div className="CheckoutStatusMessage__Date" onClick={handleDateClick}>
          {message.date}
        </div>
        <div className="CheckoutStatusMessage__Messages">{messages}</div>
      </div>
      {visible && (
        <Button
          className="CheckoutStatusMessage__Button"
          size="small"
          loading={dismissing}
          onClick={handleDismiss}
        >
          Dismiss
        </Button>
      )}
    </div>
  );
};

export default CheckoutStatusMessage;
