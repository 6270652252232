import { Dispatch } from "redux";

import { getStatusHistoryService } from "../services/status-history.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchStatusHistory = () => ({
  type: "REQUEST_FETCH_STATUS_HISTORY",
});
export const requestFetchStatusHistorySuccess = (
  statusHistory: StatusHistoryArray
) => ({
  type: "REQUEST_FETCH_STATUS_HISTORY_SUCCESS",
  statusHistory,
});
export const requestFetchStatusHistoryFailed = (error: string) => ({
  type: "REQUEST_FETCH_STATUS_HISTORY_FAILED",
  error,
});
export const startPopulateStatusHistory = (
  data?: GetStatusHistoryServiceData
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchStatusHistory());
      const response = await getStatusHistoryService(data);
      if (response.status === 200) {
        dispatch(requestFetchStatusHistorySuccess(response.data));
      } else {
        const error = "getStatusHistoryService status is not 200";
        dispatch(requestFetchStatusHistoryFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchStatusHistoryFailed(error));
      dispatchError({
        e,
        title: "Populate status history error",
      });
    }
  };
};
