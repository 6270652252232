import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

export interface GetProjectsServiceData {
  archived_yn?: ProjectObject["archived_yn"];
  workspace?: ProjectObject["workspace"];
  search?: ProjectObject["title"];
}
export type GetProjectsServiceResponse = Promise<AxiosResponse<ProjectArray>>;
export type GetProjectsService = (
  data?: GetProjectsServiceData
) => GetProjectsServiceResponse;
export const getProjectsListService: GetProjectsService = (data) => {
  if (data) {
    return axios.get("/api/projects/project/", {
      params: {
        ...data,
      },
    });
  } else {
    return axios.get("/api/projects/project/");
  }
};

export type CreateProjectService = (
  payload: ProjectObject
) => Promise<AxiosResponse<any>>;
export const createProjectService: CreateProjectService = (payload) => {
  return axios.post("/api/projects/project/", payload);
};

export type UpdateProjectService = (
  payload: ProjectObject
) => Promise<AxiosResponse<any>>;
export const updateProjectService: UpdateProjectService = (payload) => {
  const { id, ...rest } = payload;
  return axios.patch(`/api/projects/project/${id}/`, rest);
};

/**
 * Project Workday Services
 */
interface RemoveProjectFromWorkdayServiceData {
  project: ProjectObject["id"];
  user: UserDetailsObject["id"];
  work_day: WorkDayObject["id"];
}
interface RemoveProjectFromWorkdayServiceResponse {
  project: ProjectObject["id"];
  user: UserDetailsObject["id"];
  work_day: WorkDayObject["id"];
}
type RemoveProjectFromWorkdayService = (
  data: RemoveProjectFromWorkdayServiceData
) => Promise<AxiosResponse<RemoveProjectFromWorkdayServiceResponse>>;

export const addProjectToWorkDayService: RemoveProjectFromWorkdayService = (
  data
) => axios.post(`/api/work/workday_project/`, data);

export const removeProjectFromWorkDayService: RemoveProjectFromWorkdayService = (
  data
) => axios.delete(`/api/work/workday_project/`, { data });
