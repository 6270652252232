import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { getInitials } from "../../../utils/string.util";

type MemberOptionProps = {
  avatar: string;
  name: string;
  type: "project" | "list";
  onMemberAdd: () => Promise<void>;
};
const MemberOption = ({
  avatar,
  name,
  type,
  onMemberAdd,
}: MemberOptionProps) => {
  return (
    <div className="MemberOption">
      <div className="MemberOption__Info">
        <CustomAvatar
          className="MemberOption__FallbackText"
          size={20}
          src={avatar}
          fallbackText={getInitials(name)}
        />
        <span>{name}</span>
      </div>
      <Button
        className="MemberOption__btn"
        size="small"
        icon={<PlusOutlined style={{ color: "#40BF00" }} />}
        onClick={() => onMemberAdd()}
      >
        {type === "project" ? "Add to project" : "Add to task list"}
      </Button>
    </div>
  );
};

export default MemberOption;
