import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { Feature } from "flagged";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import Notifications from "../../Notifications/Notifications/Notifications";
import InTheOfficeBtn from "../../WorkStatus/InTheOfficeBtn/InTheOfficeBtn";
import ToggleWorkStatusBtn from "../../WorkStatus/ToggleWorkStatusBtn/ToggleWorkStatusBtn";
import Workspace from "../../Workspace/Workspace.component";

/**
 * Default Display
 * Displays company initials (currently static)
 * Displays InTheOffice component
 * Displays ToggleWorkStatusBtn component
 */
const VerticalMenuHeader = () => {
  const { workspace_name = "" } = useSelector(selectUserDetailsState).data;
  const [open, setOpen] = React.useState(false);
  const workspaceInitial = workspace_name
    .split(" ")
    .slice(0, 2)
    .map((s) => s[0])
    .join("");

  const toggleWorkspaceModal = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="VerticalMenuHeader">
      <div className="VerticalMenuHeader__Top">
        <div
          className="VerticalMenuHeader__Top__Select"
          onClick={toggleWorkspaceModal}
        >
          <Avatar
            style={{ backgroundColor: "#FFFFFF40", verticalAlign: "middle" }}
            size="large"
            gap={4}
            className="VerticalMenuHeader__Top__Select__Avatar"
          >
            {workspaceInitial}
          </Avatar>
          <span className="VerticalMenuHeader__Top__Select__Text">
            {workspace_name} <CaretDownIcon />
          </span>
        </div>
        <Feature name="under_development">
          <Notifications />
        </Feature>
      </div>
      <Workspace
        open={open}
        name={workspace_name}
        avatar={workspaceInitial}
        onToggle={toggleWorkspaceModal}
        onClose={() => setOpen(false)}
      />
      <InTheOfficeBtn />
      <ToggleWorkStatusBtn />
    </div>
  );
};

export { VerticalMenuHeader as default };
