import { reducerExtendedBaseDefaultState } from "../constants/constants";
import { RootState } from "../store/configureStore";

export interface WorkDaysState extends ExtendedBaseReducerState {
  data: WorkDayArray;
}
export const selectWorkDaysState = (rootState: RootState) => rootState.workDays;
export const workDaysReducerDefaultState: WorkDaysState = {
  ...reducerExtendedBaseDefaultState,
  data: [],
};
export const workDaysReducer = (
  state = workDaysReducerDefaultState,
  action: {
    type: string;
    workDays: WorkDayArray;
    workDay: WorkDayObject;
    workDayId: WorkDayObject["id"];
    updatedKeys: (keyof WorkDayObject)[];
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_WORK_DAYS":
      return {
        ...state,
        error: "",
        fetchInProgress: true,
      };
    case "REQUEST_FETCH_WORK_DAYS_SUCCESS":
      return {
        ...state,
        data: action.workDays,
        error: "",
        fetchInProgress: false,
      };
    case "REQUEST_FETCH_WORK_DAYS_FAILED":
      return {
        ...state,
        error: action.error,
        fetchInProgress: false,
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY":
      return {
        ...state,
        error: "",
        updateInProgress: {
          inProgress: true,
          recordId: action.workDayId,
          updatedKeys: action.updatedKeys,
        },
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.workDay.id) {
            return action.workDay;
          }
          return item;
        }),
        error: "",
        updateInProgress: {
          inProgress: false,
          recordId: action.workDay.id,
        },
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED":
      return {
        ...state,
        error: action.error,
        updateInProgress: {
          inProgress: false,
          recordId: action.workDayId,
        },
      };
    case "REQUEST_CREATE_WORK_DAY":
      return {
        ...state,
        error: "",
        createInProgress: {
          inProgress: true,
        },
      };
    case "REQUEST_CREATE_WORK_DAY_SUCCESS":
      return {
        ...state,
        data: [...state.data, action.workDay],
        error: "",
        createInProgress: {
          inProgress: false,
          recordId: action.workDay.id,
        },
      };
    case "REQUEST_CREATE_WORK_DAY_FAILED":
      return {
        ...state,
        error: action.error,
        createInProgress: {
          inProgress: false,
          recordId: action.workDayId,
        },
      };
    default:
      return state;
  }
};

export interface CurrentWorkDayState extends BaseReducerState {
  data: WorkDayObject;
}
export const selectCurrentWorkDayState = (rootState: RootState) =>
  rootState.currentWorkDay;
export const currentWorkDayReducerDefaultState: CurrentWorkDayState = {
  data: {} as WorkDayObject,
  error: "",
  loading: false,
};
export const currentWorkDayReducer = (
  state = currentWorkDayReducerDefaultState,
  action: { type: string; currentWorkDay: WorkDayObject; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CURRENT_WORK_DAY":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CURRENT_WORK_DAY_SUCCESS":
      return {
        data: action.currentWorkDay,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CURRENT_WORK_DAY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "SET_CURRENT_WORK_DAY_DEFAULT":
      return currentWorkDayReducerDefaultState;
    default:
      return state;
  }
};

export const selectCheckoutWorkDayState = (rootState: RootState) =>
  rootState.checkoutWorkDay;
export const checkoutWorkDayReducerDefaultState = {} as WorkDayObject;
export const checkoutWorkDayReducer = (
  state = checkoutWorkDayReducerDefaultState,
  action: { type: string; checkoutWorkDay: WorkDayObject }
) => {
  switch (action.type) {
    case "SET_CHECKOUT_WORK_DAY":
      return action.checkoutWorkDay;
    default:
      return state;
  }
};
