import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { FlagsProvider } from "flagged";

import useCheckBetaVersion from "../hooks/useCheckBetaVersion.hook";
import useRedirect from "../hooks/useRedirect.hook";

import { defaultPage, workStatuses } from "../constants/constants";
import { selectStatusState } from "../reducers/status.reducer";

export const PublicRoute = ({ children, ...rest }: RouteProps) => {
  const redirect = useRedirect();
  // check if using beta version
  const isBetaVersion = useCheckBetaVersion();

  const isAuthenticated = useSelector(
    (state: RootStateOrAny) => !!state.auth.data.token
  );

  const {
    data: { id: currentStatusId },
  } = useSelector(selectStatusState);

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (
      currentStatusId === workStatuses.out_of_office &&
      pathname !== "/start-work"
    ) {
      redirect({
        path: "/start-work",
      });
    } else if (
      (currentStatusId === workStatuses.working ||
        currentStatusId === workStatuses.taking_break) &&
      pathname === "/start-work"
    ) {
      redirect({
        path: defaultPage,
      });
    }
  }, [currentStatusId, pathname, redirect]);

  return (
    <FlagsProvider
      features={{
        under_development: isBetaVersion,
      }}
    >
      <Route {...rest}>
        {isAuthenticated ? <Redirect to="/start-work" /> : children}
      </Route>
    </FlagsProvider>
  );
};

export { PublicRoute as default };
