import React from "react";
import { useSelector } from "react-redux";
import { Tooltip, Popover, Input } from "antd";
import moment, { Moment } from "moment";
import cn from "classnames";

import Button from "../../Button/Button";
import {
  NoteIcon,
  AddIcon,
  RefreshIcon,
  InfoCircleOutlinedIcon,
  DraftIcon,
  DeleteIcon,
} from "../../CustomIcons/CustomIcons.component";
import Modal from "../../General/Modal/Modal";
import ShiftCard from "./ShiftCard";
import ShiftTimelineCard from "./ShiftTimelineCard";
import ShiftPopover from "./ShiftPopover";
import DeleteShiftPopover from "./DeleteShiftPopover";
import { getStatusHistoryService } from "../../../services/status-history.service";
import {
  WorkStatusTypesEnum,
  ChangedValueTypeEnum,
} from "../../../constants/constants";
import {
  modifyShiftService,
  updateShiftService,
} from "../../../services/work-shift.service";
import { getTimeTrackedService } from "../../../services/third-party-tracking.service";
import { selectThirdPartyTrackerState } from "../../../reducers/third-party-tracking.reducer";
import ws from "../../../sockets/websockets";
import { addTimeline, aggregateTimelines } from "../../../utils/timeline.util";

const { TextArea } = Input;

const convertStatus = (status: string) => {
  switch (status) {
    case "working":
      return "work";
    case "taking_break":
      return "break";
    default:
      return status;
  }
};

const fetchTrackedTimelines = async (
  userId: string,
  trackerId: string,
  shift: WorkShiftSummary
) => {
  try {
    const response = await getTimeTrackedService({
      user: userId,
      time_tracker: trackerId,
      shift_id: shift.id,
    });
    if (response.status === 200) {
      const timelines = response.data.map((item: any) => ({
        start: moment(item.start_datetime),
        end: moment(item.end_datetime),
        type: convertStatus(item.type_enum),
      }));
      return aggregateTimelines(timelines);
    }
    return [];
  } catch (e) {
    return [];
  }
};

/**
 * The <ShiftModal /> is the component to create or edit a shift.
 * It includes the <ShiftCard /> to show the base shift information
 * and the <ShiftTimelineCard /> to show the detials timelines of this shift.
 * User can add / edit / delete the shift time or add notes from this component.
 */
const ShiftModal: React.FC<ShiftModalProps> = ({
  workdayId,
  checkoutDate,
  userId,
  open,
  edit = false,
  start,
  end,
  shift,
  shiftId,
  syncing,
  onClose,
  onSync,
  onDelete,
  onRefreshShifts,
}) => {
  const [workBreakTimelines, setWorkBreakTimelines] = React.useState<
    Timeline[]
  >([]);
  const [
    loadingWorkBreakTimelines,
    setLoadingWorkBreakTimelines,
  ] = React.useState(false);
  const [trackedTimelines, setTrackedTimelines] = React.useState<Timeline[]>(
    []
  );
  const [loadingTrackedTimelines, setLoadingTrackedTimelines] = React.useState(
    false
  );
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const thirdPartyTracker = useSelector(selectThirdPartyTrackerState).data;
  const { id: thirdPartyTrackerId } = thirdPartyTracker;
  const [notes, setNotes] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const shiftTimeSlots = [
    {
      title: "Work",
      durationInSeconds:
        edit && shift
          ? shift.work_time
          : moment.duration(moment(end).diff(moment(start))).asSeconds(),
      color: "#40BF00",
    },
    {
      title: "Break",
      durationInSeconds: edit && shift ? shift.break_time : 0,
      color: "#0079F2",
    },
  ];

  const trackedTimeSlots: TimeSlot[] = [
    {
      title: "Active",
      durationInSeconds: edit && shift ? shift.active_time : 0,
      color: "#00BFBF",
    },
    {
      title: "Manual",
      durationInSeconds: edit && shift ? shift.manual_time : 0,
      color: "#FF8000",
    },
    {
      title: "Inactive",
      durationInSeconds: edit && shift ? shift.inactive_time : 0,
      color: "#0000007F",
    },
    {
      title: "Processing",
      durationInSeconds: edit && shift ? shift.processing_time : 0,
      color: "#0000007F",
      hasNote: true,
      noteText:
        "While time is still being processed, it is tentatively counted as tracked time.",
    },
  ];
  const shiftStartTime = edit ? moment(shift?.start_datetime) : start!;
  const shiftEndTime = edit
    ? shift?.end_datetime
      ? moment(shift.end_datetime)
      : null
    : end!;
  const shiftTimelines: Timeline[] =
    edit || !start || !end
      ? workBreakTimelines
      : [
          {
            start,
            end,
            type: "work",
          },
        ];

  // fetch work-break timelines
  React.useEffect(() => {
    if (open && shift) {
      setLoadingWorkBreakTimelines(true);
      getStatusHistoryService({ work_shift: shift.id })
        .then((response) => {
          if (response.status === 200) {
            const timelines = response.data.map((item: any) => ({
              start: moment(item.start_datetime),
              end: item.end_datetime ? moment(item.end_datetime) : null,
              type: convertStatus(item.status_enum_identifier),
            }));
            const aggTimelines = aggregateTimelines(timelines);
            setWorkBreakTimelines(aggTimelines);
          }
          setLoadingWorkBreakTimelines(false);
        })
        .catch((e) => setLoadingWorkBreakTimelines(false));
    }
    return () => {
      setWorkBreakTimelines([]);
    };
  }, [open, shift]);

  // fetch tracked timelines
  React.useEffect(() => {
    if (open && shift && userId && thirdPartyTrackerId) {
      setLoadingTrackedTimelines(true);
      fetchTrackedTimelines(userId, thirdPartyTrackerId, shift).then(
        (timelines) => {
          setTrackedTimelines(timelines);
          setLoadingTrackedTimelines(false);
        }
      );
    }
    return () => {
      setTrackedTimelines([]);
    };
  }, [open, userId, shift, thirdPartyTrackerId]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        if (eventType === "time_entries_synced" && shift) {
          const timelines = await fetchTrackedTimelines(
            userId,
            thirdPartyTrackerId,
            shift
          );
          setTrackedTimelines(timelines);
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [userId, thirdPartyTrackerId, shift]);

  const shiftNotes = shift?.work_shift_notes || "";
  React.useEffect(() => {
    if (open) {
      setNotes(shiftNotes);
    }
  }, [shiftNotes, open]);

  const handleRefresh = () => {
    if (!syncing) {
      onSync();
    }
  };

  // TODO: disable edit shift start and end time button
  const handleStartChange = (value: Moment) => {};
  const handleEndChange = (value: Moment) => {};

  const handleAddStatus = async (
    start: Moment,
    end: Moment,
    type: TimelineType
  ) => {
    if (!shift) {
      return;
    }
    const newTimelines = addTimeline(workBreakTimelines, { start, end, type });
    const aggragatedTimelines = aggregateTimelines(newTimelines);
    const statusList = aggragatedTimelines.map((timeline) => {
      let status = {
        user: userId,
        work_shift: shift.id,
        start_datetime: timeline.start,
        status_enum_identifier:
          timeline.type === "work"
            ? WorkStatusTypesEnum.working
            : WorkStatusTypesEnum.taking_break,
      };
      if (timeline.end) {
        status = {
          ...status,
          end_datetime: timeline.end,
        } as any;
      }
      return status;
    });
    let workShift = {
      id: shift.id,
      work_day: workdayId,
      start_datetime: moment(shift.start_datetime),
    };
    if (shift.end_datetime) {
      workShift = {
        ...workShift,
        end_datetime: moment(shift.end_datetime),
      } as any;
    }
    const payload = {
      work_shift: workShift,
      status_list: statusList,
      change_log: {
        modified_user: userId,
        changed_value_type_enum: ChangedValueTypeEnum.status_hist_add,
        content_object: workdayId,
        changed_datetime: moment(),
      },
    } as any;
    const result = await modifyShiftService(payload);
    if (result.success) {
      await onRefreshShifts();
    }
  };

  const handleDeleteShift = async () => {
    if (shift) {
      await onDelete(shift.id);
    } else if (shiftId) {
      await onDelete(shiftId);
    }
  };

  const handleNotesChange = (e: any) => {
    setNotes(e.target.value);
  };

  const handleModalClose = async () => {
    if (!shift) {
      onClose();
      return;
    }
    if (shift.work_shift_notes === notes) {
      onClose();
      return;
    }
    const payload = {
      id: shift.id,
      work_shift_notes: notes,
    };
    setLoading(true);
    const result = await updateShiftService(payload);
    if (result.success) {
      await onRefreshShifts();
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      className="ShiftModal"
      title={edit ? "Edit Shift" : "Add Manual Shift"}
      prefixText={
        !edit ? (
          <>
            <NoteIcon />
            <span>Confirm your work and break times</span>
          </>
        ) : undefined
      }
      centered={true}
      visible={open}
      onCancel={onClose}
      footer={null}
      width={736}
      destroyOnClose={true}
    >
      <div className="ShiftModal__Content">
        <div className="ShiftModal__Row">
          <div className="ShiftModal__Column">
            <div className="ShiftModal__Column__Header">
              <div className="ShiftModal__Column__Title">Shift</div>
              <div className="ShiftModal__Column__Extra">
                <Popover
                  destroyTooltipOnHide={true}
                  getPopupContainer={(trigger: any) => trigger}
                  trigger="click"
                  placement="bottom"
                  content={
                    <ShiftPopover
                      type="work"
                      defaultStart={shiftStartTime}
                      defaultEnd={shiftEndTime}
                      min={shiftStartTime}
                      max={shiftEndTime}
                      onSave={(start, end) =>
                        handleAddStatus(start, end, "work")
                      }
                    />
                  }
                >
                  <Button
                    className="ShiftModal__Column__Extra__Btn"
                    size="small"
                    icon={
                      <AddIcon className="ShiftModal__Column__Extra__Work" />
                    }
                  >
                    Add Work
                  </Button>
                </Popover>
                <Popover
                  destroyTooltipOnHide={true}
                  getPopupContainer={(trigger: any) => trigger}
                  trigger="click"
                  placement="bottom"
                  content={
                    <ShiftPopover
                      type="break"
                      defaultStart={shiftStartTime}
                      defaultEnd={shiftEndTime}
                      min={shiftStartTime}
                      max={shiftEndTime}
                      onSave={(start, end) =>
                        handleAddStatus(start, end, "break")
                      }
                    />
                  }
                >
                  <Button
                    className="ShiftModal__Column__Extra__Btn"
                    size="small"
                    icon={
                      <AddIcon className="ShiftModal__Column__Extra__Break" />
                    }
                  >
                    Add Break
                  </Button>
                </Popover>
              </div>
            </div>
            <ShiftCard timeSlots={shiftTimeSlots} />
          </div>
          <div className="ShiftModal__Column">
            <div className="ShiftModal__Column__Header">
              <div className="ShiftModal__Column__Left">
                <div className="ShiftModal__Column__Title">Tracked</div>
                <Tooltip
                  overlayInnerStyle={{ fontSize: "12px", lineHeight: "17px" }}
                  title="Imported from Worksnaps"
                >
                  <InfoCircleOutlinedIcon className="ShiftModal__Column__Note" />
                </Tooltip>
              </div>
              <div className="ShiftsTable__Extra-fresh">
                <RefreshIcon
                  onClick={handleRefresh}
                  className={cn("ShiftsTable__Extra-freshIcon", {
                    "ShiftsTable__Extra-freshIcon-loading": syncing,
                  })}
                />
              </div>
            </div>
            <ShiftCard timeSlots={trackedTimeSlots} />
          </div>
        </div>
        <div className="ShiftModal__Row">
          <div className="ShiftModal__Column">
            <ShiftTimelineCard
              timelines={shiftTimelines}
              loading={loadingWorkBreakTimelines}
              min={shiftStartTime}
              max={shiftEndTime}
              onStatusAdd={handleAddStatus}
            />
          </div>
          <div className="ShiftModal__Column">
            <ShiftTimelineCard
              timelines={trackedTimelines}
              loading={loadingTrackedTimelines}
              onStatusAdd={handleAddStatus}
            />
          </div>
        </div>
        <div className="ShiftModal__Row ShiftModal__TextArea">
          <div className="ShiftModal__Column">
            <div className="ShiftModal__Column__Header">
              <div className="ShiftModal__Column__Title">Note</div>
            </div>
            <TextArea
              rows={3}
              value={notes}
              onChange={handleNotesChange}
              onFocus={(e: any) => {
                // update the cursor to the end
                const val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
          </div>
        </div>
        <div className="ShiftModal__Row">
          <div>
            <Popover
              destroyTooltipOnHide={true}
              getPopupContainer={(trigger: any) => trigger}
              trigger="click"
              placement="top"
              content={
                <ShiftPopover
                  type="work"
                  defaultStart={shiftStartTime}
                  defaultEnd={shiftEndTime}
                  onSave={() => {}}
                  onStartChange={handleStartChange}
                  onEndChange={handleEndChange}
                />
              }
            >
              <Button
                className="ShiftModal__EditBtn"
                icon={<DraftIcon className="ShiftModal__Draft" />}
                disabled={true}
              >
                Edit Shift
              </Button>
            </Popover>
            <Popover
              getPopupContainer={(trigger: any) => trigger}
              trigger="click"
              placement="top"
              visible={deleteVisible}
              onVisibleChange={(visible: boolean) => setDeleteVisible(visible)}
              content={
                <DeleteShiftPopover
                  start={shiftStartTime}
                  end={shiftEndTime}
                  checkoutDate={checkoutDate}
                  current={edit && !shift?.end_datetime}
                  onCancel={() => setDeleteVisible(false)}
                  onDelete={handleDeleteShift}
                />
              }
            >
              <Button icon={<DeleteIcon className="ShiftModal__Delete" />}>
                Delete Shift
              </Button>
            </Popover>
          </div>
          <Button type="primary" loading={loading} onClick={handleModalClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShiftModal;
