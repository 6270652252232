import React from "react";
import { Avatar, Button, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";

const StartWorkAvatar = (props: StartWorkAvatarProps) => {
  const { handleClick } = props;
  const popoverContent = (
    <div className="StartWork__Popover__content">
      <Button type="primary" className="logout-button" onClick={handleClick}>
        Logout
      </Button>
    </div>
  );
  return (
    <Popover
      content={popoverContent}
      trigger="click"
      overlayClassName="StartWork__Popover"
      getPopupContainer={(trigger: any) => trigger.parentElement}
    >
      <Avatar size={64} icon={<UserOutlined />} className="StartWorkAvatar" />
    </Popover>
  );
};

export { StartWorkAvatar as default };
