export const nextCalendarSchedule: CalendarScheduleArray = [
  {
    id: null,
    user: "1",
    start_datetime: "2021-05-14T00:00:00Z",
    end_datetime: "2021-05-14T09:00:00Z",
    work_time_seconds: 32400,
    schedule_notes: "8 core",
  },
];
