import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { Tooltip } from "antd";

import {
  LoadingIcon,
  NextShiftIcon,
} from "../../CustomIcons/CustomIcons.component";

import {
  startCreateJoinTaskToUserRecord,
  startPartialUpdateJoinTaskToUserRecord,
} from "../../../actions/tasks.action";
import { selectUserTasksState } from "../../../reducers/tasks.reducer";
import { selectPrioritiesState } from "../../../reducers/priorities.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getPriorityByTitle } from "../../../utils/priorities.util";

/**
 * AddToNextShift schedules a task for the next calendar schedule
 *
 * Conditional Display
 * Tooltip text displays "Remove from Next Shift" if task is already scheduled to next shift
 * Tooltip text displays "Add to Next Shift" if task is not scheduled to next shift
 *
 * Functionality
 * If join_task_to_user record and scheduled_task record exists, and task is not scheduled for next shift...
 * ...updates the scheduled task record's scheduled_datetime to be nextCalendarSchedule.start_datetime
 *
 * If join_task_to_user record and scheduled_task record exists, and task is scheduled for next shift...
 * ...deletes the schedule task record
 *
 * If join_task_to_user record but scheduled_task record doesn't, adds a scheduled task record...
 * ...with scheduled_datetime value of nextCalendarSchedule.start_datetime
 *
 * If join_task_to_user and scheduled_task record doesn't, adds a join_task_to_user and scheduled task record...
 * ...with scheduled_datetime value of nextCalendarSchedule.start_datetime
 */
const AddToNextShift: React.FC<AddToNextShiftProps> = ({
  search,
  task: {
    join_task_to_user_id: userTaskId,
    next_shift_yn,
    title: taskTitle,
    id: taskId,
    loading: taskLoading,
  },
}) => {
  const dispatch = useDispatch();

  const { data: priorities } = useSelector(selectPrioritiesState);
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const { loading: nextShiftYnLoading, value: nextShiftYn } =
    next_shift_yn || {};
  const isUpdating = taskLoading && nextShiftYnLoading;

  const tooltipText = nextShiftYn
    ? "Remove from Next Workday"
    : "Add to Next Workday";
  const nonePriority = getPriorityByTitle(priorities, "None");

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    if (userTaskId && nextShiftYn !== undefined) {
      /**
       * Should always have user task for all tasks create, added check in case
       * update next_shift_yn to opposite value
       */
      const joinTaskToUserData: PartialUpdateJoinTaskToUserRecordServiceData = {
        id: userTaskId,
        next_shift_yn: !nextShiftYn,
        title: nextShiftYn ? taskTitle : "", // title is used for system feedback message to undo action
        taskId,
      };
      dispatch(startPartialUpdateJoinTaskToUserRecord(joinTaskToUserData));
    } else if (nonePriority) {
      dispatch(
        startCreateJoinTaskToUserRecord({
          next_shift_yn: true,
          priority: nonePriority.id,
          task: taskId,
          user: userId,
        })
      );
    } else {
      throw new Error(`nonePriority does not exist`);
    }
  };

  return (
    <div className="AddToNextShift">
      {search ? (
        <div className="AddToNextShift__Search" onClick={handleOnClick}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <NextShiftIcon
              className={cn("AddToNextShift__Icon", {
                "AddToNextShift__Icon--IsActive": nextShiftYn,
              })}
            />
          )}
          <span className="AddToNextShift__Text">{tooltipText}</span>
        </div>
      ) : (
        <Tooltip title={tooltipText}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <NextShiftIcon
              className={cn("AddToNextShift__Icon", {
                "AddToNextShift__Icon--IsActive": nextShiftYn,
              })}
              onClick={handleOnClick}
            />
          )}
        </Tooltip>
      )}
    </div>
  );
};

export default AddToNextShift;
