import React from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";

import { history } from "../../../routers/AppRouter";

import useRedirect from "../../../hooks/useRedirect.hook";

import ActivityTabContent from "../../Activities/ActivityTabContent/ActivityTabContent";
import DetailsTabContent from "../TaskDetails/DetailsTabContent/DetailsTabContent";

const { TabPane } = Tabs;

/**
 * Default Display
 * Displays details of selected task
 * Displays tabs navigation [Activity, Checklists, Work time, Description, Details]
 */
const TaskDetails = ({ task }: TaskDetailsProps) => {
  const redirect = useRedirect();

  const { taskSubPage } = useParams<{ taskSubPage: string }>();

  const { title } = task;

  const handleTabClick = (key: string) => {
    const pathWithoutSubPage = history.location.pathname.substr(
      0,
      history.location.pathname.lastIndexOf("/")
    );
    const newSubPage = `/${key}`;
    const searchQueryParams = history.location.search;
    redirect({
      path: pathWithoutSubPage + newSubPage + searchQueryParams,
    });
  };

  const taskDetailsTabs: TaskDetailsTabArray = [
    {
      title: "activity",
      component: <ActivityTabContent />,
    },
    {
      title: "details",
      component: <DetailsTabContent task={task} />,
    },
  ];

  return (
    <div className="TaskDetails">
      <div className="TaskDetails__Header">
        <div className="TaskDetails__Header__Title">{title}</div>
      </div>
      <Tabs
        activeKey={taskSubPage}
        onChange={handleTabClick}
        className="TaskDetails__Tabs"
      >
        {taskDetailsTabs.map(({ title, component }) => {
          return (
            <TabPane tab={title} key={title}>
              {component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export { TaskDetails as default };
