import { RootState } from "../store/configureStore";

export interface OfficeState extends BaseReducerState {
  data: Office;
}
export const selectOfficeState = (rootState: RootState) => rootState.office;
export const officeReducerDefaultState: OfficeState = {
  data: {
    todaySessions: 0,
    nextWorkdaySessions: 0,
    nextWorkdayDate: "",
    nextWorkdayWorkTime: 0,
  },
  error: "",
  loading: false,
};
const officeReducer = (
  state = officeReducerDefaultState,
  action: { type: string; payload: Office; error: string }
) => {
  switch (action.type) {
    case "REQUEST_OFFICE":
      return {
        ...state,
        loading: true,
      };
    case "REQUEST_OFFICE_SUCCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case "REQUEST_OFFICE_FAILED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export { officeReducer as default };
