import React from "react";

import { FilterIcon } from "../../../CustomIcons/CustomIcons.component";
import TeamMemberSearchPopoverContent from "./TeamMemberSearchPopoverContent/TeamMemberSearchPopoverContent";

const TeamMemberSearch: React.FC<TeamMemberSearchProps> = ({
  setObservableUsers,
  observableUsers,
}) => {
  const [showPopover, setShowPopover] = React.useState(false);

  return (
    <div className="TeamMemberSearch">
      <FilterIcon
        onClick={() => {
          setShowPopover(true);
        }}
      />
      {showPopover && (
        <TeamMemberSearchPopoverContent
          onClickClose={() => {
            setShowPopover(false);
          }}
          observableUsers={observableUsers}
          setObservableUsers={setObservableUsers}
        />
      )}
    </div>
  );
};

export default TeamMemberSearch;
