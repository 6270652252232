import React from "react";
import { Popover } from "antd";

import { MoreActionsIcon } from "../../../CustomIcons/CustomIcons.component";
import TeamMemberSelectorMoreActionsPopoverContent from "./TeamMemberSelectorMoreActionsPopoverContent/TeamMemberSelectorMoreActionsPopoverContent";

const TeamMemberSelectorMoreActions: React.FC<TeamMemberSelectorMoreActionsProps> = ({
  selectAll,
  deselectAll,
}) => {
  return (
    <div className="TeamMemberSelectorMoreActions">
      <Popover
        content={
          <TeamMemberSelectorMoreActionsPopoverContent
            selectAll={selectAll}
            deselectAll={deselectAll}
          />
        }
        overlayClassName="TeamMemberSelectorMoreActions__Popover"
        placement="bottomRight"
        trigger="click"
        getPopupContainer={(trigger: any) => trigger}
      >
        <MoreActionsIcon />
      </Popover>
    </div>
  );
};

export default TeamMemberSelectorMoreActions;
