import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Divider } from "antd";
import moment from "moment";

import useWorkTimesByDate from "../../hooks/useWorkTimesByDate.hook";

import { LoadingIcon } from "../CustomIcons/CustomIcons.component";
import CustomAvatar from "../CustomAvatar/CustomAvatar";

import { workDayDateFormat } from "../../constants/constants";
import { startPopulateOffice } from "../../actions/office.action";
import { startPopulateWorkTimes } from "../../actions/work-times.action";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectOfficeState } from "../../reducers/office.reducer";
import { selectCurrentWorkDayState } from "../../reducers/work-day.reducer";
import { formatDuration } from "../../utils/time-conversion.util";
import { getInitials } from "../../utils/string.util";

/**
 * Summary is a component with title and a list of items.
 * each item contains a title and a description.
 * it's used to represent today or next workday's summary
 */
const Summary = ({
  title,
  items,
  loading = false,
}: {
  title: string;
  items: { title: string; desc: string }[];
  loading?: boolean;
}) => {
  const itemList = items.map((item) => (
    <div key={item.desc} className="Office__summary-item">
      <span className="Office__summary-time">{item.title}</span>
      <span className="Office__summary-desc">{item.desc}</span>
    </div>
  ));
  return (
    <div className="Office__summary">
      <h2>{title}</h2>
      {loading ? (
        <LoadingIcon className="Office__summaries__loading" />
      ) : (
        <div className="Office__summary-content">
          {items.length > 0 ? (
            itemList
          ) : (
            <span className="Office__empty"></span>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Office component shows the summary page of a user, including today's work hours
 * and next workday's overview. It use "work-time" redux state to populate today's info
 */
const Office = () => {
  const dispatch = useDispatch();
  const { data: office, loading: officeLoading } = useSelector(
    selectOfficeState
  );
  const {
    data: {
      avatar: userAvatar,
      display_name: userDisplayName,
      what_i_do: role,
    },
    loading: userDetailsLoading,
  } = useSelector(selectUserDetailsState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [
    currentDateWorkTimes,
    currentDateWorkTimesLoading,
  ] = useWorkTimesByDate(currentWorkDayDate);

  React.useEffect(() => {
    dispatch(startPopulateOffice());
  }, [dispatch]);

  React.useEffect(() => {
    if (currentWorkDayDate)
      dispatch(startPopulateWorkTimes(currentWorkDayDate));
  }, [currentWorkDayDate, dispatch]);

  const todayItems =
    office.todaySessions === 0
      ? [
          {
            title: "-",
            desc: "Work time",
          },
          {
            title: "-",
            desc: "Break time",
          },
          {
            title: "-",
            desc: "Session",
          },
        ]
      : [
          {
            title: formatDuration(currentDateWorkTimes.work_time, "seconds"),
            desc: "Work time",
          },
          {
            title: formatDuration(currentDateWorkTimes.break_time, "seconds"),
            desc: "Break time",
          },
          {
            title: office.todaySessions.toString(),
            desc: office.todaySessions > 1 ? "Sessions" : "Session",
          },
        ];

  const nextWorkdayItems =
    office.nextWorkdaySessions === 0
      ? [
          {
            title: "-",
            desc: "Date",
          },
          {
            title: "-",
            desc: "Work time",
          },
          {
            title: "-",
            desc: "Session",
          },
        ]
      : [
          {
            title: moment(office.nextWorkdayDate).format("ddd, MMMM D"),
            desc: "Date",
          },
          {
            title: formatDuration(office.nextWorkdayWorkTime, "seconds"),
            desc: "Work time",
          },
          {
            title: office.nextWorkdaySessions.toString(),
            desc: office.nextWorkdaySessions > 1 ? "Sessions" : "Session",
          },
        ];

  return (
    <section className="Office">
      <header className="Office__header">Office</header>
      <Divider className="Office__divider" />
      <div className="Office__info-container">
        {userDetailsLoading && <Avatar size={48} icon={<LoadingIcon />} />}
        {!userDetailsLoading && (
          <>
            <CustomAvatar
              size={48}
              src={userAvatar}
              fallbackText={getInitials(userDisplayName)}
            />
            <div className="Office__info">
              <span className="Office__name">{userDisplayName}</span>
              <span className="Office__title">{role}</span>
            </div>
          </>
        )}
      </div>
      <div className="Office__summaries">
        <Summary title="today" items={todayItems} loading={officeLoading} />
        <Summary
          title="next workday"
          items={nextWorkdayItems}
          loading={officeLoading}
        />
      </div>
    </section>
  );
};

export default Office;
