import React from "react";
import { useSelector } from "react-redux";

import {
  selectScheduledTasksState,
  selectUserTasksState,
} from "../reducers/tasks.reducer";
import {
  joinTaskArrayToUserTaskArray,
  scheduleTaskJoinedToUser,
} from "../utils/tasks-consolidate.util";

export default function useConsolidateTask(tasks?: TaskArray) {
  const { data: userTasks, loading: userTasksLoading } = useSelector(
    selectUserTasksState
  );
  const { data: scheduledTasks, loading: scheduledTasksLoading } = useSelector(
    selectScheduledTasksState
  );

  const [
    consolidatedTasks,
    setConsolidatedTasks,
  ] = React.useState<TaskJoinedToUserAndScheduledArray>();

  const loading = !tasks ? false : userTasksLoading || scheduledTasksLoading;

  React.useEffect(() => {
    if (!userTasksLoading && !scheduledTasksLoading && tasks !== undefined)
      setConsolidatedTasks(
        scheduleTaskJoinedToUser(
          joinTaskArrayToUserTaskArray(tasks, userTasks),
          scheduledTasks
        )
      );
  }, [
    tasks,
    scheduledTasks,
    scheduledTasksLoading,
    userTasks,
    userTasksLoading,
  ]);

  return [!tasks ? undefined : consolidatedTasks, loading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
