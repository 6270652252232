import { useFeature } from "flagged";

import { history } from "../routers/AppRouter";

export interface UseRedirectArgs {
  path: string;
  searchParams?: string;
}
export default function useRedirect() {
  const isBeta = useFeature("under_development");
  return ({ path, searchParams }: UseRedirectArgs) => {
    history.push(
      `${path}?${isBeta ? "beta=on" : ""}${
        searchParams ? "&" + searchParams : ""
      }`
    );
  };
}
