import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment, { Moment } from "moment";

import usePreviousHook from "../../hooks/usePrevious.hook";

import StartWorkAvatar from "../../modules/StartWork/StartWorkAvatar/StartWorkAvatar.component";
import StartWorkTimeDisplay from "../../modules/StartWork/StartWorkTimeDisplay/StartWorkTimeDisplay.component";
import StartWorkBtn from "../../modules/StartWork/StartWorkBtn/StartWorkBtn.component";
import GlobalSystemMessage from "../../modules/General/GlobalSystemMessage/GlobalSystemMessage";

import { startLogout } from "../../actions/auth.action";
import { setStatus } from "../../actions/status.action";
import { workStatuses } from "../../constants/constants";
import { selectAuthState } from "../../reducers/auth.reducer";
import { selectErrorState } from "../../reducers/error.reducer";
import { selectStatusTypesState } from "../../reducers/status-types.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { minutesToMs } from "../../utils/time-conversion.util";

import ws from "../../sockets/websockets";

const StartWorkView = () => {
  const dispatch = useDispatch();

  const error = useSelector(selectErrorState);
  const { data: statusTypes } = useSelector(selectStatusTypesState);
  const {
    data: { email: authEmail },
  } = useSelector(selectAuthState);
  const {
    data: { timezone: userTimezone },
    loading: userDetailsLoading,
  } = useSelector(selectUserDetailsState);

  const [time, setTime] = React.useState<Moment>();

  const prevUserDetailsLoading = usePreviousHook(userDetailsLoading);

  const handleClickLogout = () => {
    dispatch(startLogout());
  };

  React.useEffect(() => {
    const wsOnMessage = (event: MessageEvent) => {
      try {
        const eventData = JSON.parse(event.data);
        if (eventData.email === authEmail) {
          switch (eventData.status) {
            case workStatuses.working:
              const workingStatusArray = statusTypes.filter((statusType) => {
                return statusType.title === workStatuses.working;
              });
              const workStatusExistsInStatusTypes =
                workingStatusArray.length > 0;
              if (workStatusExistsInStatusTypes) {
                dispatch(setStatus("SET_STATUS", workingStatusArray[0]));
              }
              break;
          }
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch, authEmail, statusTypes]);

  React.useEffect(() => {
    if (!userDetailsLoading && prevUserDetailsLoading && userTimezone) {
      // wait for user details to be loaded so default moment instance will use user's timezone
      setTime(moment());

      let intervalID = setInterval(() => {
        setTime((c) => {
          return moment(c).add(10, "seconds");
        });
      }, minutesToMs(1));

      return () => clearInterval(intervalID);
    }
  }, [userTimezone, userDetailsLoading, prevUserDetailsLoading]);

  return (
    <div className="View">
      {error && <GlobalSystemMessage />}
      <div className="StartWork">
        <StartWorkAvatar handleClick={handleClickLogout} />
        <StartWorkTimeDisplay time={time} />
        <StartWorkBtn />
        {/*<StartWorkChangeWallpaper />*/}
        <div className="StartWork__Carousel">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default StartWorkView;
