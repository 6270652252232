import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

import { workDayDateFormat } from "../constants/constants";

export interface GetLatestWorkDayService {
  user: UserDetailsObject["id"];
  work_date: WorkDayObject["work_date"];
  from_date: string;
  to_date: string;
}
export const getLatestWorkDayService = (
  data: Partial<GetLatestWorkDayService>
) => {
  const { ...params } = data || {};
  return axios.get("/api/work/latest_workday/", { params });
};

export interface GetWorkDayListServiceData {
  user?: UserDetailsObject["id"];
  work_date?: WorkDayObject["work_date"];
  from_date?: string;
  to_date?: string;
  ordering?: keyof WorkDayObject;
}
export type GetWorkDayListService = (
  data?: GetWorkDayListServiceData
) => Promise<AxiosResponse<any>>;
export const getWorkDayListService: GetWorkDayListService = (data) => {
  if (data) {
    const { ...params } = data || {};
    return axios.get(`/api/work/work_day/`, { params });
  } else {
    return axios.get("/api/work/work_day/");
  }
};

export const getWorkDayRecordByIdService: GetWorkDayRecordByIdService = (
  id
) => {
  return axios.get(`/api/work/work_day/${id}/`);
};

export type GetWorkDaySummaryService = (
  data: GetWorkDaySummaryServiceData
) => Promise<AxiosResponse<GetWorkDaySummaryServiceResponse>>;
export interface GetWorkDaySummaryServiceResponse {
  total_seconds_worked: number;
  workdays: WorkDayCheckoutSummaryArray;
}
export interface GetWorkDaySummaryServiceData {
  user_ids: UserDetailsObject["id"][];
  from_date: string;
  to_date: string;
}
export const getWorkDaySummaryService: GetWorkDaySummaryService = (data) => {
  const { user_ids, from_date, to_date } = data;
  const payload = {
    user_ids,
  };
  const params = {
    from_date,
    to_date,
  };
  return axios.post(`/api/work/work_day/workday_summary/`, payload, { params });
};

export interface CreateWorkDayRecordServiceData {
  checkout_notes?: WorkDayObject["checkout_notes"];
  user: UserDetailsObject["id"];
  work_date: WorkDayObject["work_date"];
  with_checkout: boolean;
}
export type CreateWorkDayRecordService = (
  data: CreateWorkDayRecordServiceData
) => Promise<AxiosResponse<any>>;
export const createWorkDayRecordService: CreateWorkDayRecordService = (
  data
) => {
  return axios.post("/api/work/work_day/", data);
};
export const updateWorkDayRecordService: UpdateWorkDayRecordService = (
  data
) => {
  const { id, ...payload } = data;
  return axios.put(`/api/work/work_day/${id}/`, payload); // must be in this format in order to mock implementation in unit test
};

export interface PartialUpdateWorkDayServiceData
  extends Partial<WorkDayObject> {
  id: WorkDayObject["id"];
  work_date: WorkDayObject["work_date"];
  user: WorkDayObject["user"];
}
export type PartialUpdateWorkDayService = (
  data: PartialUpdateWorkDayServiceData
) => Promise<AxiosResponse<any>>;
export const partialUpdateWorkDayRecordService: PartialUpdateWorkDayService = ({
  id: workDayId,
  work_date: workDayDate,
  ...payload
}) => {
  return axios.patch(`/api/work/work_day/${workDayId}/`, payload);
};
