import React from "react";
import { Form } from "antd";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../General/Modal/Modal";
import AccountForm from "./AccountForm/AccountForm.component";
import PersonalForm from "./PersonalForm/PersonalForm.component";

import { startUpdateUserDetails } from "../../actions/user-details.action";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import AvatarUploader from "./AvatarUploader/AvatarUploader";

/**
 * This component is used to edit the profile of the login user.
 * we initiate the form object in this component and pass it to the form component
 * in order to have a full control of the data of the form.
 */
const AccountSettings = ({ open, onClose, onOk }: AccountSettingsProps) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetailsState).data;
  const [accountForm] = Form.useForm();
  const [personalForm] = Form.useForm();

  // show initial avatar if it is empty string
  const [avatar, setAvatar] = React.useState<File | "">();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      accountForm.resetFields();
      personalForm.resetFields();
    }
  }, [open, accountForm, personalForm]);

  const handleSave = async () => {
    const accountValues = await accountForm.validateFields();
    const personalValues = await personalForm.validateFields();
    const payload = {
      ...userDetails,
      full_name: personalValues.fullName,
      display_name: personalValues.displayName,
      avatar: avatar,
      timezone: personalValues.timezone,
      country: personalValues.country,
      city: personalValues.city,
      what_i_do: personalValues.role,
    } as any;
    setLoading(true);
    await dispatch(startUpdateUserDetails(payload));
    setLoading(false);
    onOk();
  };
  const handleAvatarChange = (avatar: File | "") => {
    setAvatar(avatar);
  };
  return (
    <Modal
      className="AccountSettingsModal"
      title="Edit Profile"
      maskClosable={false}
      centered={true}
      confirmLoading={loading}
      visible={open}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      onOk={handleSave}
    >
      <div className="AccountSettings">
        <div className="AccountSettings__FormWrapper">
          <div className="AccountSettings__Title">Personal Information</div>
          <div className="AccountSettings__Form">
            <PersonalForm userDetails={userDetails} form={personalForm} />
          </div>

          <div className="AccountSettings__Title">Email and Password</div>
          <div className="AccountSettings__Form">
            <AccountForm userDetails={userDetails} form={accountForm} />
          </div>
        </div>
        <div className="AccountSettings__Avatar">
          <div className="AccountSettings__Title">Profile Photo</div>
          <AvatarUploader
            name={userDetails.display_name}
            defaultAvatar={userDetails.avatar || ""}
            onAvatarChange={handleAvatarChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountSettings;
