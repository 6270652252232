import { useState, useEffect } from "react";

/**
 * Custom hook that can be used to check if an element is displayed on the screen after scrolling
 * @function useVisibleOnScroll
 * @param ref
 * @returns {boolean}
 */
export default function useVisibleOnScroll(
  ref: React.MutableRefObject<HTMLDivElement | null>
) {
  const [isVisibleOnScroll, setIsVisibleOnScroll] = useState(false);

  useEffect(() => {
    const element = document.getElementsByClassName(
      "NotificationsPopoverContent__Body"
    )[0];

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisibleOnScroll(entry.isIntersecting);
    });

    const scrollHandler = () => {
      if (ref.current) observer.observe(ref.current);
    };

    if (element) element.addEventListener("scroll", scrollHandler);

    return () => {
      if (element) element.removeEventListener("scroll", scrollHandler);
      observer.disconnect();
    };
  }, [ref]);

  return isVisibleOnScroll;
}
