import {
  fetchScheduleTaskRecordService,
  fetchTaskRecordService,
  fetchUserTaskRecordService,
  getJoinTaskToUserListService,
  getScheduleTaskListService,
  getTasksListService,
} from "../services/tasks.service";

export const asyncGetTasksInProject = async (
  projectId: ProjectObject["id"]
) => {
  try {
    const response = await getTasksListService({
      parent_project: projectId,
    });
    if (response.status === 200) {
      return response.data.results as TaskArray;
    } else {
      throw new Error();
    }
  } catch (e) {
    return [] as TaskArray;
  }
};

export const asyncFetchTaskObject = async (taskId: TaskObject["id"]) => {
  try {
    const response = await fetchTaskRecordService(taskId);
    if (response.status === 200) {
      return response.data as TaskObject;
    }
  } catch (e) {}
};

export const asyncFetchUserTaskObject = async (
  userTaskId: JoinTaskToUserObject["id"]
) => {
  try {
    const response = await fetchUserTaskRecordService(userTaskId);
    if (response.status === 200) {
      return response.data as JoinTaskToUserObject;
    }
  } catch (e) {}
};

export const asyncFetchUserTaskObjectByTaskId = async (
  taskId: TaskObject["id"]
) => {
  try {
    const response = await getJoinTaskToUserListService({ task: taskId });
    if (response.status === 200 && response.data.length > 0) {
      return response.data[0] as JoinTaskToUserObject;
    }
  } catch (e) {}
};

export const asyncFetchScheduledTaskObject = async (
  scheduledTaskId: ScheduleTaskObject["id"]
) => {
  try {
    const response = await fetchScheduleTaskRecordService(scheduledTaskId);
    if (response.status === 200) {
      return response.data as TaskObject;
    }
  } catch (e) {}
};

export const asyncFetchScheduledTaskObjectByUserTaskId = async (
  userTaskId: JoinTaskToUserObject["id"]
) => {
  try {
    const response = await getScheduleTaskListService({
      user_task_id: userTaskId,
    });
    if (response.status === 200 && response.data.length > 0) {
      return response.data[0] as ScheduleTaskObject;
    }
  } catch (e) {}
};
