import React from "react";
import { Menu, Dropdown, Table, Modal } from "antd";
import { useDispatch } from "react-redux";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import {
  MoreActionsIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import EditableRow from "./EditableTable/EditableRow.component";
import EditableCell from "./EditableTable/EditableCell.component";
import { getJoinTaskToUserListService } from "../../../services/tasks.service";
import ws from "../../../sockets/websockets";
import { getInitials } from "../../../utils/string.util";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  key: React.Key;
  name: string;
  value: string;
}

/**
 * This table is used to display and manage the members of a project.
 * Only the project owner has the permission to add or update members.
 * A project member can be changed to the project owner, but the owner can't
 * be changed back to a project member.
 */
const MembersTable: React.FC<MembersTableProps> = ({
  projectId,
  type,
  title,
  data,
  accessType,
  loading = false,
  onSave,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = React.useState<JoinTaskToUserObject[]>([]);
  const menu = (user: ProjectUser) => (
    <Menu className="ProjectOverview__Menu">
      <Menu.Item key="delete" onClick={() => handleDelete(user)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  const fetchTasks = React.useCallback(async () => {
    try {
      const response = await getJoinTaskToUserListService({
        current_user: false,
        project_id: projectId,
      });
      if (response.status === 200) {
        setTasks(response.data);
      } else {
        setTasks([]);
      }
    } catch (e) {
      setTasks([]);
    }
  }, [projectId]);

  const memoData = React.useMemo(() => data, [data]);
  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType, meta } = messageEventData;
        if (eventType.trim() === "new_join_task_to_user") {
          const { user_id } = meta;
          if (memoData.find((user) => user.id === user_id)) {
            fetchTasks();
          }
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [memoData, fetchTasks, dispatch]);

  React.useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const handleSave = async (payload: any) => {
    await onSave(payload);
  };

  const confirmDelete = (user: ProjectUser) => {
    Modal.confirm({
      title: "Warning",
      content: `Are you sure you want to remove ${
        user.displayName
      } from ${title} ${type === "project" ? "project" : "list"}?`,
      onOk: () => {
        return onDelete(user);
      },
      okText: "Remove",
      cancelText: "Cancel",
    });
  };

  const handleDelete = (projectUser: ProjectUser) => {
    const deletedUser = data.find((user) => user.id === projectUser.id);
    if (type === "project") {
      const hasTodayTask =
        tasks.filter((t) => t.today_yn && t.user.id === projectUser.id).length >
        0;
      if (hasTodayTask) {
        Modal.confirm({
          title: "Warning",
          content: `The user ${projectUser.displayName} has this project in his Today list. The user will lose access to this project and not be able to allocate work hours to it.`,
          onOk: () => {
            confirmDelete(projectUser);
          },
          okText: "Ok",
          cancelText: "Cancel",
        });
        return;
      }

      const ownerCnt = data.filter((user) => user.accessType === "owner")
        .length;
      if (deletedUser?.accessType === "owner" && ownerCnt <= 1) {
        Modal.warning({
          title: "Warning",
          content: "There should be at least one owner.",
        });
        return;
      }
    }
    confirmDelete(projectUser);
  };

  let columns: ColumnsType<any> | undefined = [
    {
      title: "Member",
      key: "member",
      render: (data) => {
        return (
          <div className="MembersTable__Member">
            <CustomAvatar
              size={40}
              src={data.avatar}
              work_status={data.status}
              fallbackText={getInitials(data.displayName)}
            />
            <div className="MembersTable__Member-info">
              <div className="MembersTable__Member-name">
                {data.displayName}
              </div>
              <div className="MembersTable__Member-role">
                {data.projectRole}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Tasks",
      dataIndex: "taskCount",
      key: "taskCount",
      align: "right",
    },
    {
      title: "Project Role",
      dataIndex: "projectRole",
      key: "projectRole",
      onCell: (record: DataType) => ({
        record,
        editable: type === "project" && accessType === "owner",
        dataIndex: "projectRole",
        title: "Project Role",
        onSave: handleSave,
      }),
    },
    {
      title: "Type",
      dataIndex: "accessType",
      key: "accessType",
      render: (accessType: string) => {
        if (type === "project") {
          if (accessType === "owner") return "Project Owner";
          return "Project Member";
        }
        return "";
      },
      onCell: (record: DataType) => ({
        record,
        editable: type === "project" && accessType === "owner",
        selector: true,
        dataIndex: "accessType",
        title: "Type",
        onSave: handleSave,
      }),
    },
  ];
  columns =
    accessType === "owner"
      ? [
          ...columns,
          {
            title: "",
            key: "action",
            render: (item) => (
              <Dropdown
                overlay={() => menu(item)}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <MoreActionsIcon style={{ color: "#0000007F" }} />
              </Dropdown>
            ),
          },
        ]
      : columns;
  columns =
    type === "taskList"
      ? columns.filter((column) => column.key !== "accessType")
      : columns;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const records = data.map((item) => ({ ...item, key: item.id }));
  const owners = records
    .filter((item) => item.accessType === "owner")
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
  const members = records
    .filter((item) => item.accessType === "member")
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
  return (
    <Table
      className="ProjectOverview__Table MembersTable"
      loading={{
        spinning: loading,
        indicator: <LoadingIcon />,
      }}
      components={components}
      columns={columns}
      dataSource={[...owners, ...members]}
      pagination={false}
    />
  );
};

export default MembersTable;
