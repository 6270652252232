import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import { startCreateStatus } from "../../../actions/status.action";
import { workStatuses } from "../../../constants/constants";
import { selectStatusTypesState } from "../../../reducers/status-types.reducer";

const StartWorkBtn: React.FC = () => {
  const dispatch = useDispatch();

  const statusTypesState = useSelector(selectStatusTypesState);
  const { data: statusTypes, loading: statusTypesLoading } = statusTypesState;

  const handleClickStartToWork = () => {
    const workStatusType = statusTypes.find((statusType) => {
      return statusType.id === workStatuses.working;
    });
    if (workStatusType) {
      dispatch(startCreateStatus(workStatusType));
    }
  };

  return (
    <Button
      type="primary"
      className="StartWorkBtn green-btn"
      onClick={handleClickStartToWork}
      disabled={statusTypesLoading}
    >
      {statusTypesLoading ? <LoadingIcon /> : "Start to Work"}
    </Button>
  );
};

export { StartWorkBtn as default };
