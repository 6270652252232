import React from "react";
import { Button as AntButton, Avatar } from "antd";
import Button from "../../Button/Button";

import { toBase64 } from "../../../utils/base64.util";

const AvatarUploader = ({
  name,
  defaultAvatar,
  onAvatarChange,
}: AvatarUploaderProps) => {
  const [avatar, setAvatar] = React.useState<string>(defaultAvatar);

  // convert the image to base64 string so it can be sent to the endpoint
  const hanldeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      onAvatarChange(file);
      const base64 = await toBase64(file);
      setAvatar(base64);
    }
  };

  const handleUploadClick = (e: any) => {
    e.preventDefault();
    // create a virtual <input type="file" /> element to upload image
    const input = document.createElement("input");
    input.type = "file";
    input.name = "avatar";
    input.accept = "image/*";
    input.onchange = hanldeUpload as any;
    input.click();
  };

  const handleRemove = () => {
    setAvatar("");
    onAvatarChange("");
  };
  return (
    <div className="AvatarUploader">
      <div className="AvatarUploader__AvatarBox">
        {avatar ? (
          <Avatar size={80} src={avatar} />
        ) : (
          <div className="AvatarUploader__Avatar">{(name || "")[0]}</div>
        )}
      </div>
      <Button className="AvatarUploader__Upload" onClick={handleUploadClick}>
        Upload image
      </Button>
      <AntButton
        className="AvatarUploader__Remove"
        type="link"
        disabled={!avatar}
        onClick={handleRemove}
      >
        Remove
      </AntButton>
    </div>
  );
};

export default AvatarUploader;
