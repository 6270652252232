import { Table, Tooltip, Modal } from "antd";
import React from "react";
import moment, { Moment } from "moment";
import cn from "classnames";
import { ColumnsType } from "antd/lib/table";
import { useFeature } from "flagged";

import { getTotalDuration } from "../../../utils/time-conversion.util";
import Button from "../../Button/Button";
import {
  AddIcon,
  CommentIcon,
  DraftIcon,
  InfoCircleOutlinedIcon,
  LoadingIcon,
  ManuallyAddedIcon,
  RefreshIcon,
} from "../../CustomIcons/CustomIcons.component";
import AddShiftModal from "./AddShiftModal";
import ShiftModal from "./ShiftModal";
import {
  deleteShiftService,
  modifyShiftService,
} from "../../../services/work-shift.service";
import {
  getWorkShiftsService,
  syncWorkShiftsService,
} from "../../../services/task-time.service";
import ws from "../../../sockets/websockets";
import {
  WorkStatusTypesEnum,
  ChangedValueTypeEnum,
} from "../../../constants/constants";

const ShiftsTable: React.FC<ShiftsTableProps> = ({
  editingAllowed,
  userId,
  workdayId,
  checkoutDate,
}) => {
  const isDev = useFeature("under_development");
  const [workShifts, setWorkShifts] = React.useState<WorkShiftSummary[]>([]);
  const [addShiftModalOpen, setAddShiftModalOpen] = React.useState(false);
  const [shiftModalOpen, setShiftModalOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [shiftStart, setShiftStart] = React.useState<Moment>();
  const [shiftEnd, setShiftEnd] = React.useState<Moment>();
  const [selectedShift, setSelectedShift] = React.useState<WorkShiftSummary>();
  const [addedShiftId, setAddedShiftId] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  // syncing means when user triggers the sync request, it will be set to false
  // when FE receive "time_entries_synced" ws event.
  const [syncing, setSyncing] = React.useState(false);

  const requestWorkShifts = React.useCallback(async () => {
    if (workdayId) {
      setLoading(true);
      const response = await getWorkShiftsService(workdayId);
      setWorkShifts(response);
      setLoading(false);
      return response;
    }
    return [];
  }, [workdayId]);

  React.useEffect(() => {
    requestWorkShifts();
  }, [requestWorkShifts]);

  React.useEffect(() => {
    syncWorkShiftsService(checkoutDate, checkoutDate);
  }, [checkoutDate]);

  React.useEffect(() => {
    const synchronizeTaskTimeRecords = (
      messageEventData: TaskTimeEventData
    ) => {
      const { event: eventType } = messageEventData;
      switch (eventType) {
        case "time_entries_synced":
          setSyncing(false);
          requestWorkShifts();
          break;
        default:
          break;
      }
    };
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        synchronizeTaskTimeRecords(messageEventData);
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [requestWorkShifts]);

  const summaries = workShifts
    .map((shift) => {
      return {
        key: shift.id,
        id: shift.id,
        start: shift.start_datetime,
        end: shift.end_datetime,
        isManual: shift.manual_work_shift_yn,
        isShiftChanged: shift.shift_changed_manually,
        isWorkChanged: shift.work_time_changed_manually,
        isBreakChanged: shift.break_time_changed_manually,
        notes: shift.work_shift_notes,
        work: getTotalDuration(moment.duration(shift.work_time, "seconds")),
        break: getTotalDuration(moment.duration(shift.break_time, "seconds")),
        active: getTotalDuration(moment.duration(shift.active_time, "seconds")),
        manual: getTotalDuration(moment.duration(shift.manual_time, "seconds")),
        inactive: getTotalDuration(
          moment.duration(shift.inactive_time, "seconds")
        ),
        processing: getTotalDuration(
          moment.duration(shift.processing_time, "seconds")
        ),
        level: `${Math.round(shift.level)}%`,
      };
    })
    .filter((shift) => shift);

  const editColumn = {
    title: "",
    key: "edit",
    width: 50,
    render: (data: any) => (
      <DraftIcon
        className={cn("ShiftsTable__Column-draft", {
          "is-disabled": !editingAllowed,
        })}
        onClick={() => handleEditShift(data)}
      />
    ),
  };
  let columns:
    | ColumnsType<{
        key: string;
        id: string;
        start: string;
        end: string;
        isManual: boolean;
        isShiftChanged: boolean;
        isWorkChanged: boolean;
        isBreakChanged: boolean;
        notes: string;
        work: string;
        break: string;
        active: string;
        manual: string;
        inactive: string;
        processing: string;
        level: string;
      }>
    | undefined = [
    {
      title: "Shift",
      key: "shift",
      align: "center",
      render: (data: any, record: any) => {
        const startTime = moment(data.start).format("HH:mm");
        const endTime = data.end ? moment(data.end).format("HH:mm") : "Now";
        return (
          <div className="ShiftsTable__Column-shift">
            <span
              className={cn("ShiftsTable__Column-shift-time", {
                "ShiftsTable__Column-dot": record.isShiftChanged,
              })}
            >
              {startTime} - {endTime}
            </span>
            {record.isManual && (
              <ManuallyAddedIcon className="ShiftsTable__Column-shift-manual" />
            )}
            {!!record.notes && (
              <Tooltip
                overlayInnerStyle={{
                  maxWidth: "232px",
                  whiteSpace: "pre-wrap",
                  fontSize: "12px",
                  lineHeight: "17px",
                }}
                title={record.notes}
              >
                <CommentIcon className="ShiftsTable__Column-shift-comment" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Work",
      dataIndex: "work",
      key: "work",
      align: "right",
      render: (data: string, record: any) => (
        <span
          className={cn("ShiftsTable__Column-work", {
            "ShiftsTable__Column-dot": record.isWorkChanged,
          })}
        >
          {data}
        </span>
      ),
    },
    {
      title: "Break",
      dataIndex: "break",
      key: "break",
      align: "right",
      render: (data: string, record: any) => (
        <span
          className={cn("ShiftsTable__Column-break", {
            "ShiftsTable__Column-dot": record.isBreakChanged,
          })}
        >
          {data}
        </span>
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      align: "right",
      render: (data: string) => (
        <span className="ShiftsTable__Column-active">{data}</span>
      ),
    },
    {
      title: "Manual",
      dataIndex: "manual",
      key: "manual",
      align: "right",
      render: (data: string) => (
        <span className="ShiftsTable__Column-manual">{data}</span>
      ),
    },
    {
      title: "Inactive",
      dataIndex: "inactive",
      key: "inactive",
      align: "right",
      render: (data: string) => (
        <span className="ShiftsTable__Column-inactive">{data}</span>
      ),
    },
    {
      title: (
        <div className="ShiftsTable__Table-header">
          <span>Processing</span>
          <Tooltip
            overlayInnerStyle={{
              width: "232px",
              whiteSpace: "pre-wrap",
              fontSize: "12px",
              lineHeight: "17px",
            }}
            title="While time is still being processed, it is tentatively counted as tracked time."
          >
            <InfoCircleOutlinedIcon className="ShiftsTable__Table-icon" />
          </Tooltip>
        </div>
      ),
      dataIndex: "processing",
      key: "processing",
      align: "right",
      render: (data: string) => (
        <span className="ShiftsTable__Column-processing">{data}</span>
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      align: "right",
    },
  ];

  columns = isDev ? [editColumn, ...columns] : columns;

  const handleAddShift = async (start: Moment, end: Moment) => {
    let overlap = false;
    // check if the time is overlapped
    for (let shift of summaries) {
      const shiftStart = shift?.start ? moment(shift.start) : moment();
      const shiftEnd = shift?.end ? moment(shift.end) : moment();
      const startOverlap =
        shiftStart.isBefore(start) && shiftEnd.isAfter(start);
      const endOverlap = shiftStart.isBefore(end) && shiftEnd.isAfter(end);
      const inner = shiftStart.isAfter(start) && shiftEnd.isBefore(end);
      if (startOverlap || endOverlap || inner) {
        overlap = true;
        break;
      }
    }
    if (overlap) {
      Modal.warning({
        title: "Warning",
        content: "The time period can not overlap with an existing shift.",
      });
      return;
    }
    const payload = {
      work_shift: {
        work_day: workdayId,
        start_datetime: start,
        end_datetime: end,
      },
      status_list: [
        {
          user: userId,
          start_datetime: start,
          end_datetime: end,
          status_enum_identifier: WorkStatusTypesEnum.working,
        },
      ],
      change_log: {
        modified_user: userId,
        changed_value_type_enum: ChangedValueTypeEnum.work_shift_add,
        content_object: workdayId,
        changed_datetime: moment(),
      },
    };
    const response = await modifyShiftService(payload);
    if (response.success) {
      await requestWorkShifts();
      setShiftStart(start);
      setShiftEnd(end);
      setShiftModalOpen(true);
      setAddShiftModalOpen(false);
      setAddedShiftId(response.data.id);
      setEdit(false);
    } else {
      Modal.error({
        title: "Add manual shift error",
        content: response.error,
      });
    }
  };

  const handleEditShift = (data: any) => {
    const shift = workShifts.find((item) => item.id === data.id);
    if (shift) {
      setSelectedShift(shift);
      setEdit(true);
      setShiftModalOpen(true);
    }
  };

  const handleRefresh = async () => {
    if (!syncing) {
      setSyncing(true);
      await syncWorkShiftsService(checkoutDate, checkoutDate);
    }
  };

  const handleDelete = async (id: string) => {
    const response = await deleteShiftService(id);
    if (response.success) {
      setWorkShifts((shifts) => shifts.filter((shift) => shift.id !== id));
      setShiftModalOpen(false);
    } else {
      Modal.error({
        title: "Delete shift error",
        content: response.error,
      });
    }
  };

  const handleRefreshShifts = async () => {
    const shifts = await requestWorkShifts();

    // update selected shift so ShiftModal can be rerendered
    if (selectedShift) {
      const newShift = shifts.find((shift) => shift.id === selectedShift.id);
      if (newShift) {
        setSelectedShift(newShift);
      }
    }
  };

  return (
    <section className="ShiftsTable">
      <div className="ShiftsTable__Header">
        <div className="ShiftsTable__Title">Shifts</div>
        <div className="ShiftsTable__Extra">
          <Tooltip
            title="Refresh Tracked information from the source"
            overlayInnerStyle={{
              fontSize: "12px",
              lineHeight: "17px",
            }}
          >
            <div
              className={cn("ShiftsTable__Extra-fresh", {
                "is-disabled": !editingAllowed,
              })}
            >
              <RefreshIcon
                onClick={handleRefresh}
                className={cn("ShiftsTable__Extra-freshIcon", {
                  "ShiftsTable__Extra-freshIcon-loading": syncing,
                })}
              />
            </div>
          </Tooltip>
          <Button
            size="small"
            icon={<AddIcon className="ShiftsTable__Extra-add" />}
            onClick={() => setAddShiftModalOpen(true)}
            disabled={!editingAllowed}
          >
            Add manual shift
          </Button>
        </div>
      </div>
      <Table
        className="ShiftsTable__Table"
        columns={columns}
        dataSource={summaries}
        pagination={false}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon />,
        }}
      />
      <AddShiftModal
        open={addShiftModalOpen}
        onClose={() => setAddShiftModalOpen(false)}
        onOk={handleAddShift}
      />
      <ShiftModal
        workdayId={workdayId}
        checkoutDate={checkoutDate}
        userId={userId}
        open={shiftModalOpen}
        edit={edit}
        start={shiftStart}
        end={shiftEnd}
        shift={selectedShift}
        shiftId={addedShiftId}
        syncing={syncing}
        onClose={() => setShiftModalOpen(false)}
        onSync={handleRefresh}
        onDelete={handleDelete}
        onRefreshShifts={handleRefreshShifts}
      />
    </section>
  );
};

export default ShiftsTable;
