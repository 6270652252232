import { RootState } from "../store/configureStore";

export interface UserDetailsState extends BaseReducerState {
  data: AccountUser;
}
export interface UserDetailsRequestAction {
  type: "REQUEST_FETCH_USER_DETAILS" | "REQUEST_UPDATE_USER_DETAILS";
}
export interface UserDetailsRequestSuccessAction {
  type:
    | "REQUEST_FETCH_USER_DETAILS_SUCCESS"
    | "REQUEST_UPDATE_USER_DETAILS_SUCCESS";
  userDetails: AccountUser;
}
export interface UserDetailsRequestFailedAction {
  type:
    | "REQUEST_FETCH_USER_DETAILS__FAILED"
    | "REQUEST_UPDATE_USER_DETAILS__FAILED";
  error: string;
}
export interface UserDetailsDefaultAction {
  type: "SET_USER_DETAILS_DEFAULT";
}
export type UserDetailsActions =
  | UserDetailsRequestAction
  | UserDetailsRequestSuccessAction
  | UserDetailsRequestFailedAction
  | UserDetailsDefaultAction;
export const selectUserDetailsState = (rootState: RootState) =>
  rootState.userDetails;
export const userDetailsReducerDefaultState: UserDetailsState = {
  data: {} as AccountUser,
  loading: false,
  error: "",
};
const userDetailsReducer = (
  state = userDetailsReducerDefaultState,
  action: UserDetailsActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_USER_DETAILS":
    case "REQUEST_UPDATE_USER_DETAILS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_USER_DETAILS_SUCCESS":
    case "REQUEST_UPDATE_USER_DETAILS_SUCCESS":
      return {
        data: action.userDetails,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_USER_DETAILS__FAILED":
    case "REQUEST_UPDATE_USER_DETAILS__FAILED":
      return {
        ...state,
        loading: false,
        error: "",
      };
    case "SET_USER_DETAILS_DEFAULT":
      return userDetailsReducerDefaultState;
    default:
      return state;
  }
};

export { userDetailsReducer as default };
