import { Dispatch } from "redux";
import { store } from "../store/configureStore";
import {
  loginService,
  webhookLoginService,
  webhookLogoutService,
} from "../services/auth.service";
import { setAuthToken } from "../apis/django.instance";
import { dispatchError } from "../utils/error.util";
import {
  setUserDetailsDefault,
  startPopulateUserDetails,
} from "./user-details.action";
import { startPopulateStatusTypes } from "./status-types.action";

export const requestFetchLogin = () => ({
  type: "REQUEST_FETCH_LOGIN",
});
export const requestFetchLoginSuccess = (token: string, email: string) => {
  window.localStorage.setItem("token", token);
  window.localStorage.setItem("email", email);
  setAuthToken(token);
  return {
    type: "REQUEST_FETCH_LOGIN_SUCCESS",
    token,
    email,
  };
};
export const requestFetchLoginFailed = (error: string) => ({
  type: "REQUEST_FETCH_LOGIN_FAILED",
  error,
});

export const startLogin = (data: LoginService) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchLogin());
      const response = await loginService(data);
      if (response.status === 200) {
        dispatch(requestFetchLoginSuccess(response.data.token, data.email));
        store.dispatch(startPopulateStatusTypes()).then(() => {
          store.dispatch(startPopulateUserDetails());
        });
        return webhookLoginService();
      } else {
        const error = "loginService status is not 200";
        dispatch(requestFetchLoginFailed(error));
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchLoginFailed(error));
    }
  };
};

export const requestFetchLogout = () => ({
  type: "REQUEST_FETCH_LOGOUT",
});
export const requestFetchLogoutSuccess = () => {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("email");
  return { type: "REQUEST_FETCH_LOGOUT_SUCCESS" };
};
export const requestFetchLogoutFailed = (error: string) => ({
  type: "REQUEST_FETCH_LOGOUT_FAILED",
  error,
});
export const startLogout = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchLogout());
      const response = await webhookLogoutService();
      if (response.status === 200) {
        dispatch(requestFetchLogoutSuccess());
        dispatch(setUserDetailsDefault());
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchLogoutFailed(error));
      dispatchError({
        e,
        title: "Logout error",
      });
    }
  };
};
