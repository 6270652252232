import React from "react";

import StarredTeamMemberItem from "../StarredTeamMemberItem/StarredTeamMemberItem";

const StarredTeamMemberList: React.FC<StarredTeamMemberListProps> = ({
  endDate,
  onClickCallback,
  maxCount = 11,
  starredObservableUsers,
  startDate,
}) => {
  return (
    <div className="StarredTeamMemberList">
      {starredObservableUsers.map((observableUser, index) => {
        const count = index + 1;
        if (count <= maxCount) {
          return (
            <StarredTeamMemberItem
              key={index}
              endDate={endDate}
              observableUser={observableUser}
              onClickCallback={onClickCallback}
              startDate={startDate}
            />
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
};

export default StarredTeamMemberList;
