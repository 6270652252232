import React from "react";
import { Popover } from "antd";
import moment from "moment";

import Button from "../../Button/Button";
import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";
import { getTotalDuration } from "../../../utils/time-conversion.util";

const DeleteShiftPopover: React.FC<DeleteShiftPopoverProps> = ({
  start,
  end,
  checkoutDate,
  current,
  onCancel,
  onDelete,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [workTimes] = useWorkTimesByDate(checkoutDate);
  const endOrNow = end || moment();
  const unallocatedTime = workTimes.work_time - workTimes.tasks_allocated_time;
  const shiftTime = current ? 0 : endOrNow.diff(start) / 1000;
  const removeable = unallocatedTime - shiftTime >= 0;
  const startTime = start.format("HH:mm");
  const endTime = endOrNow.format("HH:mm");
  const diff = endOrNow.diff(start);

  const handleDelete = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
  };
  return (
    <div className="DeleteShiftPopover">
      <div className="DeleteShiftPopover__Title">
        {!current && !removeable
          ? "Before removing this shift, reduce the allocated time"
          : "Delete shift forever?"}
      </div>
      {removeable && (
        <div className="DeleteShiftPopover__Time">
          {startTime} - {endTime} ({getTotalDuration(moment.duration(diff))})
        </div>
      )}
      <div className="DeleteShiftPopover__Btns">
        <Button
          size="small"
          className="DeleteShiftPopover__Cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
        {current ? (
          <Popover
            getPopupContainer={(trigger: any) => trigger}
            content="The current shift cannot be deleted. Switch to Invisible mode and then try again."
          >
            <div>
              <Button
                style={{ pointerEvents: "none" }}
                size="small"
                type="primary"
                danger={true}
                disabled={true}
              >
                Delete
              </Button>
            </div>
          </Popover>
        ) : (
          <Button
            size="small"
            type="primary"
            danger={true}
            disabled={!removeable}
            loading={loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default DeleteShiftPopover;
