import React from "react";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import cn from "classnames";
import { isEmpty } from "lodash";

import CheckoutTaskCollapsePanel from "../../../Checkout/CheckoutTaskCollapsePanel/CheckoutTaskCollapsePanel";
import CustomDateRangePicker from "../../../CustomDateRangePicker/CustomDateRangePicker";
// import { getWorkDaysInRange } from "../../../Tasks/HistoryPanel/HistoryPanel";
import SummaryOfTheDay from "../../../Checkout/SummaryOfTheDay/SummaryOfTheDay";
import RefreshBtn from "../../../General/RefreshBtn/RefreshBtn";
import TeamHistoryDateList from "../../../Team/TeamHistoryDateList/TeamHistoryDateList";
import TeamHistoryMemberList from "../../../Team/TeamHistoryMemberList/TeamHistoryMemberList";
import TeamMemberSelector from "../../../Team/TeamMemberSelector/TeamMemberSelector";
import { TeamPanelContext } from "../../../Team/TeamPanel/TeamPanel";
import WorkTimeRecordTable from "../../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";

import { workDayDateFormat } from "../../../../constants/constants";
import { selectThirdPartyTrackerState } from "../../../../reducers/third-party-tracking.reducer";
import { getUserTaskTimeTableDataService } from "../../../../services/task-time.service";
import { minutesToSeconds } from "../../../../utils/time-conversion.util";
import { getUserWorkTimeTableDataCompleteRecords } from "../../../../utils/task-time.util";
import { RangePickerProps } from "antd/lib/date-picker";

type GroupBy = "date" | "member";
export interface DayInRangeWithTableData {
  day: Moment;
  tableData: MemberWorkTimeRecordTableArray;
}
export type DaysInRangeWithTableData = DayInRangeWithTableData[];

export const groupByArray: GroupBy[] = ["date", "member"];

export const getObservableUserWithWorkDay = async (
  observableUser: ObservableUserObject,
  day: Moment,
  workDays: WorkDayArray
) => {
  const getTeamMemberWorkDayByDate = async (
    observableUser: ObservableUserObject,
    workDate: WorkDayObject["work_date"],
    workDays: WorkDayArray
  ) => {
    /**
     * TODO
     * Replace with endpoints to get work day record of team member by work date
     * Cannot compare using workDays global state as this only has current user's work days
     */
    return workDays.find(({ user, work_date }) => {
      return user === observableUser.observed_user.id && work_date === workDate;
    });
  };

  const workDay = await getTeamMemberWorkDayByDate(
    observableUser,
    day.format(workDayDateFormat),
    workDays
  );
  return { ...observableUser, workDay };
};

export const getUserWorkTimeTableData = async (
  endDate: Moment,
  shownObservableUsers: ObservableUserArray,
  startDate: Moment,
  thirdPartyTracker: ExternalTimeTrackerObject
) => {
  const shownObservableUsersWithTableData: ShownObservableUserWithTableDataObject[] = await Promise.all(
    shownObservableUsers.map(async (shownObservableUser) => {
      const { observed_user } = shownObservableUser;
      const { id: observedUserId } = observed_user;
      const defaultReturn = {
        shownObservableUser,
        tableData: undefined,
      };
      try {
        const data = {
          fromDate: startDate.format(workDayDateFormat),
          timeTrackerId: thirdPartyTracker.id,
          toDate: endDate.format(workDayDateFormat),
          userId: observedUserId,
        };
        const response = await getUserTaskTimeTableDataService(data);
        if (response.status === 200) {
          const tableData = getUserWorkTimeTableDataCompleteRecords(
            startDate,
            endDate,
            response.data
          );
          return {
            shownObservableUser,
            tableData,
          };
        } else {
          return defaultReturn;
        }
      } catch (e) {
        return defaultReturn;
      }
    })
  );
  return shownObservableUsersWithTableData;
};

export const HistoryTabPaneContext = React.createContext<HistoryTabPaneContext>(
  {} as HistoryTabPaneContext
);

const HistoryTabPane: React.FC = () => {
  const { shownObservableUsers } = React.useContext(TeamPanelContext);

  const { data: thirdPartyTracker } = useSelector(selectThirdPartyTrackerState);

  const [startDate, setStartDate] = React.useState<Moment>();
  const [endDate, setEndDate] = React.useState<Moment>();
  const [groupBy, setGroupBy] = React.useState<GroupBy>("date");
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastUpdated, setLastUpdated] = React.useState<Moment>();
  const [tooltipText, setTooltipText] = React.useState<string>();
  const [
    shownObservableUserWithTableDataArray,
    setShownObservableUserWithTableDataArray,
  ] = React.useState<ShownObservableUserWithTableDataArray>();

  const dateIsSame = startDate && endDate && startDate.isSame(endDate, "day");

  const handleChangeCalendar: RangePickerProps["onCalendarChange"] = React.useCallback(
    (dates, dateString, info) => {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    },
    []
  );
  const handleClickReload = async () => {
    getShownObservableUsersTableData();
  };
  const handlePresetClick = React.useCallback((dates: [Moment, Moment]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  }, []);

  React.useEffect(() => {
    if (isLoading) setLastUpdated(moment());
  }, [isLoading]);

  const updateTooltipText = React.useCallback(() => {
    setTooltipText(`Last updated ${lastUpdated?.fromNow()}. Click to refresh.`);
  }, [lastUpdated]);

  React.useEffect(() => {
    let intervalID = setInterval(() => {
      updateTooltipText();
    }, minutesToSeconds(1));

    return () => clearInterval(intervalID);
  }, [updateTooltipText]);

  const getShownObservableUsersTableData = React.useCallback(async () => {
    if (
      startDate &&
      endDate &&
      shownObservableUsers.length > 0 &&
      !isEmpty(thirdPartyTracker)
    ) {
      setIsLoading(true);
      const shownObservableUsersWithTableData: ShownObservableUserWithTableDataObject[] = await getUserWorkTimeTableData(
        endDate,
        shownObservableUsers,
        startDate,
        thirdPartyTracker
      );
      setShownObservableUserWithTableDataArray(
        shownObservableUsersWithTableData
      );
      setIsLoading(false);
    } else {
      setShownObservableUserWithTableDataArray([]);
    }
  }, [endDate, shownObservableUsers, startDate, thirdPartyTracker]);

  React.useEffect(() => {
    getShownObservableUsersTableData();
  }, [getShownObservableUsersTableData]);

  const groupByJsx = (
    <div className="HistoryTabPane__Header__GroupBy">
      {groupByArray.map((group, index) => (
        <div
          key={index}
          className={cn("HistoryTabPane__Header__GroupBy__Item", {
            "HistoryTabPane__Header__GroupBy__Item--Active": groupBy === group,
            "HistoryTabPane__Header__GroupBy__Item--Disabled":
              groupBy !== group && isLoading,
          })}
          onClick={() => setGroupBy(group)}
        >
          <span>{group}</span>
        </div>
      ))}
    </div>
  );

  return (
    <HistoryTabPaneContext.Provider
      value={{
        shownObservableUserWithTableDataArray,
      }}
    >
      <div className="HistoryTabPane">
        <div className="HistoryTabPane__Header">
          <RefreshBtn
            tooltipText={lastUpdated ? tooltipText : undefined}
            onClick={handleClickReload}
            disabled={isLoading}
          />
          <CustomDateRangePicker
            onCalendarChange={handleChangeCalendar}
            onPresetClick={handlePresetClick}
            startDateValue={startDate}
            endDateValue={endDate}
          />
          {groupByJsx}
        </div>
        <TeamMemberSelector startDate={startDate} endDate={endDate} />
        <div className="HistoryTabPane__Body">
          <div className="HistoryTabPane__Body__DateRangeDisplay">
            {moment(startDate).format("dddd, MMMM D")}
            {!dateIsSame && (
              <span className="HistoryTabPane__Body__DateRangeDisplay__EndDate">
                &ndash;{moment(endDate).format("dddd, MMMM D")}
              </span>
            )}
          </div>

          {isLoading && groupBy === "date" && (
            <WorkTimeRecordTable isLoading={true} type="member" />
          )}

          {!isLoading &&
            groupBy === "date" &&
            shownObservableUserWithTableDataArray && (
              <TeamHistoryDateList
                shownObservableUserWithTableDataArray={
                  shownObservableUserWithTableDataArray
                }
              />
            )}

          {isLoading && groupBy === "member" && (
            <WorkTimeRecordTable isLoading={true} type="date" />
          )}

          {!isLoading &&
            groupBy === "member" &&
            shownObservableUserWithTableDataArray && (
              <TeamHistoryMemberList
                endDate={endDate}
                shownObservableUserWithTableDataArray={
                  shownObservableUserWithTableDataArray
                }
                startDate={startDate}
              />
            )}

          {groupBy === "date" &&
            startDate &&
            endDate &&
            shownObservableUsers.length > 0 && (
              <SummaryOfTheDay
                date={[
                  startDate.format(workDayDateFormat),
                  endDate.format(workDayDateFormat),
                ]}
                type="team"
                users={shownObservableUsers.map(
                  ({ observed_user }) => observed_user
                )}
              />
            )}

          {startDate && endDate && (
            <CheckoutTaskCollapsePanel
              activitiesSummaryType="team"
              dates={[
                startDate.format(workDayDateFormat),
                endDate.format(workDayDateFormat),
              ]}
              users={shownObservableUsers.map(
                ({ observed_user }) => observed_user
              )}
            />
          )}
        </div>
      </div>
    </HistoryTabPaneContext.Provider>
  );
};

export default HistoryTabPane;
