import { AxiosResponse } from "axios";
import moment, { Moment } from "moment";

import axios from "../apis/django.instance";
import {
  WorkStatusTypesEnum,
  ChangedValueTypeEnum,
} from "../constants/constants";

export const getWorkShiftListService = () => {
  return axios.get("/api/work/workshift/"); // must be in this format in order to mock implementation in unit test
};

export const getWorkShiftRecordService: GetWorkShiftRecordService = (data) => {
  const { id } = data;
  return axios.get(`/api/work/workshift/${id}/`); // must be in this format in order to mock implementation in unit test
};

export interface FetchLatestWorkShiftServiceData {
  user: UserDetailsObject["id"];
  from_date?: string;
  to_date?: string;
  manual_work_shift_yn?: boolean;
  ordering?: string;
}
export const fetchLatestWorkShiftService = (
  data: FetchLatestWorkShiftServiceData
) => {
  return axios.get("/api/work/latest_workshift//", { params: data });
};

export interface UpdateWorkShiftRecordServiceData {
  id: WorkShiftObject["id"];
  end_datetime?: WorkShiftObject["end_datetime"];
  shift_notes?: string;
}
export type UpdateWorkShiftRecordService = (
  data: UpdateWorkShiftRecordServiceData
) => Promise<AxiosResponse<WorkShiftObject>>;
export const updateWorkShiftRecordService: UpdateWorkShiftRecordService = (
  data
) => {
  const { id, ...payload } = data;
  return axios.put(`/api/work/workshift/${id}/`, payload); // must be in this format in order to mock implementation in unit test
};

export const getWorkShiftLogsService: GetWorkShiftLogsService = (
  date: string
) => {
  return axios.get(`/api/work/change_log/`);
};

export const getWorktimeSummary = (date: string) => {
  return axios.get(`/api/work/worktime_summary/?date=${date}`);
};

export const getWorkShiftSchedule = (
  fromDate: string,
  toDate: string,
  user: string
) => {
  return axios.get(
    `/api/work/workshiftschedule/?from_date=${fromDate}&to_date=${toDate}&user=${user}`
  );
};

type CreateShiftServiceParams = {
  work_day: string;
  start_datetime: Moment;
  end_datetime: Moment;
  user: string;
};
export const createShiftService = async ({
  work_day,
  start_datetime,
  end_datetime,
  user,
}: CreateShiftServiceParams) => {
  const data = {
    work_shift: {
      work_day,
      start_datetime,
      end_datetime,
    },
    status_list: [
      {
        user,
        start_datetime,
        end_datetime,
        status_enum_identifier: "working",
      },
    ],
    change_log: {
      modified_user: user,
      changed_value_type_enum: "work_shift_add",
      content_object: work_day,
      changed_datetime: moment(),
    },
  };
  try {
    const response = await axios.post(
      "/api/work/workshift/shift_modification/",
      data
    );
    if (response.status === 200) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  } catch (e) {
    const errorResponse = (e as any).response;
    return {
      success: false,
      error: errorResponse.data.work_shift.join(" ."),
    };
  }
};

type ModifyShiftServiceParams = {
  work_shift: {
    work_day: string;
    start_datetime: Moment;
    end_datetime: Moment | null;
  };
  status_list: {
    user: string;
    start_datetime: Moment;
    end_datetime: Moment | null;
    status_enum_identifier: WorkStatusTypesEnum;
  }[];
  change_log: {
    modified_user: string;
    changed_value_type_enum: ChangedValueTypeEnum;
    content_object: string;
    changed_datetime: Moment;
  };
};
export const modifyShiftService = async (payload: ModifyShiftServiceParams) => {
  try {
    const response = await axios.post(
      "/api/work/workshift/shift_modification/",
      payload
    );
    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
      };
    }
    return {
      success: false,
    };
  } catch (e) {
    const errorResponse = (e as any).response;
    return {
      success: false,
      error: errorResponse.data.work_shift.join(" ."),
    };
  }
};

export const updateShiftService = async (shift: Partial<WorkShiftObject>) => {
  try {
    const response = await axios.patch(
      `/api/work/workshift/${shift.id}/`,
      shift
    );
    if (response.status === 200) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const deleteShiftService = async (shiftId: string) => {
  try {
    const response = await axios.delete(`/api/work/workshift/${shiftId}`);
    if (response.status === 204) {
      return { success: true };
    }
    return { success: false };
  } catch (e) {
    const errorResponse = (e as any).response;
    return {
      success: false,
      error: errorResponse.data,
    };
  }
};
