import React from "react";
import moment from "moment";

const StartWorkTimeDisplay = (props: StartWorkTimeDisplayProps) => {
  const { time } = props;
  return (
    <span className="StartWorkTimeDisplay">
      <span>{moment(time).format("HH:mm")} </span>
    </span>
  );
};

export { StartWorkTimeDisplay as default };
