import React from "react";
import * as _ from "lodash";
import cn from "classnames";

import { TeamPanelContext } from "../TeamPanel/TeamPanel";
import StarredTeamMemberList from "./StarredTeamMemberList/StarredTeamMemberList";
import TeamMemberSearch from "./TeamMemberSearch/TeamMemberSearch";
import TeamMemberSelectorMoreActions from "./TeamMemberSelectorMoreActions/TeamMemberSelectorMoreActions";

import { partialUpdateObservableUserService } from "../../../services/observable_users.service";

const TeamMemberSelector: React.FC<TeamMemberSelectorProps> = ({
  endDate,
  startDate,
}) => {
  const {
    selectedObservableUser,
    observableUsers,
    setObservableUsers,
    shownObservableUsers,
  } = React.useContext(TeamPanelContext);

  const unshownObservableUsers = React.useMemo(
    () => observableUsers.filter(({ shown_yn }) => !shown_yn),
    [observableUsers]
  );

  const starredObservableUsers = React.useMemo(
    () => observableUsers.filter(({ starred_yn }) => starred_yn),
    [observableUsers]
  );

  const updateObservableUsersShownStatus = React.useCallback(
    async (
      observableUsers: ObservableUserArray,
      shown_yn: ObservableUserObject["shown_yn"]
    ) => {
      if (observableUsers.length > 0) {
        try {
          await Promise.all(
            observableUsers.map(
              async (observableUser: ObservableUserObject) => {
                const { id } = observableUser;
                const data = {
                  id,
                  shown_yn,
                };
                const response = await partialUpdateObservableUserService(data);
                if (response.status === 200) {
                  // synchronization is handled in src\modules\Team\TeamPanel\TeamPanel.tsx
                }
              }
            )
          );
        } catch (e) {}
      }
    },
    []
  );

  const handleSelectAll = React.useCallback(async () => {
    await updateObservableUsersShownStatus(unshownObservableUsers, true);
  }, [unshownObservableUsers, updateObservableUsersShownStatus]);

  const handleDeselectAll = React.useCallback(async () => {
    await updateObservableUsersShownStatus(shownObservableUsers, false);
  }, [shownObservableUsers, updateObservableUsersShownStatus]);

  const handleSelectMember = async ({ id, shown_yn }: ObservableUserObject) => {
    const data = {
      id,
      shown_yn: !shown_yn,
    };

    try {
      const response = await partialUpdateObservableUserService(data);
      if (response.status === 200) {
        const updatedRecord: ObservableUserObject = response.data;
        setObservableUsers((c) => {
          return c.map((observableUser) => {
            if (updatedRecord.id === observableUser.id) {
              return { ...observableUser, ...updatedRecord };
            } else {
              return observableUser;
            }
          });
        });
      }
    } catch (e) {}
  };

  return (
    <div
      className={cn("TeamMemberSelector", {
        "TeamMemberSelector--MemberSelected": selectedObservableUser,
      })}
    >
      {!selectedObservableUser && (
        <StarredTeamMemberList
          endDate={endDate}
          onClickCallback={handleSelectMember}
          starredObservableUsers={starredObservableUsers}
          startDate={startDate}
        />
      )}
      <div className="TeamMemberSelector__Actions">
        <TeamMemberSearch
          observableUsers={observableUsers}
          setObservableUsers={setObservableUsers}
        />
        <TeamMemberSelectorMoreActions
          selectAll={handleSelectAll}
          deselectAll={handleDeselectAll}
        />
      </div>
    </div>
  );
};

export default TeamMemberSelector;
