import React, { useState } from "react";
import { Popover, Input, Empty } from "antd";
import MemberOption from "./MemberOption.component";
import { CloseIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * This component is used to search the members for a project.
 * The searching space is all the users of the workspace for current user.
 * The search result will only be displayed when user type the keyword to this component.
 */
const MembersSearchBar: React.FC<MembersSearchBarProps> = ({
  members,
  type,
  className,
  width = 360,
  onMemberAdd,
  popoverClassName,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");
  const inputRef = React.useRef<Input>(null);
  const widthStyle = typeof width === "number" ? `${width}px` : width;
  const filterMembers = members.filter(
    (member) =>
      value &&
      member.displayName.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );
  const memberOptions = filterMembers.map((item, index) => (
    <MemberOption
      key={index}
      avatar={item.avatar}
      name={item.displayName}
      type={type}
      onMemberAdd={() => handleMemberAdd(item.id)}
    />
  ));
  const content = (
    <div style={{ width: widthStyle }}>
      {filterMembers.length > 0 ? (
        memberOptions
      ) : (
        <div className="MembersSearchBar__Empty">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
  const handleChange = (e: any) => {
    setVisible(true);
    setValue(e.target.value);
  };
  const handleClose = () => {
    setValue("");
    setVisible(false);
  };
  const handleMemberAdd = async (id: string) => {
    await onMemberAdd(id);
    setValue("");
    setVisible(false);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <Popover
      className={`MembersSearchBar ${className}`}
      overlayClassName={`MembersSearchBar__Popover ${popoverClassName}`}
      content={content}
      placement="bottom"
      visible={visible}
      getPopupContainer={(trigger: any) => trigger}
      {...rest}
    >
      <div className="MembersSearchBar__Input">
        <Input
          ref={inputRef}
          value={value}
          placeholder={
            type === "project"
              ? "Search or add new project member"
              : "Search or add new member"
          }
          onChange={handleChange}
        />
        {visible && (
          <div className="MembersSearchBar__CloseIcon">
            <CloseIcon
              style={{ fontSize: "16px", color: "#0000007F" }}
              onClick={handleClose}
            />
          </div>
        )}
      </div>
    </Popover>
  );
};

export default MembersSearchBar;
