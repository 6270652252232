import moment from "moment";

export const teammates: Teammate[] = [
  {
    status: "taking_break",
    today_starttime: moment().subtract(50, 'minutes').toString(),
    today_endtime: moment().add(340, 'minutes').toString(),
    next_workday: moment().add(1, 'day').toString(),
    pinned_yn: false,
    left_the_office_today_yn: false,
    user: "1",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    city: "Manila",
    country: "Philippines",
    email: "briggs@meistery.net",
    full_name: "Briggs Victoria",
    display_name: "Briggs",
    timezone: "UTC",
    role: "Developer",
  },
  {
    status: "working",
    today_starttime: moment().subtract(50, 'minutes').toString(),
    today_endtime: moment().add(340, 'minutes').toString(),
    next_workday: moment().add(1, 'day').toString(),
    pinned_yn: true,
    left_the_office_today_yn: false,
    user: "2",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    city: "Shanghai",
    country: "China",
    email: "kenny@meistery.net",
    full_name: "Kenny Liu",
    display_name: "Kenny",
    timezone: "UTC+8",
    role: "FE Developer",
  },
  {
    status: "working",
    today_starttime: moment().subtract(120, 'minutes').toString(),
    today_endtime: moment().add(230, 'minutes').toString(),
    next_workday: moment().add(1, 'day').toString(),
    pinned_yn: false,
    left_the_office_today_yn: false,
    user: "3",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    city: "Tokyo",
    country: "Japan",
    email: "mike@meistery.net",
    full_name: "Mike",
    display_name: "mike",
    timezone: "UTC+9",
    role: "Manager",
  },
];
