import React from "react";
import classNames from "classnames";
import { Button, ButtonProps as AntButtonProps } from "antd";

/**
 * Default Display
 * Extends and displays re-styled Ant Design Button
 * https://ant.design/components/button/
 *
 * Functionality
 * Takes similar props as Ant Design Button
 */
const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { className = "", type, ...rest } = props;
  return (
    <Button
      {...rest}
      className={classNames("CustomButton", className, {
        "CustomButton--Success": type === "success",
      })}
    ></Button>
  );
};

export { CustomButton as default };
