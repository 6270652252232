import { Dispatch } from "redux";
import { findIndex } from "lodash";

import { store } from "../store/configureStore";

import {
  getProjectsListService,
  createProjectService,
  updateProjectService,
} from "../services/projects.service";
import { startPopulateLists } from "../actions/list.action";
import { dispatchError } from "../utils/error.util";
import { fetchProjectColorService } from "../services/projects-colors.service";

export const requestFetchProjects = () => ({
  type: "REQUEST_FETCH_PROJECTS",
});
export const requestFetchProjectsSuccess = (projects: ProjectArray) => ({
  type: "REQUEST_FETCH_PROJECTS_SUCCESS",
  projects,
});
export const requestFetchProjectsFailed = (error: string) => ({
  type: "REQUEST_FETCH_PROJECTS_FAILED",
  error,
});
export const startPopulateProjects = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchProjects());
      const response = await getProjectsListService();
      if (response.status === 200) {
        // need projectsColors to be populated before running
        const projects = response.data;
        const projectsInOrder: ProjectArray = projects
          .filter(({ archived_yn }) => !archived_yn) // filter archived projects for all members
          .sort((a, b) => {
            return a.title.localeCompare(b.title);
          })
          // add loading state for each record
          .map((project) => ({ ...project, loading: false }));
        dispatch(requestFetchProjectsSuccess(projectsInOrder));
      } else {
        const error = "getProjectsListService status is not 200";
        dispatch(requestFetchProjectsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchProjectsFailed(error));
      dispatchError({
        e,
        title: "Get projects error",
      });
    }
  };
};

export const requestCreateProject = () => ({
  type: "REQUEST_CREATE_PROJECT",
});
export const requestCreateProjectSuccess = (project: ProjectObject) => ({
  type: "REQUEST_CREATE_PROJECT_SUCCESS",
  project,
});
export const requestCreateProjectFailed = (error: string) => ({
  type: "REQUEST_CREATE_PROJECT_FAILED",
  error,
});
export const startCreateProject = (project: ProjectObject) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateProject());
      const response = await createProjectService(project);
      const projectsColors: ProjectColorArray = store.getState().projectsColors
        .data;
      if (response.status === 201) {
        // need projectsColors to be populated before running
        if (projectsColors.length > 0) {
          dispatch(startPopulateLists());
          const createdProject = response.data;
          dispatch(requestCreateProjectSuccess(createdProject));
          return {
            success: true,
            payload: createdProject,
          };
        }
      } else {
        const error = "createProjectService status is not 201";
        dispatch(requestCreateProjectFailed(error));
        throw new Error();
      }
      return {
        success: false,
      };
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateProjectFailed(error));
      dispatchError({
        e,
        title: "Create project error",
      });

      const data = (e as any).response;
      const errors = data.non_field_errors;
      // project title duplicated
      if (
        errors &&
        errors.length > 0 &&
        errors[0] === "The fields workspace, title must make a unique set."
      ) {
        return {
          success: false,
          message: "duplicated",
        };
      }

      return {
        success: false,
      };
    }
  };
};

export const startUpdateProject = (project: ProjectObject) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_UPDATE_PROJECT",
        project,
      });
      const response = await updateProjectService(project);
      if (response.status === 200) {
        const updatedProject = response.data;
        const updatedProjectColorId = updatedProject.color;

        // get project color object as response only returns id
        try {
          const fetchProjectColorServiceResponse = await fetchProjectColorService(
            updatedProjectColorId
          );
          if (fetchProjectColorServiceResponse.status === 200) {
            dispatch({
              type: "REQUEST_UPDATE_PROJECT_SUCCESS",
              project: {
                ...updatedProject,
                color: fetchProjectColorServiceResponse.data as ProjectColorObject,
              },
            });
            return {
              success: true,
            };
          }
        } catch (e) {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_UPDATE_PROJECT_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Update project error",
      });

      const { data } = (e as any).response;
      const errors = data.non_field_errors;
      // project title duplicated
      if (
        errors &&
        errors.length > 0 &&
        errors[0] === "The fields workspace, title must make a unique set."
      ) {
        return {
          success: false,
          message: "duplicated",
        };
      }

      return {
        success: false,
      };
    }
  };
};
