import { Tooltip } from "antd";
import React from "react";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import ObserversTooltip from "./ObserversTooltip";
import { getInitials } from "../../../utils/string.util";

const Observers: React.FC<ObserversProps> = ({ observers }) => {

  return (
    <>
      {observers.length > 0 && (
        <Tooltip
          placement="bottom"
          title={<ObserversTooltip observers={observers} />}
        >
          <div className="Observers">
            <AvatarGroup
              avatars={observers.map((ob) => ob.avatar)}
              avatarsFallBackText={observers.map((ob) => getInitials(ob.displayName))}
              maxCount={5}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default Observers;
