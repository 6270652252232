import React from "react";
import cn from "classnames";
import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { TeamPanelContext } from "../../../TeamPanel/TeamPanel";
import {
  CloseIcon,
  StarFilledIcon,
  StarOutlineIcon,
} from "../../../../CustomIcons/CustomIcons.component";
import CustomAvatar from "../../../../CustomAvatar/CustomAvatar";

import { partialUpdateObservableUserService } from "../../../../../services/observable_users.service";
import { getInitials } from "../../../../../utils/string.util";

export const getFilteredObservableUser = (
  searchText: string,
  observableUsers: ObservableUserArray
) => {
  const searchLowerCase = searchText.toLowerCase();
  return observableUsers.filter(({ observed_user }) => {
    const { display_name = "" } = observed_user || {};
    return display_name.toLowerCase().includes(searchLowerCase);
  });
};

const TeamMemberSearchPopoverContent: React.FC<TeamMemberSearchPopoverContentProps> = ({
  onClickClose,
  setObservableUsers,
  observableUsers,
}) => {
  const {
    selectedObservableUser,
    setSelectedObservableUser,
  } = React.useContext(TeamPanelContext);

  const [searchText, setSearchText] = React.useState("");
  const [
    filteredObservableUsers,
    setFilteredObservableUsers,
  ] = React.useState<ObservableUserArray>([]);

  const { observed_user: selectedObservableUserObservedUser } =
    selectedObservableUser || {};
  const {
    avatar: selectedObservableUserObservedUserAvatar,
    display_name: selectedObservableUserObservedUserDisplayName,
    current_status: selectedObservableUserObservedUserCurrentStatus,
  } = selectedObservableUserObservedUser || {};
  const {
    status_enum_identifier: selectedObservableUserObservedUserStatusEnumIdentifier,
  } = selectedObservableUserObservedUserCurrentStatus || {};

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleClickTeamMember = (observableUser: ObservableUserObject) => (
    e: React.MouseEvent
  ) => {
    setSelectedObservableUser(observableUser);
  };

  const handleClickStar = ({ id, starred_yn }: ObservableUserObject) => async (
    e: React.MouseEvent
  ) => {
    e.stopPropagation();

    const data = {
      id,
      starred_yn: !starred_yn,
    };

    try {
      const response = await partialUpdateObservableUserService(data);
      if (response.status === 200) {
        const updatedRecord: ObservableUserObject = response.data;
        setObservableUsers((currentValue) => {
          return currentValue.map((observableUser) => {
            if (updatedRecord.id === observableUser.id) {
              return { ...observableUser, ...updatedRecord };
            } else {
              return observableUser;
            }
          });
        });
      }
    } catch (e) {}
  };

  const handleClickClose = (e: React.MouseEvent) => {
    setSelectedObservableUser(undefined);
    onClickClose();
  };

  React.useEffect(() => {
    setFilteredObservableUsers(
      getFilteredObservableUser(searchText, observableUsers)
    );
  }, [searchText, observableUsers]);

  const teamMembersJsx = (
    <div className="TeamMemberSearchPopoverContent__MembersList">
      {filteredObservableUsers.map((observableUser, index) => {
        const { observed_user, starred_yn } = observableUser;
        const { avatar = "", display_name = "" } = observed_user || {};
        const StarIcon = starred_yn ? StarFilledIcon : StarOutlineIcon;
        return (
          <div
            key={index}
            className="TeamMemberSearchPopoverContent__MembersList__Item"
            onClick={handleClickTeamMember(observableUser)}
          >
            <div className="TeamMemberSearchPopoverContent__MembersList__Item__UserDetails">
              <CustomAvatar
                size={24}
                src={avatar}
                work_status={
                  selectedObservableUserObservedUserStatusEnumIdentifier
                }
                fallbackText={getInitials(display_name)}
              />
              <span>{display_name}</span>
            </div>
            <StarIcon
              className={cn(
                "TeamMemberSearchPopoverContent__MembersList__Item__Star",
                {
                  "TeamMemberSearchPopoverContent__MembersList__Item__Star--Active": starred_yn,
                }
              )}
              onClick={handleClickStar(observableUser)}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="TeamMemberSearchPopoverContent">
      <CloseIcon
        className="TeamMemberSearchPopoverContent__Close"
        onClick={handleClickClose}
      />
      {selectedObservableUser ? (
        <div className="TeamMemberSearchPopoverContent__SelectedMember">
          <CustomAvatar
            size={24}
            src={selectedObservableUserObservedUserAvatar}
            work_status={
              selectedObservableUserObservedUserCurrentStatus?.status_enum_identifier
            }
          />
          <span>{selectedObservableUserObservedUserDisplayName}</span>
        </div>
      ) : (
        <>
          <div className="TeamMemberSearchPopoverContent__Search">
            <Input
              value={searchText}
              size={"default" as SizeType}
              placeholder="Enter a member name"
              className="TeamMemberSearchPopoverContent__Search__Input"
              onChange={handleOnChange}
            />
          </div>
          {filteredObservableUsers.length > 0 && teamMembersJsx}
        </>
      )}
    </div>
  );
};

export default TeamMemberSearchPopoverContent;
