import React from "react";
import { Input, Popover } from "antd";
import cn from "classnames";
import { useSelector } from "react-redux";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import useAsyncSearchTaskArrayByTitle from "../../../hooks/useAsyncSearchTaskArrayByTitle.hook";
import useDebounce from "../../../hooks/useDebounce.hook";

import CreateNewTaskButton from "../CreateNewTaskButton/CreateNewTaskButton";
import {
  CloseIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import SearchTaskCardList from "../../Tasks/SearchTaskCardList/SearchTaskCardList";

import { selectProjectsState } from "../../../reducers/projects.reducer";

export interface TaskSearchBarContext {
  activitiesSummaryType?: ActivitiesSummaryType;
  isMerge?: boolean;
  handleTaskSelect?: (task: TaskJoinedToUserAndScheduledObject) => void;
  panelType: PanelType;
  searchText: string;
  taskPage?: TaskPage;
}
export const TaskSearchBarContext = React.createContext<TaskSearchBarContext>(
  {} as TaskSearchBarContext
);

/**
 * TaskSearchBar is used for searching or creating new tasks
 *
 * Default Display
 * Input for search text or task title for creation
 *
 * Conditional Display
 * CreateNewTaskButton is displayed when searchText is not blank string
 * CloseIcon is displayed when searchText is not blank string
 * SearchTaskCardList displayed when searchText is not blank string
 */
export interface TaskSearchBarProps extends MyDeskTaskPanelProps {
  activitiesSummaryType?: ActivitiesSummaryType;
  handleSearchClose?: Function;
  handleTaskSelect?: (task: TaskJoinedToUserAndScheduledObject) => void;
  isMerge?: boolean;
  panelType: PanelType;
}
const TaskSearchBar: React.FC<TaskSearchBarProps> = ({
  activitiesSummaryType,
  handleSearchClose,
  handleTaskSelect,
  isMerge = false,
  panelType,
  taskPage,
}) => {
  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );
  const [searchText, setSearchText] = React.useState("");

  const debounceSearchText = useDebounce(searchText, 1000);

  const [searchTaskArray, loading] = useAsyncSearchTaskArrayByTitle(
    debounceSearchText
  );
  const isSearching = debounceSearchText !== "" && searchText !== "";
  const isLoading = loading === "true" || projectsLoading;

  const searchResult = React.useMemo(() => {
    return searchText ? searchTaskArray : [];
  }, [searchText, searchTaskArray]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleClickClose = () => {
    if (handleSearchClose) handleSearchClose();
    setSearchText("");
  };

  return (
    <TaskSearchBarContext.Provider
      value={{
        activitiesSummaryType,
        handleTaskSelect,
        isMerge,
        panelType,
        searchText,
        taskPage,
      }}
    >
      <div
        className={cn("TaskSearchBar", {
          "TaskSearchBar--Searching": isSearching,
        })}
      >
        <Popover
          content={
            <div
              className={cn("TaskSearchBar__Body", {
                "TaskSearchBar__Body--Loading": isLoading,
              })}
            >
              {(() => {
                if (isLoading) {
                  return <LoadingIcon />;
                } else if (!isLoading && !isMerge) {
                  return (
                    <>
                      {/* <SearchTaskCardList
                        filter="today"
                        searchTaskArray={searchResult}
                      /> */}
                      <SearchTaskCardList
                        filter="all"
                        searchTaskArray={searchResult}
                      />
                    </>
                  );
                } else if (!isLoading && isMerge) {
                  return (
                    <SearchTaskCardList
                      filter="all"
                      searchTaskArray={searchResult}
                    />
                  );
                }
              })()}
            </div>
          }
          overlayStyle={{ zIndex: 1000 }}
          getPopupContainer={(trigger: any) => trigger}
          trigger="click"
          placement="bottom"
          visible={isSearching}
        >
          <div className="TaskSearchBar__Header">
            <Input
              value={searchText}
              size={"default" as SizeType}
              placeholder="Search or create a new task"
              className="TaskSearchBar__Header__Input"
              onChange={handleOnChange}
            />
            {isSearching ? (
              <div className="TaskSearchBar__Header__Actions">
                <CreateNewTaskButton
                  panelType={panelType}
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                <CloseIcon
                  onClick={handleClickClose}
                  className="TaskSearchBar__Header__Actions__Close"
                />
              </div>
            ) : null}
          </div>
        </Popover>
      </div>
    </TaskSearchBarContext.Provider>
  );
};

export default TaskSearchBar;
