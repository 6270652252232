import axios from "../apis/django.instance";

export const getWorkShiftScheduleService: GetWorkShiftScheduleService = (
  data
) => {
  const { end_datetime, start_datetime } = data || {};
  const params = {
    end_datetime,
    start_datetime,
  };
  return axios.get("/api/work/workshiftschedule/", { params }); // must be in this format in order to mock implementation in unit test
};
