import React from "react";

import CustomButton from "../../CustomButton/CustomButton";
import { LeaveTheOfficeIcon } from "../../CustomIcons/CustomIcons.component";

const LeaveTheOfficeBtn: React.FC<LeaveTheOfficeBtnProps> = (props) => {
  const { confirmLeavingOffice } = props;

  return (
    <CustomButton className="LeaveTheOfficeBtn" {...props}>
      <span className="LeaveTheOfficeBtn__Text">
        {confirmLeavingOffice ? "Leave anyway" : "Leave the office"}
      </span>
      <LeaveTheOfficeIcon />
    </CustomButton>
  );
};

export default LeaveTheOfficeBtn;
