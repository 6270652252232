export const workDayArray: WorkDayArray = [
  {
    id: "DRYVO2YIXA",
    user: "9LBX5JWFS1",
    work_date: "2021-08-10",
    checkout_notes:
      '{"blocks":[{"key":"v3md","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "B4W1M0AQTL",
    user: "9LBX5JWFS1",
    work_date: "2021-08-09",
    checkout_notes:
      '{"blocks":[{"key":"1l91l","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "DVNBCTI68K",
    user: "9LBX5JWFS1",
    work_date: "2021-08-07",
    checkout_notes:
      '{"blocks":[{"key":"51oso","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "56672QCABB",
    user: "9LBX5JWFS1",
    work_date: "2021-08-06",
    checkout_notes: null,
  },
  {
    id: "UV68OC6GWR",
    user: "9LBX5JWFS1",
    work_date: "2021-08-05",
    checkout_notes: null,
  },
  {
    id: "3U22NGY9V7",
    user: "9LBX5JWFS1",
    work_date: "2021-08-04",
    checkout_notes: "Sample checkout notes",
  },
  {
    id: "D5ONJC5J5A",
    user: "9LBX5JWFS1",
    work_date: "2021-08-03",
    checkout_notes:
      '{"blocks":[{"key":"c59n6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "IZW59FNYUW",
    user: "9LBX5JWFS1",
    work_date: "2021-08-02",
    checkout_notes:
      '{"blocks":[{"key":"83mkn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "5RP885KBI6",
    user: "9LBX5JWFS1",
    work_date: "2021-07-30",
    checkout_notes:
      '{"blocks":[{"key":"ak01f","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  {
    id: "9AKEPBQHHG",
    user: "9LBX5JWFS1",
    work_date: "2021-07-29",
    checkout_notes:
      '{"blocks":[{"key":"63d93","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
];

export const workDayObject = workDayArray[0];
