import { RootState } from "../store/configureStore";

export interface ProjectsState extends BaseReducerState {
  data: ProjectArray;
}
export const selectProjectsState = (rootState: RootState) => rootState.projects;
export const projectsReducerDefaultState: ProjectsState = {
  data: [],
  error: null,
  loading: false,
};

export interface ProjectsRequestAction {
  type: "REQUEST_FETCH_PROJECTS" | "REQUEST_CREATE_PROJECT";
}
export interface FetchProjectsSuccessAction {
  type: "REQUEST_FETCH_PROJECTS_SUCCESS";
  projects: ProjectArray;
}
export interface ProjectsRequestFailedAction {
  type:
    | "REQUEST_FETCH_PROJECTS_FAILED"
    | "REQUEST_CREATE_PROJECT_FAILED"
    | "REQUEST_UPDATE_PROJECT_FAILED";
  error: string;
}
export interface ProjectsRequestSuccessAction {
  type:
    | "REQUEST_CREATE_PROJECT_SUCCESS"
    | "REQUEST_UPDATE_PROJECT"
    | "REQUEST_UPDATE_PROJECT_SUCCESS";
  project: ProjectObject;
}
export type ProjectsActions =
  | ProjectsRequestAction
  | FetchProjectsSuccessAction
  | ProjectsRequestFailedAction
  | ProjectsRequestSuccessAction;

const projectsReducer = (
  state = projectsReducerDefaultState,
  action: ProjectsActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_PROJECTS":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "REQUEST_CREATE_PROJECT":
      return {
        ...state,
        error: null,
        creating: true,
      };
    case "REQUEST_UPDATE_PROJECT":
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.project.id) {
            return {
              ...item,
              loading: true,
            };
          }
          return item;
        }),
        loading: false,
      };
    case "REQUEST_FETCH_PROJECTS_SUCCESS":
      return {
        data: action.projects,
        error: null,
        loading: false,
      };
    case "REQUEST_CREATE_PROJECT_SUCCESS":
      return {
        data: [...state.data, action.project].sort((a, b) => {
          return a.title.localeCompare(b.title);
        }),
        error: null,
        loading: false,
      };
    case "REQUEST_UPDATE_PROJECT_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.project.id) {
            return { ...action.project, loading: false };
          }
          return item;
        }),
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_PROJECTS_FAILED":
    case "REQUEST_CREATE_PROJECT_FAILED":
    case "REQUEST_UPDATE_PROJECT_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { projectsReducer as default };
