import React from "react";
import cn from "classnames";

import {
  LabelIcon,
  SettingIcon,
} from "../../CustomIcons/CustomIcons.component";
import moment from "moment";
import { getTimezoneInfo } from "../../../utils/dates.util";

const renderStatus = (status: WorkStatusTypes) => {
  switch (status) {
    case "working":
      return "Working";
    case "taking_break":
      return "On a break";
    case "out_of_office":
      return "Schedule to work later";
  }
};

/**
 * TeamPopver is the component to display the teammate when user
 * hover on the teammate avatar.
 *
 * There are 2 states for this component:
 * 1. if the hovered user is current login user, then there is a "setting" button
 * on top-right of this component, user can update his profile from it;
 *
 * 2. otherwise there is a "pin" button on the top-right of this component, the login
 * user can decide whether to pin or unpin a teammate from the list. The pinned users
 * will be displayed in advanced of unpinned users and separated by a divider.
 */
const TeamPopover: React.FC<TeamPopoverProps> = ({
  teammate,
  isCurrentUser = false,
  onSetting,
  onPin,
}) => {
  const handleSetting = () => {
    if (onSetting) {
      onSetting();
    }
  };

  const handlePin = () => {
    if (onPin) {
      onPin();
    }
  };

  const timezone = getTimezoneInfo(teammate.timezone, new Date());
  return (
    <div className="TeamPopover">
      <div className="TeamPopover__Header">
        <div className="TeamPopover__Header__Info">
          <div className="TeamPopover__Header__Name">
            {teammate.display_name}
          </div>
          <div className="TeamPopover__Status">
            <div
              className={`TeamPopover__Dot TeamPopover__Dot--${teammate.status}`}
            />
            <div>{renderStatus(teammate.status)}</div>
          </div>
        </div>

        {isCurrentUser ? (
          <SettingIcon
            className="TeamPopover__Header__Icon"
            onClick={handleSetting}
          />
        ) : (
          <LabelIcon
            className={cn("TeamPopover__Header__Icon", {
              "TeamPopover__Header__Icon--active": teammate.pinned_yn,
            })}
            onClick={handlePin}
          />
        )}
      </div>
      <div className="TeamPopover__Title">Schedule</div>
      <div className="TeamPopover__Description">
        <div>Today</div>
        <div>
          {teammate.today_endtime
            ? moment(teammate.today_endtime).format("HH:mm")
            : "-"}
        </div>
      </div>
      <div className="TeamPopover__Description">
        <div>Next Workday</div>
        <div>
          {teammate.next_workday
            ? moment(teammate.next_workday).format("MM/DD HH:mm")
            : "-"}
        </div>
      </div>

      <div className="TeamPopover__Title">Info</div>
      <div className="TeamPopover__Description">
        <div>Full name</div>
        <div>{teammate.full_name}</div>
      </div>
      <div className="TeamPopover__Description">
        <div>Email</div>
        <div>{teammate.email}</div>
      </div>
      <div className="TeamPopover__Description">
        <div>Role</div>
        <div>{teammate.role}</div>
      </div>
      <div className="TeamPopover__Description">
        <div>Location</div>
        <div>{`${teammate.country}${teammate.country && ","} ${teammate.city}${
          teammate.city && ","
        } ${timezone.time}`}</div>
      </div>
    </div>
  );
};

export default TeamPopover;
