import { RootState } from "../store/configureStore";

export interface ListState extends BaseReducerState {
  data: ListArray;
}
export const selectListsState = (rootState: RootState) => rootState.lists;
export const listsReducerDefaultState: ListState = {
  data: [],
  loading: false,
  error: "",
};

const listsReducer = (
  state = listsReducerDefaultState,
  action: { type: string; lists: ListArray; list: ListObject; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_LISTS":
    case "REQUEST_CREATE_LIST":
    case "REQUEST_UPDATE_LIST":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_LISTS_SUCCESS":
    case "REQUEST_CREATE_LIST_SUCCESS":
      return {
        data: action.lists,
        error: "",
        loading: false,
      };
    case "REQUEST_UPDATE_LIST_SUCCESS":
      return {
        data: state.data.map((item) => {
          if (item.id === action.list.id) {
            return action.list;
          }
          return item;
        }),
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_LISTS_FAILED":
    case "REQUEST_CREATE_LIST_FAILED":
    case "REQUEST_UPDATE_LIST_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "TOGGLE_LIST_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.list.id) {
            return {
              ...item,
              show_list_in_sidebar_yn: !item.show_list_in_sidebar_yn,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export { listsReducer as default };
