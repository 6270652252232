import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";

import CheckoutTaskCollapsePanel from "../../../Checkout/CheckoutTaskCollapsePanel/CheckoutTaskCollapsePanel";
import SummaryOfTheDay from "../../../Checkout/SummaryOfTheDay/SummaryOfTheDay";
import { getUserWorkTimeTableData } from "../HistoryTabPane/HistoryTabPane";
import TeamMemberSelector from "../../../Team/TeamMemberSelector/TeamMemberSelector";
import TeamHistoryDateList from "../../../Team/TeamHistoryDateList/TeamHistoryDateList";
import { TeamPanelContext } from "../../../Team/TeamPanel/TeamPanel";
import WorkTimeRecordTable from "../../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";

import { workDayDateFormat } from "../../../../constants/constants";
import { selectThirdPartyTrackerState } from "../../../../reducers/third-party-tracking.reducer";
import { selectCurrentWorkDayState } from "../../../../reducers/work-day.reducer";

const today = moment();
const TodayTabPane = () => {
  const { selectedObservableUser, observableUsers } = React.useContext(
    TeamPanelContext
  );

  const { data: thirdPartyTracker } = useSelector(selectThirdPartyTrackerState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [isLoading, setIsLoading] = React.useState(false);
  const [shownObservableUsers, setShownObservableUsers] = React.useState<
    ObservableUserArray
  >([]);
  const [
    shownObservableUserWithTableDataArray,
    setShownObservableUserWithTableDataArray,
  ] = React.useState<ShownObservableUserWithTableDataArray>();

  React.useEffect(() => {
    if (selectedObservableUser) {
      setShownObservableUsers([selectedObservableUser]);
    } else {
      setShownObservableUsers(
        observableUsers.filter(({ shown_yn }) => shown_yn)
      );
    }
  }, [selectedObservableUser, observableUsers]);

  React.useEffect(() => {
    const getShownObservableUsersTableData = async () => {
      if (shownObservableUsers.length > 0 && !isEmpty(thirdPartyTracker)) {
        setIsLoading(true);
        try {
          const shownObservableUsersWithTableData: ShownObservableUserWithTableDataObject[] = await getUserWorkTimeTableData(
            moment(),
            shownObservableUsers,
            moment(),
            thirdPartyTracker
          );
          setShownObservableUserWithTableDataArray(
            shownObservableUsersWithTableData
          );
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      }
    };
    getShownObservableUsersTableData();
  }, [shownObservableUsers, thirdPartyTracker]);

  return (
    <div className="HistoryTabPane">
      <TeamMemberSelector />
      <div className="HistoryTabPane__Body">
        <div className="HistoryTabPane__Body__DateRangeDisplay">
          {today.format("dddd, MMMM D")}
        </div>

        {isLoading && <WorkTimeRecordTable isLoading={true} type="member" />}

        {!isLoading && shownObservableUserWithTableDataArray && (
          <TeamHistoryDateList
            shownObservableUserWithTableDataArray={
              shownObservableUserWithTableDataArray
            }
          />
        )}

        <SummaryOfTheDay
          date={[currentWorkDayDate]}
          type="team"
          users={shownObservableUsers.map(({ observed_user }) => observed_user)}
        />

        <CheckoutTaskCollapsePanel
          activitiesSummaryType="team"
          dates={[
            today.format(workDayDateFormat),
            today.format(workDayDateFormat),
          ]}
          users={shownObservableUsers.map(({ observed_user }) => observed_user)}
        />
      </div>
    </div>
  );
};

export default TodayTabPane;
