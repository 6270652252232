import axios from "../apis/django.instance";

export const getUserDetailsService = () => {
  return axios.get("/api/accounts/me/"); // must be in this format in order to mock implementation in unit test
};

export const getUserDetailsByIDService: GetUserDetailsByIdService = (
  userId
) => {
  return axios.get(`/api/accounts/get_user_details/${userId}/`); // must be in this format in order to mock implementation in unit test
};

export const updateUserDetailsService = (payload: AccountUser) => {
  const {
    avatar,
    full_name,
    display_name,
    email,
    timezone,
    country,
    city,
    what_i_do,
  } = payload;
  const formData = new FormData();
  if (avatar || avatar === "") {
    formData.append("avatar", avatar);
  }
  if (full_name) {
    formData.append("full_name", full_name);
  }
  if (display_name) {
    formData.append("display_name", display_name);
  }
  if (email) {
    formData.append("email", email);
  }
  if (timezone) {
    formData.append("timezone", timezone);
  }
  if (country) {
    formData.append("country", country);
  }
  if (city) {
    formData.append("city", city);
  }
  if (what_i_do) {
    formData.append("what_i_do", what_i_do);
  }
  return axios.patch("/api/accounts/me/", formData);
};
