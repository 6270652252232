import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import { store } from "../store/configureStore";

// public views
import LoginView from "../views/Login/Login.view";
import UnauthorizedView from "../views/Unauthorized/Unauthorized.view";

// private views
import StartWorkView from "../views/StartWork/StartWork.view";
import InTheOfficeView from "../views/InTheOffice/InTheOffice.view";
import NotFoundView from "../views/NotFound/NotFound.view";
import WorkspaceView from "../views/WorkspaceView/WorkspaceView.view";

import {
  requestFetchLoginSuccess,
  requestFetchLogoutSuccess,
} from "../actions/auth.action";
import { startPopulateStatusTypes } from "../actions/status-types.action";
import { startPopulateUserDetails } from "../actions/user-details.action";
import { webSocketEvents } from "../constants/constants";

import ws from "../sockets/websockets";

export const history = createBrowserHistory();
export const individualOfficePrivateUrls = [
  "/office",
  "/team",
  "/unread",
  "/checkout",
  "/today",
  "/nextShift",
  "/starred",
  "/calendar",
  "/history",
];
export const sharedOfficePrivateUrls = [
  "/p/:projectId/:projectName",
  "/l/:listId/:listName",
  "/l/:listId/:listName/:listSubPage",
  "/s/:sectionId/:sectionName",
  "/t/:taskId/:taskName/:taskSubPage",
  "/l/:listId/:listName/:taskId/:taskName/:taskSubPage",
];
export const privateOfficeUrls = [
  ...individualOfficePrivateUrls,
  ...sharedOfficePrivateUrls,
];

export const individualWorkspacePrivateUrls = [
  "/projects",
  "/members",
  "/team-page",
  "/workspace",
];
export const privateWorkspaceUrls = [...individualWorkspacePrivateUrls];
const AppRouter = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const synchronizeAuth = ({ email, event }: AuthEventData) => {
      const lsEmail = localStorage.getItem("email");
      if (lsEmail === email) {
        switch (event) {
          case webSocketEvents.login:
            const token = localStorage.getItem("token");
            if (token && email) {
              dispatch(requestFetchLoginSuccess(token, email));
              store.dispatch(startPopulateStatusTypes()).then(() => {
                store.dispatch(startPopulateUserDetails());
              });
            }
            break;
          case webSocketEvents.logout:
            dispatch(requestFetchLogoutSuccess());
            break;
          default:
        }
      }
    };

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        synchronizeAuth(messageEventData);
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch]);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/" exact={true}>
          <LoginView />
        </PublicRoute>
        <PrivateRoute path="/unauthorized" exact={true}>
          <UnauthorizedView />
        </PrivateRoute>
        <PrivateRoute path="/start-work" exact={true}>
          <StartWorkView />
        </PrivateRoute>
        <PrivateRoute path={privateWorkspaceUrls} exact={true}>
          <WorkspaceView />
        </PrivateRoute>
        <PrivateRoute path={privateOfficeUrls} exact={true}>
          <InTheOfficeView />
        </PrivateRoute>
        <Route component={NotFoundView}></Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
