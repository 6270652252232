import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import { startLogin } from "../../../actions/auth.action";
import { selectAuthState } from "../../../reducers/auth.reducer";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const LoginForm = () => {
  const [loginForm] = Form.useForm();

  const { loading: authLoading, error: authError } = useSelector(
    selectAuthState
  );

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  const handleChangeForm = (changedValues: any, allValues: any) => {
    setUsername(allValues.username);
    setPassword(allValues.password);
  };

  const onFinish = async (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    const data = {
      email: username,
      password: password,
    };
    dispatch(startLogin(data));
  };

  const onFinishFailed = (errorInfo: {
    errorFields: any[];
    outOfDate: boolean;
    values: {
      username: string;
      password: string;
      remember: boolean;
    };
  }) => {
    console.error("Failed:", errorInfo);
  };

  React.useEffect(() => {
    if (!!authError) {
      loginForm.setFields([
        {
          name: "password",
          errors: ["Username and password does not match!"],
        },
      ]);
    }
  }, [loginForm, authError]);

  return (
    <Form
      {...layout}
      form={loginForm}
      name="basic"
      initialValues={{ username, password, remember: true }}
      onValuesChange={handleChangeForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="LoginForm"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input className="LoginForm__username-input" disabled={authLoading} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          className="LoginForm__password-input"
          disabled={authLoading}
        />
      </Form.Item>

      <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox disabled={authLoading}>Remember me</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          className="LoginForm__submit-btn"
          disabled={authLoading}
        >
          {authLoading ? <LoadingIcon /> : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export { LoginForm as default };
