import { Dispatch } from "redux";

import { store } from "../store/configureStore";

import { setStatus } from "../actions/status.action";
import {
  createWorkDayRecordService,
  getLatestWorkDayService,
  getWorkDayListService,
  CreateWorkDayRecordServiceData,
  PartialUpdateWorkDayServiceData,
  partialUpdateWorkDayRecordService,
} from "../services/work-day.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchWorkDays = () => ({
  type: "REQUEST_FETCH_WORK_DAYS",
});
export const requestFetchWorkDaysSuccess = (workDays: WorkDayArray) => ({
  type: "REQUEST_FETCH_WORK_DAYS_SUCCESS",
  workDays,
});
export const requestFetchWorkDaysFailed = (error: string) => ({
  type: "REQUEST_FETCH_WORK_DAYS_FAILED",
  error,
});
export const startPopulateWorkDays = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchWorkDays());
      const response = await getWorkDayListService({
        user: store.getState().userDetails.data.id,
      });
      if (response.status === 200) {
        dispatch(requestFetchWorkDaysSuccess(response.data));
      } else {
        const error = "getWorkDayListService status is not 200";
        dispatch(requestFetchWorkDaysFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchWorkDaysFailed(error));
      dispatchError({
        e,
        title: "Populate work days error",
      });
    }
  };
};

export const requestFetchCurrentWorkDay = () => ({
  type: "REQUEST_FETCH_CURRENT_WORK_DAY",
});
export const requestFetchCurrentWorkDaySuccess = (
  currentWorkDay: WorkDayObject
) => ({
  type: "REQUEST_FETCH_CURRENT_WORK_DAY_SUCCESS",
  currentWorkDay,
});
export const requestFetchCurrentWorkDayFailed = (error: string) => ({
  type: "REQUEST_FETCH_CURRENT_WORK_DAY_FAILED",
  error,
});

export const startSetCurrentWorkDay = (data?: StatusTypeObject) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {
        userDetails: {
          data: { id: userId },
        },
      } = store.getState();
      dispatch(requestFetchCurrentWorkDay());
      const response = await getLatestWorkDayService({ user: userId });
      if (response.status === 200) {
        dispatch(requestFetchCurrentWorkDaySuccess(response.data));
        if (data) {
          dispatch(setStatus("REQUEST_CREATE_STATUS_SUCCESS", data));
        }
      } else {
        const error = "getLatestWorkDayService status is not 200";
        dispatch(requestFetchCurrentWorkDayFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchCurrentWorkDayFailed(error));
      dispatchError({
        e,
        title: "Get latest work day error",
      });
    }
  };
};

export const setCurrentWorkDayDefault = () => {
  return {
    type: "SET_CURRENT_WORK_DAY_DEFAULT",
  };
};

export const setCheckoutWorkDay = (checkoutWorkDay: WorkDayObject) => {
  return {
    type: "SET_CHECKOUT_WORK_DAY",
    checkoutWorkDay,
  };
};

export const requestCreateWorkDay = () => ({
  type: "REQUEST_CREATE_WORK_DAY",
});
export const requestCreateWorkDaySuccess = (workDays: WorkDayArray) => ({
  type: "REQUEST_CREATE_WORK_DAY_SUCCESS",
  workDays,
});
export const requestCreateWorkDayFailed = (error: string) => ({
  type: "REQUEST_CREATE_WORK_DAY_FAILED",
  error,
});
export const startCreateWorkDayRecord = (
  data: CreateWorkDayRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateWorkDay());
      const response = await createWorkDayRecordService(data);
      if (response.status === 200) {
        const createdWorkDay: WorkDayObject = response.data;
        dispatch(setCheckoutWorkDay(createdWorkDay));
        dispatch(
          requestCreateWorkDaySuccess([
            ...store.getState().workDays,
            createdWorkDay,
          ])
        );
      } else {
        const error = "createWorkDayRecordService status is not 200";
        dispatch(requestCreateWorkDayFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateWorkDayFailed(error));
      dispatchError({
        e,
        title: "Create work day record error",
      });
    }
  };
};

export const requestPartialUpdateWorkDay = (
  workDayId: WorkDayObject["id"],
  updatedKeys: (keyof WorkDayObject)[]
) => ({
  type: "REQUEST_PARTIAL_UPDATE_WORK_DAY",
  workDayId,
  updatedKeys,
});
export const requestPartialUpdateWorkDaySuccess = (workDay: WorkDayObject) => ({
  type: "REQUEST_PARTIAL_UPDATE_WORK_DAY_SUCCESS",
  workDay,
});
export const requestPartialUpdateWorkDayFailed = (
  workDayId: WorkDayObject["id"],
  error: string
) => ({
  type: "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED",
  workDayId,
  error,
});
export const startPartialUpdateWorkDayRecord = (
  data: PartialUpdateWorkDayServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(
        requestPartialUpdateWorkDay(
          data.id,
          Object.keys(data) as (keyof WorkDayObject)[]
        )
      );
      const response = await partialUpdateWorkDayRecordService(data);
      if (response.status === 200) {
        const updatedWorkDay: WorkDayObject = response.data;
        dispatch(requestPartialUpdateWorkDaySuccess(updatedWorkDay));
        return {
          success: true,
        };
      } else {
        const error = "updateWorkDayRecordService status is not 200";
        dispatch(requestPartialUpdateWorkDayFailed(data.id, error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestPartialUpdateWorkDayFailed(data.id, error));
      dispatchError({
        e,
        title: "Update work day record error",
      });
    }
  };
};
