import React from "react";

import useConsolidateTask from "./useConsolidateTask.hook";

import {
  getArchivedTasksInSectionService,
  getTasksListService,
} from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export interface Params {
  sectionId: SectionObject["id"];
  archived?: boolean;
}
export default function useAsyncTasksInSection({
  sectionId,
  archived,
}: Params) {
  const [tasksInSection, setTasksInSection] = React.useState<TaskArray>();

  const [
    consolidatedTasksInSection,
    consolidatedTasksInSectionLoading,
  ] = useConsolidateTask(tasksInSection);

  const isLoading =
    tasksInSection === undefined || consolidatedTasksInSectionLoading;

  const fetchTasksInSection = React.useCallback(async () => {
    try {
      const response = archived
        ? await getArchivedTasksInSectionService(sectionId)
        : await getTasksListService({
            section: sectionId,
          });
      if (response.status === 200 && archived) {
        setTasksInSection(response.data as TaskArray);
      } else if (response.status === 200 && !archived) {
        setTasksInSection(response.data.results as TaskArray);
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Fetch tasks in section error",
      });
    }
  }, [archived, sectionId]);

  React.useEffect(() => {
    fetchTasksInSection();
  }, [fetchTasksInSection]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventSectionId = messageEventData.meta.data.section.id;
          if (sectionId === eventSectionId) {
            fetchTasksInSection();
          }
        } else if (eventType === "new_join_task_to_user") {
          const eventTaskId = messageEventData.meta.task_id;
          const eventTask = await asyncFetchTaskObject(eventTaskId);

          // synchronize members of task record
          if (!!eventTask) {
            setTasksInSection((c) =>
              c
                ? c.map((task) => {
                    if (task.id === eventTaskId) {
                      return eventTask;
                    }
                    return task;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksInSection, sectionId]);

  return [consolidatedTasksInSection, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
