import { Dispatch } from "redux";
import moment from "moment-timezone";

import {
  getUserDetailsService,
  updateUserDetailsService,
} from "../services/user-details.service";
import { dispatchError } from "../utils/error.util";
import { startGetCurrentUserStatus } from "./status.action";
import { startSetCurrentWorkDay } from "./work-day.action";

export const requestFetchUserDetails = () => ({
  type: "REQUEST_FETCH_USER_DETAILS",
});
export const requestFetchUserDetailsSuccess = (userDetails: AccountUser) => ({
  type: "REQUEST_FETCH_USER_DETAILS_SUCCESS",
  userDetails,
});
export const requestFetchUserDetailsFailed = (error: string) => ({
  type: "REQUEST_FETCH_USER_DETAILS__FAILED",
  error,
});
export const startPopulateUserDetails = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchUserDetails());
      const response = await getUserDetailsService();
      if (response.status === 200) {
        const userDetails = response.data as AccountUser;
        dispatch(requestFetchUserDetailsSuccess(userDetails));

        // set moment timezone based on user's timezone
        if (userDetails.timezone) {
          moment.tz.setDefault(userDetails.timezone);
          dispatch(startGetCurrentUserStatus());
          dispatch(startSetCurrentWorkDay());
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchUserDetailsFailed(error));
      dispatchError({
        e,
        title: "Populate user details error",
      });
    }
  };
};

export const requestUpdateUserDetails = () => ({
  type: "REQUEST_UPDATE_USER_DETAILS",
});
export const requestUpdateUserDetailsSuccess = (userDetails: AccountUser) => ({
  type: "REQUEST_UPDATE_USER_DETAILS_SUCCESS",
  userDetails,
});
export const requestUpdateUserDetailsFailed = (error: string) => ({
  type: "REQUEST_UPDATE_USER_DETAILS__FAILED",
  error,
});
export const startUpdateUserDetails = (payload: AccountUser) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestUpdateUserDetails());
      const response = await updateUserDetailsService(payload);
      if (response.status === 200) {
        const userDetails = response.data;

        // set moment timezone based on user's timezone
        if (userDetails.timezone) {
          moment.tz.setDefault(userDetails.timezone);
        }

        dispatch(requestUpdateUserDetailsSuccess(userDetails));
      } else {
        const error = "updateUserDetailsService status is not 200";
        dispatch(requestUpdateUserDetailsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestUpdateUserDetailsFailed(error));
      dispatchError({
        e,
        title: "Update user details error",
      });
    }
  };
};

export const setUserDetailsDefault = () => {
  return {
    type: "SET_USER_DETAILS_DEFAULT",
  };
};
