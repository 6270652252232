import React from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";

import UserWorkDaySummaryItemGroupedByDate from "./UserWorkDaySummaryItemGroupedByDate/UserWorkDaySummaryItemGroupedByDate";

import { groupObjectArrayByObjectDateKey } from "../../../utils/objects-array.util";

interface UserWorkDaySummaryListGroupedByDateProps {
  type: ActivitiesSummaryType;
  users: UserArray;
  workDaysCheckoutSummaryGroupedByDate: ReturnType<
    typeof groupObjectArrayByObjectDateKey
  >;
}
const UserWorkDaySummaryListGroupedByDate: React.FC<UserWorkDaySummaryListGroupedByDateProps> = ({
  type,
  users,
  workDaysCheckoutSummaryGroupedByDate,
}) => {
  return (
    <div className="UserWorkDaySummaryListGroupedByDate">
      {!isEmpty(workDaysCheckoutSummaryGroupedByDate) ? (
        Object.keys(workDaysCheckoutSummaryGroupedByDate).map((key) => {
          const dateWorkDayCheckoutSummaryArray: WorkDayCheckoutSummaryArray =
            workDaysCheckoutSummaryGroupedByDate[key];
          if (dateWorkDayCheckoutSummaryArray.length === 0) {
            return <Empty key={key} />;
          }
          return (
            <UserWorkDaySummaryItemGroupedByDate
              key={key}
              dateKey={key}
              dateWorkDayCheckoutSummaryArray={dateWorkDayCheckoutSummaryArray}
              type={type}
              users={users}
            />
          );
        })
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default UserWorkDaySummaryListGroupedByDate;
