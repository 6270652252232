import React from "react";
import { Tooltip } from "antd";

import CustomButton from "../../CustomButton/CustomButton";
import { RefreshIcon } from "../../CustomIcons/CustomIcons.component";

export interface RefreshBtnProps {
  disabled: boolean;
  onClick: Function;
  tooltipText?: string;
}

const RefreshBtn: React.FC<RefreshBtnProps> = ({
  disabled,
  onClick,
  tooltipText,
}) => {
  return (
    <div className="RefreshBtn">
      <Tooltip title={tooltipText}>
        <CustomButton
          icon={<RefreshIcon />}
          onClick={onClick}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
};

export default RefreshBtn;
