import React from "react";
import { Spin } from "antd";
import Icon, { LoadingOutlined } from "@ant-design/icons";

/**
 * Default Display
 * Displays reusable custom icons using Ant Design's Icon component
 * https://ant.design/components/icon/
 */
const NormalPanelSvg = () => (
  <svg
    id="Group_336"
    data-name="Group 336"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_184"
          data-name="Rectangle 184"
          width="24"
          height="24"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <rect
      id="Rectangle_183"
      data-name="Rectangle 183"
      width="24"
      height="24"
      fill="currentColor"
      opacity="0"
    />
    <g id="Group_335" data-name="Group 335">
      <g id="Group_334" data-name="Group 334" clipPath="url(#clipPath)">
        <path
          id="Path_336"
          data-name="Path 336"
          d="M10,3H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1"
          fill="rgba(0,0,0,0.5)"
        />
        <path
          id="Path_337"
          data-name="Path 337"
          d="M20,3H14a1,1,0,0,0-1,1V20a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1"
          fill="rgba(0,0,0,0.5)"
        />
      </g>
    </g>
  </svg>
);

const CompactPanelSvg = () => (
  <svg
    id="Group_341"
    data-name="Group 341"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_186"
          data-name="Rectangle 186"
          width="24"
          height="24"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <rect
      id="Rectangle_185"
      data-name="Rectangle 185"
      width="24"
      height="24"
      fill="currentColor"
      opacity="0"
    />
    <g id="Group_338" data-name="Group 338">
      <g id="Group_337" data-name="Group 337" clipPath="url(#clipPath)">
        <path
          id="Path_338"
          data-name="Path 338"
          d="M7,3H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V4A1,1,0,0,0,7,3"
          fill="rgba(0,0,0,0.5)"
        />
        <path
          id="Path_339"
          data-name="Path 339"
          d="M20,3H11a1,1,0,0,0-1,1V20a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1"
          transform="translate(0)"
          fill="rgba(0,0,0,0.5)"
        />
      </g>
    </g>
  </svg>
);

const OfficeSvg = () => (
  <svg
    id="dashboard-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_200" data-name="Path 200" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_201"
      data-name="Path 201"
      d="M3,11.333H9.667V3H3ZM3,18H9.667V13H3Zm8.333,0H18V9.667H11.333Zm0-15V8H18V3Z"
      transform="translate(-0.5 -0.5)"
    />
  </svg>
);

const ReportsSvg = () => (
  <svg
    id="assignment-24px_1_"
    data-name="assignment-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_24" data-name="Path 24" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_25"
      data-name="Path 25"
      d="M16.333,2.667H12.85a2.49,2.49,0,0,0-4.7,0H4.667A1.672,1.672,0,0,0,3,4.333V16a1.672,1.672,0,0,0,1.667,1.667H16.333A1.672,1.672,0,0,0,18,16V4.333A1.672,1.672,0,0,0,16.333,2.667Zm-5.833,0a.833.833,0,1,1-.833.833A.836.836,0,0,1,10.5,2.667Zm1.667,11.667H6.333V12.667h5.833ZM14.667,11H6.333V9.333h8.333Zm0-3.333H6.333V6h8.333Z"
      transform="translate(-0.5 -0.167)"
    />
  </svg>
);

const CheckoutSvg = () => (
  <svg
    id="done-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path id="Path_587" data-name="Path 587" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_588"
      data-name="Path 588"
      d="M8.067,14.433l-3.5-3.5L3.4,12.1l4.667,4.667,10-10L16.9,5.6Z"
      transform="translate(-0.567 -0.933)"
      fill="currentColor"
    />
  </svg>
);

const ActivitiesSvg = () => (
  <svg
    id="query_builder-24px_2_"
    data-name="query_builder-24px (2)"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_19" data-name="Path 19" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_20"
      data-name="Path 20"
      d="M10.325,2a8.333,8.333,0,1,0,8.342,8.333A8.329,8.329,0,0,0,10.325,2Zm.008,15A6.667,6.667,0,1,1,17,10.333,6.665,6.665,0,0,1,10.333,17Z"
      transform="translate(-0.333 -0.333)"
    />
    <path
      id="Path_21"
      data-name="Path 21"
      d="M12.25,7H11v5l4.375,2.625L16,13.6l-3.75-2.225Z"
      transform="translate(-1.833 -1.167)"
    />
  </svg>
);

const IndividualSvg = () => (
  <svg
    id="person-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_54" data-name="Path 54" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_55"
      data-name="Path 55"
      d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
    />
  </svg>
);

const TeamSvg = () => (
  <svg
    id="group-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_409" data-name="Path 409" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_410"
      data-name="Path 410"
      d="M13.5,10A2.5,2.5,0,1,0,11,7.5,2.49,2.49,0,0,0,13.5,10ZM6.833,10a2.5,2.5,0,1,0-2.5-2.5A2.49,2.49,0,0,0,6.833,10Zm0,1.667C4.892,11.667,1,12.642,1,14.583v2.083H12.667V14.583C12.667,12.642,8.775,11.667,6.833,11.667Zm6.667,0c-.242,0-.517.017-.808.042a3.517,3.517,0,0,1,1.642,2.875v2.083h5V14.583C19.333,12.642,15.442,11.667,13.5,11.667Z"
      transform="translate(-0.167 -0.833)"
    />
  </svg>
);

const SearchSvg = () => (
  <svg
    id="search-24px_1_"
    data-name="search-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_239" data-name="Path 239" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_240"
      data-name="Path 240"
      d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z"
    />
  </svg>
);

const FilterSvg = () => (
  <svg
    id="filter_alt-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_243" data-name="Path 243" d="M0,0H24m0,24H0" fill="none" />
    <path
      id="Path_244"
      data-name="Path 244"
      d="M4.25,5.61C6.27,8.2,10,13,10,13v6a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V13s3.72-4.8,5.74-7.39A1,1,0,0,0,18.95,4H5.04A1,1,0,0,0,4.25,5.61Z"
    />
    <path id="Path_245" data-name="Path 245" d="M0,0H24V24H0Z" fill="none" />
  </svg>
);

const FilterActiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_243"
          data-name="Path 243"
          fill="none"
          d="M0,0H24m0,24H0"
        />
        <path
          id="Path_245"
          data-name="Path 245"
          fill="none"
          d="M0,0H24V24H0Z"
        />
        <path
          fill="#0079f2"
          d="M16,4H5A1,1,0,0,0,4,5a1,1,0,0,0,.21.61L10,13v6a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V13l4.22-5.44A4,4,0,0,1,16,4Z"
        />
        <circle fill="#0079f2" cx="20" cy="4" r="3" />
      </g>
    </g>
  </svg>
);

const TodaySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g id="ic" transform="translate(-251 -365)">
      <rect
        id="Rectangle_145"
        data-name="Rectangle 145"
        width="20"
        height="20"
        transform="translate(251 365)"
        opacity="0"
      />
      <path
        id="Path_59"
        data-name="Path 59"
        d="M261.333,368.667a6.667,6.667,0,1,1-6.667,6.667,6.674,6.674,0,0,1,6.667-6.667m0-1.667a8.333,8.333,0,1,0,8.333,8.333A8.333,8.333,0,0,0,261.333,367Zm0,5A3.333,3.333,0,1,1,258,375.333,3.337,3.337,0,0,1,261.333,372m0-1.667a5,5,0,1,0,5,5,5,5,0,0,0-5-5Zm0,3.333A1.667,1.667,0,1,0,263,375.333,1.666,1.666,0,0,0,261.333,373.667Z"
        transform="translate(-0.333 -0.333)"
      />
    </g>
  </svg>
);

const TasksSvg = () => (
  <svg
    id="work-24px_1_"
    data-name="work-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_318" data-name="Path 318" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_319"
      data-name="Path 319"
      d="M17,5.333H13.667V3.667A1.661,1.661,0,0,0,12,2H8.667A1.661,1.661,0,0,0,7,3.667V5.333H3.667A1.654,1.654,0,0,0,2.008,7L2,16.167a1.661,1.661,0,0,0,1.667,1.667H17a1.661,1.661,0,0,0,1.667-1.667V7A1.661,1.661,0,0,0,17,5.333Zm-5,0H8.667V3.667H12Z"
      transform="translate(-0.333 -0.333)"
    />
  </svg>
);

const CalendarSvg = () => (
  <svg
    id="today-24px_2_"
    data-name="today-24px (2)"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_334" data-name="Path 334" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_335"
      data-name="Path 335"
      d="M16.333,2.667H15.5V1H13.833V2.667H7.167V1H5.5V2.667H4.667A1.659,1.659,0,0,0,3.008,4.333L3,16a1.666,1.666,0,0,0,1.667,1.667H16.333A1.672,1.672,0,0,0,18,16V4.333A1.672,1.672,0,0,0,16.333,2.667Zm0,13.333H4.667V6.833H16.333Zm-10-7.5H10.5v4.167H6.333Z"
      transform="translate(-0.5 -0.167)"
    />
  </svg>
);

const ExclamationCircleFilledSvg = () => (
  <svg
    id="error-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_44" data-name="Path 44" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_45"
      data-name="Path 45"
      d="M10.333,2a8.333,8.333,0,1,0,8.333,8.333A8.336,8.336,0,0,0,10.333,2Zm.833,12.5H9.5V12.833h1.667Zm0-3.333H9.5v-5h1.667Z"
      transform="translate(-0.333 -0.333)"
    />
  </svg>
);

const NotificationBellSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.21875em"
    height="1em"
    viewBox="0 0 16 19.5"
    fill="currentColor"
  >
    <path
      id="notifications-24px"
      d="M12,22a2.006,2.006,0,0,0,2-2H10A2,2,0,0,0,12,22Zm6-6V11c0-3.07-1.64-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.63,5.36,6,7.92,6,11v5L4,18v1H20V18Z"
      transform="translate(-4 -2.5)"
    />
  </svg>
);

const BulletPointSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 8 8"
    fill="currentColor"
  >
    <circle id="Ellipse_65" data-name="Ellipse 65" cx="4" cy="4" r="4" />
  </svg>
);

const InTheOfficeSvg = () => (
  <svg
    id="laptop-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g id="Group_359" data-name="Group 359">
      <rect
        id="Rectangle_191"
        data-name="Rectangle 191"
        width="20"
        height="20"
        fill="none"
      />
    </g>
    <g id="Group_362" data-name="Group 362" transform="translate(0 3.333)">
      <g id="Group_361" data-name="Group 361">
        <g id="Group_360" data-name="Group 360">
          <path
            id="Path_359"
            data-name="Path 359"
            d="M16.667,15.667A1.672,1.672,0,0,0,18.333,14V5.667A1.672,1.672,0,0,0,16.667,4H3.333A1.672,1.672,0,0,0,1.667,5.667V14a1.672,1.672,0,0,0,1.667,1.667H0v1.667H20V15.667Zm-13.333-10H16.667V14H3.333Z"
            transform="translate(0 -4)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const LeaveTheOfficeSvg = () => (
  <svg
    id="ic-exit-2"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_548"
          data-name="Rectangle 548"
          width="20"
          height="20"
          fill="rgba(255,255,255,0.8)"
        />
      </clipPath>
    </defs>
    <rect
      id="Rectangle_547"
      data-name="Rectangle 547"
      width="20"
      height="20"
      fill="rgba(255,255,255,0.8)"
      opacity="0"
    />
    <g id="Group_487" data-name="Group 487">
      <g id="Group_486" data-name="Group 486" clipPath="url(#clipPath)">
        <path
          id="Path_405"
          data-name="Path 405"
          d="M14.5,6.333,13.333,7.5,15.5,9.667H7v1.667h8.5L13.333,13.5,14.5,14.667,18.667,10.5Zm-10.833,10h6.667V18H3.667A1.672,1.672,0,0,1,2,16.333V4.667A1.672,1.672,0,0,1,3.667,3h6.667V4.667H3.667Z"
          transform="translate(-0.333 -0.5)"
          fill="rgba(255,255,255,0.8)"
        />
      </g>
    </g>
  </svg>
);

const InfoCircleOutlinedSvg = () => (
  <svg
    id="info-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_278" data-name="Path 278" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_279"
      data-name="Path 279"
      d="M9.5,6.167h1.667V7.833H9.5ZM9.5,9.5h1.667v5H9.5ZM10.333,2a8.333,8.333,0,1,0,8.333,8.333A8.336,8.336,0,0,0,10.333,2Zm0,15A6.667,6.667,0,1,1,17,10.333,6.676,6.676,0,0,1,10.333,17Z"
      transform="translate(-0.333 -0.333)"
    />
  </svg>
);

const InfoCircleFilledSvg = () => (
  <svg
    id="info-24px_4_"
    data-name="info-24px (4)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <g id="Btn">
      <path id="Path_215" data-name="Path 215" d="M0,0H18V18H0Z" fill="none" />
      <path
        id="Path_216"
        data-name="Path 216"
        d="M9.5,2A7.5,7.5,0,1,0,17,9.5,7.5,7.5,0,0,0,9.5,2Zm.75,11.25H8.75V8.75h1.5Zm0-6H8.75V5.75h1.5Z"
        transform="translate(-0.5 -0.5)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const PriorityHighSvg = () => (
  <svg
    id="ic-priority"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g id="Btn">
      <rect
        id="placeholder"
        width="17"
        height="17"
        fill="rgba(0,0,0,0.05)"
        opacity="0"
      />
      <g id="ic" transform="translate(-9 -12)">
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="3"
          height="13"
          transform="translate(11 14)"
          fill="#ff2900"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="3"
          height="13"
          transform="translate(16 14)"
          fill="#ff2900"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="3"
          height="13"
          transform="translate(21 14)"
          fill="#ff2900"
        />
      </g>
    </g>
  </svg>
);

const PriorityMediumSvg = () => (
  <svg
    id="ic-priority"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g id="Btn">
      <rect
        id="placeholder"
        width="17"
        height="17"
        fill="rgba(0,0,0,0.05)"
        opacity="0"
      />
      <g id="ic" transform="translate(-9 -12)">
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="3"
          height="13"
          transform="translate(11 14)"
          fill="#FFBF00"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="3"
          height="13"
          transform="translate(16 14)"
          fill="#FFBF00"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="3"
          height="13"
          transform="translate(21 14)"
          fill="rgba(0,0,0,0.1)"
        />
      </g>
    </g>
  </svg>
);

const PriorityLowSvg = () => (
  <svg
    id="ic-priority"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g id="Btn">
      <rect
        id="placeholder"
        width="17"
        height="17"
        fill="rgba(0,0,0,0.05)"
        opacity="0"
      />
      <g id="ic" transform="translate(-9 -12)">
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="3"
          height="13"
          transform="translate(11 14)"
          fill="#0079f2"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="3"
          height="13"
          transform="translate(16 14)"
          fill="rgba(0,0,0,0.1)"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="3"
          height="13"
          transform="translate(21 14)"
          fill="rgba(0,0,0,0.1)"
        />
      </g>
    </g>
  </svg>
);

const PriorityNoneSvg = () => (
  <svg
    id="ic-priority"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g id="Btn">
      <rect
        id="placeholder"
        width="17"
        height="17"
        fill="rgba(0,0,0,0.05)"
        opacity="0"
      />
      <g id="ic" transform="translate(-9 -12)">
        <rect
          id="Rectangle_162"
          data-name="Rectangle 162"
          width="3"
          height="13"
          transform="translate(11 14)"
          fill="rgba(0,0,0,0.1)"
        />
        <rect
          id="Rectangle_163"
          data-name="Rectangle 163"
          width="3"
          height="13"
          transform="translate(16 14)"
          fill="rgba(0,0,0,0.1)"
        />
        <rect
          id="Rectangle_164"
          data-name="Rectangle 164"
          width="3"
          height="13"
          transform="translate(21 14)"
          fill="rgba(0,0,0,0.1)"
        />
      </g>
    </g>
  </svg>
);

const ChecklistSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
  >
    <path
      id="Path_285"
      data-name="Path 285"
      d="M13.667,3H4.333A1.337,1.337,0,0,0,3,4.333v9.333A1.337,1.337,0,0,0,4.333,15h9.333A1.337,1.337,0,0,0,15,13.667V4.333A1.337,1.337,0,0,0,13.667,3Zm0,10.667H4.333V4.333h9.333ZM12.993,7l-.94-.947L7.66,10.447,5.94,8.733l-.947.94,2.667,2.66Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

const QuackSvg = () => (
  <svg
    id="Group_318"
    data-name="Group 318"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_166"
          data-name="Rectangle 166"
          width="16"
          height="16"
        />
      </clipPath>
    </defs>
    <rect
      id="Rectangle_165"
      data-name="Rectangle 165"
      width="16"
      height="16"
      opacity="0"
    />
    <g id="Group_316" data-name="Group 316">
      <g id="Group_315" data-name="Group 315" clipPath="url(#clipPath)">
        <path
          id="Path_309"
          data-name="Path 309"
          d="M13.721,4.264A3.666,3.666,0,1,0,7.7,8.211l-1.455.571a9.6,9.6,0,0,1-2.921.552H2s1.041,6,6,6,5.333-4,5.333-4V7.771a3.68,3.68,0,0,0,.5-1.021l1.5-.75V4.667ZM12.242,7,12,7.349v3.9C11.917,11.823,11.376,14,8,14c-2.4,0-3.632-1.915-4.217-3.35a11.025,11.025,0,0,0,2.946-.627l1.454-.571,1.86-.729L8.655,7.285A2.334,2.334,0,1,1,12.242,7m-.909-1.337a1,1,0,1,1-1-1,1,1,0,0,1,1,1"
          transform="translate(-0.667 -0.667)"
        />
      </g>
    </g>
  </svg>
);

const ReportSvg = () => (
  <svg
    id="article-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path id="Path_379" data-name="Path 379" d="M0,0H16V16H0Z" fill="none" />
    <path
      id="Path_380"
      data-name="Path 380"
      d="M13.667,3H4.333A1.337,1.337,0,0,0,3,4.333v9.333A1.337,1.337,0,0,0,4.333,15h9.333A1.337,1.337,0,0,0,15,13.667V4.333A1.337,1.337,0,0,0,13.667,3Zm-3.333,9.333H5.667V11h4.667Zm2-2.667H5.667V8.333h6.667Zm0-2.667H5.667V5.667h6.667Z"
      transform="translate(-1 -1)"
    />
  </svg>
);

const TimeTrackerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g id="Track" transform="translate(-363 -8)">
      <g id="timer-24px" transform="translate(363 8)">
        <path
          id="Path_217"
          data-name="Path 217"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_218"
          data-name="Path 218"
          d="M13,1H8V2.667h5ZM9.667,11.833h1.667v-5H9.667Zm6.692-5.508,1.183-1.183a9.207,9.207,0,0,0-1.175-1.175L15.183,5.15a7.5,7.5,0,1,0,1.175,1.175ZM10.5,16.833A5.833,5.833,0,1,1,16.333,11,5.829,5.829,0,0,1,10.5,16.833Z"
          transform="translate(-0.5 -0.167)"
        />
      </g>
    </g>
  </svg>
);

const PauseTimeTrackerSvg = () => (
  <svg
    id="Tracker"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Btn">
      <rect id="bg" width="20" height="20" rx="10" fill="#0079f2" />
      <g id="pause-24px" transform="translate(2 2)">
        <path
          id="Path_452"
          data-name="Path 452"
          d="M0,0H16V16H0Z"
          fill="none"
        />
        <path
          id="Path_453"
          data-name="Path 453"
          d="M6,14.333H8.667V5H6ZM11.333,5v9.333H14V5Z"
          transform="translate(-2 -1.667)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const StopTimeTrackerSvg = () => (
  <svg
    id="Tracker"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Btn">
      <rect id="bg" width="20" height="20" rx="10" fill="#ff2900" />
      <g id="stop-24px" transform="translate(2 2)">
        <path id="Path_5" data-name="Path 5" d="M0,0H16V16H0Z" fill="none" />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M6,6h8v8H6Z"
          transform="translate(-2 -2)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const MoreSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g id="More" transform="translate(-399 -8)">
      <g id="more_vert-24px" transform="translate(399 8)">
        <path
          id="Path_276"
          data-name="Path 276"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_277"
          data-name="Path 277"
          d="M12,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,16Z"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </svg>
);

const AssignSelfSvg = () => (
  <svg
    id="account_circle-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_324" data-name="Path 324" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_325"
      data-name="Path 325"
      d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,2.4A2.4,2.4,0,1,1,7.6,6.8,2.4,2.4,0,0,1,10,4.4Zm0,11.36a5.76,5.76,0,0,1-4.8-2.576c.024-1.592,3.2-2.464,4.8-2.464s4.776.872,4.8,2.464A5.76,5.76,0,0,1,10,15.76Z"
    />
  </svg>
);

const AddSvg = () => (
  <svg
    id="add-24px_1_"
    data-name="add-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_202" data-name="Path 202" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_203"
      data-name="Path 203"
      d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z"
    />
  </svg>
);

const OrganizationSvg = () => (
  <svg
    id="business-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_63" data-name="Path 63" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_64"
      data-name="Path 64"
      d="M12,7V3H2V21H22V7ZM6,19H4V17H6Zm0-4H4V13H6Zm0-4H4V9H6ZM6,7H4V5H6Zm4,12H8V17h2Zm0-4H8V13h2Zm0-4H8V9h2Zm0-4H8V5h2ZM20,19H12V17h2V15H12V13h2V11H12V9h8Zm-2-8H16v2h2Zm0,4H16v2h2Z"
    />
  </svg>
);

const MenuSquareFilledSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
  >
    <rect
      id="Rectangle_754"
      data-name="Rectangle 754"
      width="72"
      height="72"
      rx="4"
      fill="#f2f2f2"
    />
    <g id="Group_999" data-name="Group 999" transform="translate(-506 -367)">
      <rect
        id="Rectangle_755"
        data-name="Rectangle 755"
        width="48"
        height="4"
        transform="translate(518 393)"
        fill="rgba(0,0,0,0.15)"
      />
      <rect
        id="Rectangle_756"
        data-name="Rectangle 756"
        width="48"
        height="4"
        transform="translate(518 401)"
        fill="rgba(0,0,0,0.15)"
      />
      <rect
        id="Rectangle_757"
        data-name="Rectangle 757"
        width="48"
        height="4"
        transform="translate(518 409)"
        fill="rgba(0,0,0,0.15)"
      />
    </g>
  </svg>
);

const MenuCircleFilledSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g id="Ic" transform="translate(-1227 -295)">
      <circle
        id="Ellipse_52"
        data-name="Ellipse 52"
        cx="32"
        cy="32"
        r="32"
        transform="translate(1227 295)"
        fill="#f2f2f2"
      />
      <g id="Group_65" data-name="Group 65" transform="translate(2 1)">
        <rect
          id="Rectangle_57"
          data-name="Rectangle 57"
          width="32"
          height="4"
          transform="translate(1241 316)"
          fill="#fff"
        />
        <rect
          id="Rectangle_59"
          data-name="Rectangle 59"
          width="32"
          height="4"
          transform="translate(1241 324)"
          fill="#fff"
        />
        <rect
          id="Rectangle_61"
          data-name="Rectangle 61"
          width="32"
          height="4"
          transform="translate(1241 332)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const UpArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="keyboard_arrow_down-24px" transform="translate(24 24) rotate(180)">
      <path id="Path_17" data-name="Path 17" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z"
        fill="rgba(0,0,0,0.5)"
      />
    </g>
  </svg>
);

const DownArrowSvg = () => (
  <svg
    id="keyboard_arrow_down-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_17" data-name="Path 17" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_18"
      data-name="Path 18"
      d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z"
      fill="rgba(0,0,0,0.5)"
    />
  </svg>
);

const RightArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="3"
    height="6"
    viewBox="0 0 3 6"
  >
    <path
      id="Path_373"
      data-name="Path 373"
      d="M8,11.3,9.854,9,8,6.7,8.571,6,11,9,8.571,12Z"
      transform="translate(-8 -6)"
      fill="rgba(0,0,0,0.25)"
    />
  </svg>
);

const CaretLeftSvg = () => (
  <svg
    id="keyboard_arrow_left-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_312" data-name="Path 312" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_313"
      data-name="Path 313"
      d="M14.175,14.825,10.358,11l3.817-3.825L13,6,8,11l5,5Z"
      transform="translate(-1.333 -1)"
      fill="rgba(0,0,0,0.5)"
    />
  </svg>
);

const CaretRightSvg = () => (
  <svg
    id="keyboard_arrow_left-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_312" data-name="Path 312" d="M20,0H0V20H20Z" fill="none" />
    <path
      id="Path_313"
      data-name="Path 313"
      d="M8,14.825,11.817,11,8,7.175,9.175,6l5,5-5,5Z"
      transform="translate(-0.842 -1)"
      fill="rgba(0,0,0,0.5)"
    />
  </svg>
);

const CaretDownSvg = () => (
  <svg
    id="arrow_drop_down-24px_1_"
    data-name="arrow_drop_down-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_237" data-name="Path 237" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_238"
      data-name="Path 238"
      d="M7,10l4.167,4.167L15.333,10Z"
      transform="translate(-1.167 -1.667)"
    />
  </svg>
);

const DominoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="Drag" transform="translate(4 7)">
      <rect
        id="Rectangle_64"
        data-name="Rectangle 64"
        width="32"
        height="32"
        transform="translate(-4 -7)"
        fill="#ff2900"
        opacity="0"
      />
      <g id="Group_67" data-name="Group 67" transform="translate(4 8)">
        <circle
          id="Ellipse_53"
          data-name="Ellipse 53"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(4 -5)"
          fill="rgba(0,0,0,0.15)"
        />
        <circle
          id="Ellipse_55"
          data-name="Ellipse 55"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(4)"
          fill="rgba(0,0,0,0.15)"
        />
        <circle
          id="Ellipse_57"
          data-name="Ellipse 57"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(4 5)"
          fill="rgba(0,0,0,0.15)"
        />
        <circle
          id="Ellipse_54"
          data-name="Ellipse 54"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9 -5)"
          fill="rgba(0,0,0,0.15)"
        />
        <circle
          id="Ellipse_56"
          data-name="Ellipse 56"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9)"
          fill="rgba(0,0,0,0.15)"
        />
        <circle
          id="Ellipse_58"
          data-name="Ellipse 58"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9 5)"
          fill="rgba(0,0,0,0.15)"
        />
      </g>
    </g>
  </svg>
);

const CloseSvg = () => (
  <svg
    id="close-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path id="Path_374" data-name="Path 374" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_375"
      data-name="Path 375"
      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
    />
  </svg>
);

const DraftSvg = () => (
  <svg
    id="create-24px_1_"
    data-name="create-24px (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_340" data-name="Path 340" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_341"
      data-name="Path 341"
      d="M3,12.9v2.6H5.6l7.681-7.681-2.6-2.6ZM15.3,5.805a.692.692,0,0,0,0-.979L13.675,3.2a.692.692,0,0,0-.979,0L11.425,4.472l2.6,2.6Z"
      transform="translate(0.75 0.749)"
    />
  </svg>
);

const DeleteSvg = () => (
  <svg
    id="delete-24px_2_"
    data-name="delete-24px (2)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_350" data-name="Path 350" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_351"
      data-name="Path 351"
      d="M5.833,16.333A1.672,1.672,0,0,0,7.5,18h6.667a1.672,1.672,0,0,0,1.667-1.667v-10h-10Zm10.833-12.5H13.75L12.917,3H8.75l-.833.833H5V5.5H16.667Z"
      transform="translate(-0.833 -0.5)"
    />
  </svg>
);

const ReactSvg = () => (
  <svg
    id="sentiment_satisfied_alt-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path id="Path_342" data-name="Path 342" d="M0,0H20V20H0Z" fill="none" />
    <path id="Path_343" data-name="Path 343" d="M0,0H20V20H0Z" fill="none" />
    <circle
      id="Ellipse_102"
      data-name="Ellipse 102"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(11.333 6.476)"
    />
    <circle
      id="Ellipse_103"
      data-name="Ellipse 103"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(5.667 6.476)"
    />
    <path
      id="Path_344"
      data-name="Path 344"
      d="M9.992,2A8,8,0,1,0,18,10,8,8,0,0,0,9.992,2ZM10,16.4A6.4,6.4,0,1,1,16.4,10,6.4,6.4,0,0,1,10,16.4Zm0-2a4.4,4.4,0,0,0,4.1-2.8H12.76a3.18,3.18,0,0,1-5.52,0H5.9A4.4,4.4,0,0,0,10,14.4Z"
    />
  </svg>
);

const SuccessCircleFilledSvg = () => (
  <svg
    id="check_circle_black_24dp_1_"
    data-name="check_circle_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path id="Path_784" data-name="Path 784" d="M0,0H16V16H0Z" fill="none" />
    <path
      id="Path_785"
      data-name="Path 785"
      d="M8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2ZM6.8,11l-3-3,.846-.846L6.8,9.3l4.554-4.554L12.2,5.6Z"
      fill="#40bf00"
    />
  </svg>
);

const ErrorCircleFilledSvg = () => (
  <svg
    id="error-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path id="Path_44" data-name="Path 44" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_45"
      data-name="Path 45"
      d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm.8,12H9.2V12.4h1.6Zm0-3.2H9.2V6h1.6Z"
      fill="#ff2900"
    />
  </svg>
);

const ErrorModalSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80.444"
    height="79.606"
    viewBox="0 0 80.444 79.606"
  >
    <g
      id="Group_1040"
      data-name="Group 1040"
      transform="translate(-24 -24.394)"
    >
      <rect
        id="Rectangle_767"
        data-name="Rectangle 767"
        width="50"
        height="50"
        rx="2"
        transform="translate(24 54)"
        fill="#ff4a26"
      />
      <rect
        id="Rectangle_768"
        data-name="Rectangle 768"
        width="60"
        height="60"
        rx="4"
        transform="translate(44.444 24.394)"
        fill="#ff2900"
      />
      <path
        id="Path_655"
        data-name="Path 655"
        d="M72,54H44V80a4,4,0,0,0,4,4H74V56A2,2,0,0,0,72,54Z"
        fill="#e61300"
      />
      <path
        id="Path_656"
        data-name="Path 656"
        d="M58,41.789H90v32H58Z"
        fill="none"
      />
      <g id="Group_1039" data-name="Group 1039">
        <circle
          id="Ellipse_203"
          data-name="Ellipse 203"
          cx="3.556"
          cy="3.556"
          r="3.556"
          transform="translate(70.889 63.283)"
          fill="#fff"
        />
        <path
          id="Path_657"
          data-name="Path 657"
          d="M70.889,38.394H78V59.728H70.889Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const DeleteConfirmationModalSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <g id="Group_1042" data-name="Group 1042" transform="translate(-24 -24)">
      <rect
        id="Rectangle_769"
        data-name="Rectangle 769"
        width="50"
        height="50"
        rx="2"
        transform="translate(24 54)"
        fill="#ff4a26"
      />
      <rect
        id="Rectangle_770"
        data-name="Rectangle 770"
        width="60"
        height="60"
        rx="4"
        transform="translate(44 24)"
        fill="#ff2900"
      />
      <path
        id="Path_658"
        data-name="Path 658"
        d="M72,54H44V80a4,4,0,0,0,4,4H74V56A2,2,0,0,0,72,54Z"
        fill="#e61300"
      />
      <g id="Group_1041" data-name="Group 1041">
        <path
          id="Path_659"
          data-name="Path 659"
          d="M58,38H90V70H58Z"
          fill="none"
        />
        <path
          id="Path_660"
          data-name="Path 660"
          d="M66,63.333A2.675,2.675,0,0,0,68.667,66H79.333A2.675,2.675,0,0,0,82,63.333v-16H66Zm17.333-20H78.667L77.333,42H70.667l-1.334,1.333H64.667V46H83.333Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const ExpandSvg = () => (
  <svg
    id="unfold_less-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_589"
      data-name="Path 589"
      d="M0,0H16V16H0Z"
      fill="#ffd9d9"
      opacity="0"
    />
    <path
      id="Path_593"
      data-name="Path 593"
      d="M7,10l2.667,2.667L12.333,10Z"
      transform="translate(-1.667 1.333)"
      fill="currentColor"
    />
    <path
      id="Path_594"
      data-name="Path 594"
      d="M7,12.667,9.667,10l2.667,2.667Z"
      transform="translate(-1.667 -8)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_715"
      data-name="Rectangle 715"
      width="12"
      height="1.333"
      transform="translate(2 6)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_716"
      data-name="Rectangle 716"
      width="12"
      height="1.333"
      transform="translate(2 8.667)"
      fill="currentColor"
    />
  </svg>
);

const SuperExpandSvg = () => (
  <svg
    id="unfold_less-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_589" data-name="Path 589" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_593"
      data-name="Path 593"
      d="M7,10l3.333,3.333L13.667,10Z"
      transform="translate(-0.333 5)"
      fill="currentColor"
    />
    <path
      id="Path_594"
      data-name="Path 594"
      d="M7,13.333,10.333,10l3.333,3.333Z"
      transform="translate(-0.333 -8.333)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_715"
      data-name="Rectangle 715"
      width="15"
      height="1.667"
      transform="translate(2.5 6.667)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_716"
      data-name="Rectangle 716"
      width="15"
      height="1.667"
      transform="translate(2.5 9.167)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_717"
      data-name="Rectangle 717"
      width="15"
      height="1.667"
      transform="translate(2.5 11.667)"
      fill="currentColor"
    />
  </svg>
);

const CollapseSvg = () => (
  <svg
    id="unfold_less-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_589" data-name="Path 589" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_593"
      data-name="Path 593"
      d="M7,13.333,10.333,10l3.333,3.333Z"
      transform="translate(-0.333 2.5)"
      fill="currentColor"
    />
    <path
      id="Path_594"
      data-name="Path 594"
      d="M7,10l3.333,3.333L13.667,10Z"
      transform="translate(-0.333 -5.833)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_714"
      data-name="Rectangle 714"
      width="15"
      height="1.667"
      transform="translate(2.5 9.167)"
      fill="currentColor"
    />
  </svg>
);

const ArrowDownSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path fill="none" d="M0,0H20V20H0Z" />
    <path
      d="M7,10l4.167,4.167L15.333,10Z"
      transform="translate(-1.167 -1.667)"
    />
  </svg>
);

const ArrowRightSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          id="Path_312"
          data-name="Path 312"
          d="M24,0H0V24H24Z"
        />
        <path
          fill="#0000003F"
          id="Path_313"
          data-name="Path 313"
          d="M8.59,16.59,13.17,12,8.59,7.41,10,6l6,6-6,6Z"
        />
      </g>
    </g>
  </svg>
);

const TimeSvg = () => (
  <svg
    id="query_builder-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_421" data-name="Path 421" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_422"
      data-name="Path 422"
      d="M10.325,2a8.333,8.333,0,1,0,8.342,8.333A8.329,8.329,0,0,0,10.325,2Zm.008,15A6.667,6.667,0,1,1,17,10.333,6.665,6.665,0,0,1,10.333,17Z"
      transform="translate(-0.333 -0.333)"
      fill="currentColor"
    />
    <path
      id="Path_423"
      data-name="Path 423"
      d="M12.25,7H11v5l4.375,2.625L16,13.6l-3.75-2.225Z"
      transform="translate(-1.833 -1.167)"
      fill="currentColor"
    />
  </svg>
);

const CommentSvg = () => (
  <svg
    id="comment-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_426" data-name="Path 426" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_427"
      data-name="Path 427"
      d="M18.658,3.667A1.664,1.664,0,0,0,17,2H3.667A1.672,1.672,0,0,0,2,3.667v10a1.672,1.672,0,0,0,1.667,1.667H15.333l3.333,3.333ZM15.333,12h-10V10.333h10Zm0-2.5h-10V7.833h10Zm0-2.5h-10V5.333h10Z"
      transform="translate(-0.333 -0.333)"
      fill="currentColor"
    />
  </svg>
);

const TextSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="title-24px" transform="translate(2 2)">
        <path
          id="Path_486"
          data-name="Path 486"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_487"
          data-name="Path 487"
          d="M5,4V6.5H9.583v10h2.5V6.5h4.583V4Z"
          transform="translate(-0.833 -0.667)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const BoldSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="format_bold-24px" transform="translate(2 2)">
        <path
          id="Path_470"
          data-name="Path 470"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_471"
          data-name="Path 471"
          d="M14.167,9.658a3.034,3.034,0,0,0,1.375-2.325A3.286,3.286,0,0,0,12.208,4H7V15.667h5.867a3.16,3.16,0,0,0,1.3-6.008ZM9.5,6.083H12a1.25,1.25,0,0,1,0,2.5H9.5Zm2.917,7.5H9.5v-2.5h2.917a1.25,1.25,0,0,1,0,2.5Z"
          transform="translate(-1.167 -0.667)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const ItalicSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="format_italic-24px" transform="translate(2 2)">
        <path
          id="Path_474"
          data-name="Path 474"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_475"
          data-name="Path 475"
          d="M9.333,4V6.5h1.842l-2.85,6.667H6v2.5h6.667v-2.5H10.825L13.675,6.5H16V4Z"
          transform="translate(-1 -0.667)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const UnderlineSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="format_underlined-24px" transform="translate(2 2)">
        <path
          id="Path_484"
          data-name="Path 484"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_485"
          data-name="Path 485"
          d="M10.833,14.667a5,5,0,0,0,5-5V3H13.75V9.667a2.917,2.917,0,1,1-5.833,0V3H5.833V9.667A5,5,0,0,0,10.833,14.667ZM5,16.333V18H16.667V16.333Z"
          transform="translate(-0.833 -0.5)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const StrikethroughSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="format_strikethrough-24px" transform="translate(2 2)">
        <path
          id="Path_482"
          data-name="Path 482"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_483"
          data-name="Path 483"
          d="M8.833,16.5h3.333V14H8.833ZM4.667,4V6.5H8.833V9h3.333V6.5h4.167V4ZM3,12.333H18V10.667H3Z"
          transform="translate(-0.5 -0.667)"
          fill="rgba(0,0,0,0.5)"
        />
      </g>
    </g>
  </svg>
);

const LinkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="insert_link-24px" transform="translate(2 2)">
        <path
          id="Path_480"
          data-name="Path 480"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_481"
          data-name="Path 481"
          d="M3.583,11.167A2.586,2.586,0,0,1,6.167,8.583H9.5V7H6.167a4.167,4.167,0,0,0,0,8.333H9.5V13.75H6.167A2.586,2.586,0,0,1,3.583,11.167ZM7,12h6.667V10.333H7Zm7.5-5H11.167V8.583H14.5a2.583,2.583,0,1,1,0,5.167H11.167v1.583H14.5A4.167,4.167,0,1,0,14.5,7Z"
          transform="translate(-0.333 -1.167)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const BlockQuoteSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="format_quote-24px" transform="translate(2 2)">
        <path
          id="Path_476"
          data-name="Path 476"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_477"
          data-name="Path 477"
          d="M5.833,15.333h2.5L10,12V7H5v5H7.5Zm6.667,0H15L16.667,12V7h-5v5h2.5Z"
          transform="translate(-0.833 -1.167)"
          fill="rgba(0,0,0,0.5)"
        />
      </g>
    </g>
  </svg>
);

const ImageSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="insert_photo-24px" transform="translate(2 2)">
        <path
          id="Path_488"
          data-name="Path 488"
          d="M0,0H20V20H0Z"
          fill="none"
        />
        <path
          id="Path_489"
          data-name="Path 489"
          d="M18,16.333V4.667A1.672,1.672,0,0,0,16.333,3H4.667A1.672,1.672,0,0,0,3,4.667V16.333A1.672,1.672,0,0,0,4.667,18H16.333A1.672,1.672,0,0,0,18,16.333ZM7.583,11.75l2.083,2.508L12.583,10.5l3.75,5H4.667Z"
          transform="translate(-0.5 -0.5)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const CopyLinkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="none"
          id="Path_646"
          data-name="Path 646"
          d="M0,0H20V20H0Z"
        />
        <path
          fill="#8c8c8c"
          id="Path_647"
          data-name="Path 647"
          d="M3.25,10A2.58,2.58,0,0,1,5.83,7.42H9.17V5.83H5.83a4.17,4.17,0,0,0,0,8.34H9.17V12.58H5.83A2.58,2.58,0,0,1,3.25,10Zm3.42.83h6.66V9.17H6.67Zm7.5-5H10.83V7.42h3.34a2.58,2.58,0,1,1,0,5.16H10.83v1.59h3.34a4.17,4.17,0,0,0,.13-8.34Z"
        />
      </g>
    </g>
  </svg>
);

const AttachFileSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
  >
    <g id="Btn" transform="translate(1 1)">
      <g
        id="Rectangle_158"
        data-name="Rectangle 158"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
        opacity="0"
      >
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="-0.5" y="-0.5" width="25" height="25" rx="4.5" fill="none" />
      </g>
      <g id="attach_file-24px" transform="translate(3 3)">
        <path
          id="Path_472"
          data-name="Path 472"
          d="M0,0H18V18H0Z"
          fill="none"
        />
        <path
          id="Path_473"
          data-name="Path 473"
          d="M14.125,4.75v8.625a3,3,0,1,1-6,0V4a1.875,1.875,0,1,1,3.75,0v7.875a.75.75,0,1,1-1.5,0V4.75H9.25v7.125a1.875,1.875,0,1,0,3.75,0V4A3,3,0,1,0,7,4v9.375a4.125,4.125,0,1,0,8.25,0V4.75Z"
          transform="translate(-1.75 -0.25)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const NoteSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <path fill="none" d="M0,0H18V18H0Z" />
    <path
      fill="currentColor"
      d="M9.5,2A7.5,7.5,0,1,0,17,9.5,7.5,7.5,0,0,0,9.5,2Zm.75,11.25H8.75V8.75h1.5Zm0-6H8.75V5.75h1.5Z"
      transform="translate(-0.5 -0.5)"
    />
  </svg>
);

const NoTaskSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
  >
    <g id="Group_1071" data-name="Group 1071" transform="translate(-28 -24)">
      <rect
        id="Rectangle_775"
        data-name="Rectangle 775"
        width="50"
        height="50"
        rx="2"
        transform="translate(28 54)"
        fill="#07e"
      />
      <rect
        id="Rectangle_776"
        data-name="Rectangle 776"
        width="60"
        height="60"
        rx="4"
        transform="translate(48 24)"
        fill="#40bf00"
      />
      <path
        id="Path_673"
        data-name="Path 673"
        d="M76,54H48V80a4,4,0,0,0,4,4H78V56A2,2,0,0,0,76,54Z"
        fill="#006b8f"
      />
      <g
        id="reorder-24px_1_"
        data-name="reorder-24px (1)"
        transform="translate(66 42)"
      >
        <path
          id="Path_679"
          data-name="Path 679"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_680"
          data-name="Path 680"
          d="M3,15H21V13H3Zm0,4H21V17H3Zm0-8H21V9H3ZM3,5V7H21V5Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const NextShiftSvg = () => (
  <svg
    id="redo-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_543" data-name="Path 543" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_544"
      data-name="Path 544"
      d="M15.59,10A8.726,8.726,0,0,0,1.54,13.85l1.967.65a6.642,6.642,0,0,1,10.6-3.017L11.09,14.5h7.5V7Z"
      transform="translate(-0.257 -1.167)"
      fill="currentColor"
    />
  </svg>
);

const StarFilledSvg = () => (
  <svg
    id="grade-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path id="Path_464" data-name="Path 464" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_465"
      data-name="Path 465"
      d="M10.333,14.725l5.15,3.108-1.367-5.858,4.55-3.942-5.992-.508L10.333,2,7.992,7.525,2,8.033l4.55,3.942L5.183,17.833Z"
      transform="translate(-0.333 -0.333)"
      fill="currentColor"
    />
  </svg>
);

const StarOutlineSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16.667 15.833"
  >
    <path
      id="Path_841"
      data-name="Path 841"
      d="M10.333,6.275l.808,1.908.392.925,1,.083,2.058.175-1.567,1.358-.758.658.225.983.467,2.008-1.767-1.067-.858-.533-.858.517L7.708,14.358l.467-2.008.225-.983-.758-.658L6.075,9.35l2.058-.175,1-.083.392-.925.808-1.892m0-4.275L7.992,7.525,2,8.033l4.55,3.942L5.183,17.833l5.15-3.108,5.15,3.108-1.367-5.858,4.55-3.942-5.992-.508Z"
      transform="translate(-2 -2)"
      fill="currentColor"
    />
  </svg>
);

const UndoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
  >
    <g id="Btn" transform="translate(-754 -685)">
      <rect
        id="active_area"
        data-name="active area"
        width="1em"
        height="1em"
        rx="4"
        transform="translate(754 685)"
      />
      <g id="undo-24px" transform="translate(758 689)">
        <path
          id="Path_690"
          data-name="Path 690"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_691"
          data-name="Path 691"
          d="M12.5,8a10.464,10.464,0,0,0-6.9,2.6L2,7v9h9L7.38,12.38A7.971,7.971,0,0,1,20.1,16l2.37-.78A10.514,10.514,0,0,0,12.5,8Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

const HistorySvg = () => (
  <svg
    id="fact_check_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Group_1505" data-name="Group 1505">
      <rect
        id="Rectangle_1015"
        data-name="Rectangle 1015"
        width="20"
        height="20"
        fill="none"
      />
    </g>
    <g id="Group_1507" data-name="Group 1507" transform="translate(1.667 2.5)">
      <g id="Group_1506" data-name="Group 1506">
        <path
          id="Path_788"
          data-name="Path 788"
          d="M17,3H3.667A1.672,1.672,0,0,0,2,4.667V16.333A1.672,1.672,0,0,0,3.667,18H17a1.672,1.672,0,0,0,1.667-1.667V4.667A1.672,1.672,0,0,0,17,3ZM8.667,14.667H4.5V13H8.667Zm0-3.333H4.5V9.667H8.667ZM8.667,8H4.5V6.333H8.667Zm4.017,5-2.35-2.367,1.175-1.175,1.175,1.183L15.325,8l1.183,1.183Z"
          transform="translate(-2 -3)"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const TableGeneralViewSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
  >
    <g id="Group_1503" data-name="Group 1503" transform="translate(-172 -42)">
      <rect
        id="Rectangle_995"
        data-name="Rectangle 995"
        width="6"
        height="6"
        transform="translate(172 42)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1014"
        data-name="Rectangle 1014"
        width="6"
        height="6"
        transform="translate(172 50)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1004"
        data-name="Rectangle 1004"
        width="6"
        height="6"
        transform="translate(180 42)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1013"
        data-name="Rectangle 1013"
        width="6"
        height="6"
        transform="translate(180 50)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const TableDetailedViewSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
  >
    <g id="Group_1502" data-name="Group 1502" transform="translate(-170 -42)">
      <rect
        id="Rectangle_995"
        data-name="Rectangle 995"
        width="4"
        height="4"
        transform="translate(170 42)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1007"
        data-name="Rectangle 1007"
        width="4"
        height="4"
        transform="translate(170 47)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1010"
        data-name="Rectangle 1010"
        width="4"
        height="4"
        transform="translate(170 52)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_996"
        data-name="Rectangle 996"
        width="4"
        height="4"
        transform="translate(175 42)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1008"
        data-name="Rectangle 1008"
        width="4"
        height="4"
        transform="translate(175 47)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1011"
        data-name="Rectangle 1011"
        width="4"
        height="4"
        transform="translate(175 52)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_997"
        data-name="Rectangle 997"
        width="4"
        height="4"
        transform="translate(180 42)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1009"
        data-name="Rectangle 1009"
        width="4"
        height="4"
        transform="translate(180 47)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_1012"
        data-name="Rectangle 1012"
        width="4"
        height="4"
        transform="translate(180 52)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const MTagSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="M" transform="translate(-72.328 -73)">
      <g
        id="Rectangle_905"
        data-name="Rectangle 905"
        transform="translate(72.328 73)"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
      >
        <rect width="16" height="16" rx="2" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="none" />
      </g>
      <path
        id="Path_764"
        data-name="Path 764"
        d="M-.948-.566l-2.32-6.681h-.044q.093,1.488.093,2.791V-.566H-4.406v-8h1.844L-.341-2.2h.033L1.98-8.566h1.85v8H2.57V-4.523q0-.6.03-1.554t.052-1.16H2.609L.207-.566Z"
        transform="translate(80.616 85.566)"
        fill="#ff8000"
      />
    </g>
  </svg>
);

const NoMemberSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
  >
    <g id="Group_1071" data-name="Group 1071" transform="translate(-28 -24)">
      <rect
        id="Rectangle_775"
        data-name="Rectangle 775"
        width="50"
        height="50"
        rx="2"
        transform="translate(28 54)"
        fill="#07e"
      />
      <rect
        id="Rectangle_776"
        data-name="Rectangle 776"
        width="60"
        height="60"
        rx="4"
        transform="translate(48 24)"
        fill="#40bf00"
      />
      <path
        id="Path_673"
        data-name="Path 673"
        d="M76,54H48V80a4,4,0,0,0,4,4H78V56A2,2,0,0,0,76,54Z"
        fill="#006b8f"
      />
      <g
        id="person-24px_1_"
        data-name="person-24px (1)"
        transform="translate(66 42)"
      >
        <path
          id="Path_681"
          data-name="Path 681"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_682"
          data-name="Path 682"
          d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const ClockSvg = () => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path id="Path_44" data-name="Path 44" d="M0,0H16V16H0Z" fill="none" />
    <g
      id="Path_45"
      data-name="Path 45"
      transform="translate(-0.666 -0.667)"
      fill="none"
    >
      <path
        d="M8.667,2a6.667,6.667,0,1,0,6.667,6.667A6.669,6.669,0,0,0,8.667,2Z"
        stroke="none"
      />
      <path
        d="M 8.666666984558105 2.999997138977051 C 5.542057037353516 2.999997138977051 2.999997138977051 5.542057037353516 2.999997138977051 8.666666984558105 C 2.999997138977051 11.7912769317627 5.542057037353516 14.33333683013916 8.666666984558105 14.33333683013916 C 11.7912769317627 14.33333683013916 14.33333683013916 11.7912769317627 14.33333683013916 8.666666984558105 C 14.33333683013916 5.542057037353516 11.7912769317627 2.999997138977051 8.666666984558105 2.999997138977051 M 8.666666984558105 1.999997138977051 C 12.34666633605957 1.999997138977051 15.33333683013916 4.986666679382324 15.33333683013916 8.666666984558105 C 15.33333683013916 12.34666633605957 12.34666633605957 15.33333683013916 8.666666984558105 15.33333683013916 C 4.986666679382324 15.33333683013916 1.999997138977051 12.34666633605957 1.999997138977051 8.666666984558105 C 1.999997138977051 4.986666679382324 4.986666679382324 1.999997138977051 8.666666984558105 1.999997138977051 Z"
        stroke="none"
        fill="currentColor"
      />
    </g>
    <rect
      id="Rectangle_1203"
      data-name="Rectangle 1203"
      width="1"
      height="4"
      transform="translate(7 5)"
      fill="currentColor"
    />
    <rect
      id="Rectangle_1204"
      data-name="Rectangle 1204"
      width="4"
      height="1"
      transform="translate(7 9)"
      fill="currentColor"
    />
  </svg>
);

const ManuallyAddedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="M" transform="translate(-72.328 -73)">
      <g
        id="Rectangle_905"
        data-name="Rectangle 905"
        transform="translate(72.328 73)"
        fill="#fff"
        stroke="rgba(0,0,0,0.15)"
        stroke-width="1"
      >
        <rect width="16" height="16" rx="2" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="none" />
      </g>
      <path
        id="Path_764"
        data-name="Path 764"
        d="M-.948-.566l-2.32-6.681h-.044q.093,1.488.093,2.791V-.566H-4.406v-8h1.844L-.341-2.2h.033L1.98-8.566h1.85v8H2.57V-4.523q0-.6.03-1.554t.052-1.16H2.609L.207-.566Z"
        transform="translate(80.616 85.566)"
        fill="#ff8000"
      />
    </g>
  </svg>
);

const RefreshSvg = () => (
  <svg
    id="refresh_black_24dp_1_"
    data-name="refresh_black_24dp (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path id="Path_856" data-name="Path 856" d="M0,0H16V16H0Z" fill="none" />
    <path
      id="Path_857"
      data-name="Path 857"
      d="M13.1,5.567a5.334,5.334,0,1,0,1.387,5.1H13.1A4,4,0,1,1,9.337,5.333,3.943,3.943,0,0,1,12.15,6.52L10,8.667H14.67V4Z"
      transform="translate(-1.337 -1.333)"
      fill="currentColor"
    />
  </svg>
);

const ArrowPointerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 8"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_820"
          data-name="Path 820"
          fill="currentColor"
          d="M12,3H0V5H12V8l4-4L12,0Z"
        />
      </g>
    </g>
  </svg>
);

const NoCheckoutSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
  >
    <g id="Group_1071" data-name="Group 1071" transform="translate(-28 -24)">
      <rect
        id="Rectangle_775"
        data-name="Rectangle 775"
        width="50"
        height="50"
        rx="2"
        transform="translate(28 54)"
        fill="#07e"
      />
      <rect
        id="Rectangle_776"
        data-name="Rectangle 776"
        width="60"
        height="60"
        rx="4"
        transform="translate(48 24)"
        fill="#40bf00"
      />
      <path
        id="Path_673"
        data-name="Path 673"
        d="M76,54H48V80a4,4,0,0,0,4,4H78V56A2,2,0,0,0,76,54Z"
        fill="#006b8f"
      />
      <g id="done-24px" transform="translate(66 42)">
        <path
          id="Path_587"
          data-name="Path 587"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_588"
          data-name="Path 588"
          d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const LabelSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="trigger">
          <path
            id="Path_1025"
            data-name="Path 1025"
            fill="none"
            d="M0,0H16V16H0Z"
          />
          <path
            id="Path_1026"
            data-name="Path 1026"
            fill="currentColor"
            d="M11.75,3.89a1.33,1.33,0,0,0-1.08-.56H3.33A1.33,1.33,0,0,0,2,4.67v6.66a1.33,1.33,0,0,0,1.33,1.33h7.34a1.33,1.33,0,0,0,1.08-.56L14.67,8Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

const MergeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_632"
          data-name="Path 632"
          fill="none"
          d="M0,0H20V20H0Z"
        />
        <path
          id="Path_633"
          data-name="Path 633"
          fill="currentColor"
          d="M10,15.45,3.85,10.68l-1.35,1L10,17.56l7.5-5.84-1.36-1.05Zm0-2.12,6.13-4.77L17.5,7.5,10,1.67,2.5,7.5,3.86,8.56Z"
        />
      </g>
    </g>
  </svg>
);

const MoveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_687"
          data-name="Path 687"
          fill="none"
          d="M0,0H20V20H0Z"
        />
        <path
          id="Path_688"
          data-name="Path 688"
          fill="currentColor"
          d="M16.67,5H10L8.33,3.33h-5A1.67,1.67,0,0,0,1.67,5V15a1.67,1.67,0,0,0,1.66,1.67H16.67A1.67,1.67,0,0,0,18.33,15V6.67A1.66,1.66,0,0,0,16.67,5Zm-5,10V12.5H8.33V9.17h3.34V6.67l4.16,4.16Z"
        />
      </g>
    </g>
  </svg>
);

const ArchiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_636"
          data-name="Path 636"
          fill="none"
          d="M0,0H20V20H0Z"
        />
        <path
          id="Path_637"
          data-name="Path 637"
          fill="currentColor"
          d="M16.64,4.73l-1.08-1.3A1.14,1.14,0,0,0,14.67,3H5.33a1.16,1.16,0,0,0-.9.43L3.36,4.73a1.56,1.56,0,0,0-.36,1v9.72A1.56,1.56,0,0,0,4.56,17H15.44A1.56,1.56,0,0,0,17,15.44V5.72A1.56,1.56,0,0,0,16.64,4.73ZM10,14.28,5.72,10H8.44V8.44h3.12V10h2.72ZM4.65,4.56l.63-.78h9.33l.73.78Z"
        />
      </g>
    </g>
  </svg>
);

const UserSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path
          fill="currentColor"
          d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
        />
      </g>
    </g>
  </svg>
);

const WatchSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path
          fill="currentColor"
          d="M12,4.5A11.83,11.83,0,0,0,1,12a11.82,11.82,0,0,0,22,0A11.83,11.83,0,0,0,12,4.5ZM12,17a5,5,0,1,1,5-5A5,5,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z"
        />
      </g>
    </g>
  </svg>
);

const SettingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_441"
          data-name="Path 441"
          fill="none"
          d="M0,0H24V24H0Z"
        />
        <path
          id="Path_442"
          fill="currentColor"
          data-name="Path 442"
          d="M19.14,12.94A7.42,7.42,0,0,0,19.2,12a6,6,0,0,0-.07-.94l2-1.58a.49.49,0,0,0,.12-.61L19.36,5.55a.49.49,0,0,0-.59-.22l-2.39,1a7.28,7.28,0,0,0-1.62-.94L14.4,2.81a.48.48,0,0,0-.48-.41H10.08a.47.47,0,0,0-.47.41L9.25,5.35a7.28,7.28,0,0,0-1.62.94l-2.39-1a.48.48,0,0,0-.59.22L2.74,8.87a.46.46,0,0,0,.12.61l2,1.58a5.65,5.65,0,0,0,0,1.88l-2,1.58a.49.49,0,0,0-.12.61l1.92,3.32a.49.49,0,0,0,.59.22l2.39-1a7.28,7.28,0,0,0,1.62.94l.36,2.54a.49.49,0,0,0,.48.41h3.84a.47.47,0,0,0,.47-.41l.36-2.54a6.72,6.72,0,0,0,1.62-.94l2.39,1a.48.48,0,0,0,.59-.22l1.92-3.32a.47.47,0,0,0-.12-.61ZM12,15.6A3.6,3.6,0,1,1,15.6,12,3.6,3.6,0,0,1,12,15.6Z"
        />
      </g>
    </g>
  </svg>
);

const ShowArchiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path
          fill="currentColor"
          d="M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,12H15a3,3,0,0,1-6,0H5V5H19Z"
        />
      </g>
    </g>
  </svg>
);

const ProjectSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    height="1em"
    width="1em"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1_copy" data-name="Layer 1 copy">
        <path
          id="Path_950"
          data-name="Path 950"
          fill="none"
          d="M0,0H20V20H0Z"
        />
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          fill="currentColor"
          d="M16.67,18.33H3.33a1.66,1.66,0,0,1-1.66-1.66V3.33A1.66,1.66,0,0,1,3.33,1.67H16.67a1.67,1.67,0,0,1,1.66,1.66V16.67A1.66,1.66,0,0,1,16.67,18.33ZM7,4.5H7v11H8.79V11.38H9.94a4.92,4.92,0,0,0,3.21-.92,3.21,3.21,0,0,0,1.13-2.64,3.06,3.06,0,0,0-1-2.48,4.8,4.8,0,0,0-3.09-.84ZM9.75,9.87h-1V6H10a2.88,2.88,0,0,1,1.81.46,1.71,1.71,0,0,1,.58,1.42,1.8,1.8,0,0,1-.64,1.5,3.4,3.4,0,0,1-2,.48Z"
        />
      </g>
    </g>
  </svg>
);
export const ProjectIcon = (props: any) => (
  <Icon component={ProjectSvg} {...props} />
);

const MembersSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path
          fill="currentColor"
          d="M9,11.75A1.25,1.25,0,1,0,10.25,13,1.25,1.25,0,0,0,9,11.75Zm6,0A1.25,1.25,0,1,0,16.25,13,1.25,1.25,0,0,0,15,11.75ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-8-8,8.11,8.11,0,0,1,0-.86A10.06,10.06,0,0,0,9.26,5.77,10,10,0,0,0,17.42,10a9.76,9.76,0,0,0,2.25-.26A8,8,0,0,1,12,20Z"
        />
      </g>
    </g>
  </svg>
);
export const MembersIcon = (props: any) => (
  <Icon component={MembersSvg} {...props} />
);

const HomeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path fill="currentColor" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" />
      </g>
    </g>
  </svg>
);
export const HomeIcon = (props: any) => <Icon component={HomeSvg} {...props} />;

export const NormalPanelIcon = (props: any) => (
  <Icon component={NormalPanelSvg} {...props} />
);

export const CompactPanelIcon = (props: any) => (
  <Icon component={CompactPanelSvg} {...props} />
);

export const OfficeIcon = (props: any) => (
  <Icon component={OfficeSvg} {...props} />
);

export const ReportsIcon = (props: any) => (
  <Icon component={ReportsSvg} {...props} />
);

export const CheckoutIcon = (props: any) => (
  <Icon component={CheckoutSvg} {...props} />
);

export const ActivitiesIcon = (props: any) => (
  <Icon component={ActivitiesSvg} {...props} />
);

export const IndividualIcon = (props: any) => {
  return <Icon component={IndividualSvg} {...props} />;
};

export const TeamIcon = (props: any) => {
  return <Icon component={TeamSvg} {...props} />;
};

export const SearchIcon = (props: any) => (
  <Icon component={SearchSvg} {...props} />
);

export const FilterIcon = (props: any) => (
  <Icon component={FilterSvg} {...props} />
);
export const FilterActiveIcon = (props: any) => (
  <Icon component={FilterActiveSvg} {...props} />
);
export const TodayIcon = (props: any) => (
  <Icon component={TodaySvg} {...props} />
);
export const TasksIcon = (props: any) => (
  <Icon component={TasksSvg} {...props} />
);
export const CalendarIcon = (props: any) => (
  <Icon component={CalendarSvg} {...props} />
);
export const ExclamationCircleFilledIcon = (props: any) => (
  <Icon component={ExclamationCircleFilledSvg} {...props} />
);
export const NotificationBellIcon = (props: any) => (
  <Icon component={NotificationBellSvg} {...props} />
);
export const BulletPointIcon = (props: any) => (
  <Icon component={BulletPointSvg} {...props} />
);
export const InTheOfficeIcon = (props: any) => (
  <Icon component={InTheOfficeSvg} {...props} />
);
export const LeaveTheOfficeIcon = (props: any) => (
  <Icon component={LeaveTheOfficeSvg} {...props} />
);
export const InfoCircleOutlinedIcon = (props: any) => (
  <Icon component={InfoCircleOutlinedSvg} {...props} />
);
export const InfoCircleFilledIcon = (props: any) => (
  <Icon component={InfoCircleFilledSvg} {...props} />
);
export const PriorityHighIcon = (props: any) => (
  <Icon component={PriorityHighSvg} {...props} />
);
export const PriorityMediumIcon = (props: any) => (
  <Icon component={PriorityMediumSvg} {...props} />
);
export const PriorityLowIcon = (props: any) => (
  <Icon component={PriorityLowSvg} {...props} />
);
export const PriorityNoneIcon = (props: any) => (
  <Icon component={PriorityNoneSvg} {...props} />
);
export const ChecklistIcon = (props: any) => (
  <Icon component={ChecklistSvg} {...props} />
);
export const QuackIcon = (props: any) => (
  <Icon component={QuackSvg} {...props} />
);
export const ReportIcon = (props: any) => (
  <Icon component={ReportSvg} {...props} />
);
export const TimeTrackerIcon = (props: any) => (
  <Icon component={TimeTrackerSvg} {...props} />
);
export const PauseTimeTrackerIcon = (props: any) => (
  <Icon component={PauseTimeTrackerSvg} {...props} />
);
export const StopTimeTrackerIcon = (props: any) => (
  <Icon component={StopTimeTrackerSvg} {...props} />
);
export const MoreActionsIcon = (props: any) => (
  <Icon component={MoreSvg} {...props} />
);

export const AssignSelfIcon = (props: any) => (
  <Icon component={AssignSelfSvg} {...props} />
);
export const AddIcon = (props: any) => <Icon component={AddSvg} {...props} />;

export const OrganizationIcon = (props: any) => (
  <Icon component={OrganizationSvg} {...props} />
);

export const MenuCircleFilledIcon = (props: any) => (
  <Icon component={MenuCircleFilledSvg} {...props} />
);

export const MenuSquareFilledIcon = (props: any) => (
  <Icon component={MenuSquareFilledSvg} {...props} />
);

export const UpArrowIcon = (props: any) => (
  <Icon component={UpArrowSvg} {...props} />
);

export const DownArrowIcon = (props: any) => (
  <Icon component={DownArrowSvg} {...props} />
);
export const RightArrowIcon = (props: any) => (
  <Icon component={RightArrowSvg} {...props} />
);
export const CaretLeftIcon = (props: any) => (
  <Icon component={CaretLeftSvg} {...props} />
);
export const CaretRightIcon = (props: any) => (
  <Icon component={CaretRightSvg} {...props} />
);

export const CaretDownIcon = (props: any) => (
  <Icon component={CaretDownSvg} {...props} />
);

export const DominoIcon = (props: any) => (
  <Icon component={DominoSvg} {...props} />
);
export const CloseIcon = (props: any) => (
  <Icon component={CloseSvg} {...props} />
);
export const DraftIcon = (props: any) => (
  <Icon component={DraftSvg} {...props} />
);
export const DeleteIcon = (props: any) => (
  <Icon component={DeleteSvg} {...props} />
);
export const ReactIcon = (props: any) => (
  <Icon component={ReactSvg} {...props} />
);
export const SuccessCircleFilledIcon = (props: any) => (
  <Icon component={SuccessCircleFilledSvg} {...props} />
);
export const ErrorCircleFilledIcon = (props: any) => (
  <Icon component={ErrorCircleFilledSvg} {...props} />
);
export const ErrorModalIcon = (props: any) => (
  <Icon component={ErrorModalSvg} {...props} />
);
export const DeleteConfirmationModalIcon = (props: any) => (
  <Icon component={DeleteConfirmationModalSvg} {...props} />
);
export const ExpandIcon = (props: any) => (
  <Icon component={ExpandSvg} {...props} />
);
export const SuperExpandIcon = (props: any) => (
  <Icon component={SuperExpandSvg} {...props} />
);
export const CollapseIcon = (props: any) => (
  <Icon component={CollapseSvg} {...props} />
);
export const ArrowDownIcon = (props: any) => (
  <Icon component={ArrowDownSvg} {...props} />
);
export const ArrowRightIcon = (props: any) => (
  <Icon component={ArrowRightSvg} {...props} />
);
export const CopyLinkIcon = (props: any) => (
  <Icon component={CopyLinkSvg} {...props} />
);
export const NoteIcon = (props: any) => <Icon component={NoteSvg} {...props} />;
export const TimeIcon = (props: any) => <Icon component={TimeSvg} {...props} />;
export const CommentIcon = (props: any) => (
  <Icon component={CommentSvg} {...props} />
);
export const TextIcon = (props: any) => <Icon component={TextSvg} {...props} />;
export const BoldIcon = (props: any) => <Icon component={BoldSvg} {...props} />;
export const ItalicIcon = (props: any) => (
  <Icon component={ItalicSvg} {...props} />
);
export const UnderlineIcon = (props: any) => (
  <Icon component={UnderlineSvg} {...props} />
);
export const StrikethroughIcon = (props: any) => (
  <Icon component={StrikethroughSvg} {...props} />
);
export const LinkIcon = (props: any) => <Icon component={LinkSvg} {...props} />;
export const BlockQuoteIcon = (props: any) => (
  <Icon component={BlockQuoteSvg} {...props} />
);
export const ImageIcon = (props: any) => (
  <Icon component={ImageSvg} {...props} />
);
export const AttachFileIcon = (props: any) => (
  <Icon component={AttachFileSvg} {...props} />
);
export const NoTaskIcon = (props: any) => (
  <Icon component={NoTaskSvg} {...props} />
);
export const NextShiftIcon = (props: any) => (
  <Icon component={NextShiftSvg} {...props} />
);
export const StarFilledIcon = (props: any) => (
  <Icon component={StarFilledSvg} {...props} />
);
export const StarOutlineIcon = (props: any) => (
  <Icon component={StarOutlineSvg} {...props} />
);
export const UndoIcon = (props: any) => <Icon component={UndoSvg} {...props} />;
export const HistoryIcon = (props: any) => (
  <Icon component={HistorySvg} {...props} />
);
export const TableGeneralViewIcon = (props: any) => (
  <Icon component={TableGeneralViewSvg} {...props} />
);
export const TableDetailedViewIcon = (props: any) => (
  <Icon component={TableDetailedViewSvg} {...props} />
);
export const NoMemberIcon = (props: any) => (
  <Icon component={NoMemberSvg} {...props} />
);
export const ClockIcon = (props: any) => (
  <Icon component={ClockSvg} {...props} />
);
export const MTagIcon = (props: any) => <Icon component={MTagSvg} {...props} />;
export const ManuallyAddedIcon = (props: any) => (
  <Icon component={ManuallyAddedSvg} {...props} />
);
export const RefreshIcon = (props: any) => (
  <Icon component={RefreshSvg} {...props} />
);
export const NoCheckoutIcon = (props: any) => (
  <Icon component={NoCheckoutSvg} {...props} />
);

export const LoadingIcon = (props: any) => <LoadingOutlined {...props} spin />;

export const ArrowPointerIcon = (props: any) => <ArrowPointerSvg {...props} />;

export const LabelIcon = (props: any) => (
  <Icon component={LabelSvg} {...props} />
);

export const MergeIcon = (props: any) => (
  <Icon component={MergeSvg} {...props} />
);

export const MoveIcon = (props: any) => <Icon component={MoveSvg} {...props} />;

export const ArchiveIcon = (props: any) => (
  <Icon component={ArchiveSvg} {...props} />
);
export const UserIcon = (props: any) => <Icon component={UserSvg} {...props} />;

export const WatchIcon = (props: any) => (
  <Icon component={WatchSvg} {...props} />
);

export const SettingIcon = (props: any) => (
  <Icon component={SettingSvg} {...props} />
);

export const ShowArchiveIcon = (props: any) => (
  <Icon component={ShowArchiveSvg} {...props} />
);

const EditAccountSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill="currentColor"
    transform="scale(-1, 1)"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" />
  </svg>
);
export const EditAccountIcon = (props: any) => (
  <Icon component={EditAccountSvg} {...props} />
);

const MailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
  </svg>
);
export const MailIcon = (props: any) => <Icon component={MailSvg} {...props} />;

const AccountCircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
  </svg>
);
export const AccountCircleIcon = (props: any) => (
  <Icon component={AccountCircleSvg} {...props} />
);

const BlockSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" />
  </svg>
);
export const BlockIcon = (props: any) => (
  <Icon component={BlockSvg} {...props} />
);

const UnarchiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill="currentColor"
  >
    <g>
      <rect fill="none" height="24" width="24" x="0" />
    </g>
    <g>
      <g>
        <g>
          <path d="M20.55,5.22l-1.39-1.68C18.88,3.21,18.47,3,18,3H6C5.53,3,5.12,3.21,4.85,3.55L3.46,5.22C3.17,5.57,3,6.01,3,6.5V19 c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6.5C21,6.01,20.83,5.57,20.55,5.22z M12,9.5l5.5,5.5H14v2h-4v-2H6.5L12,9.5z M5.12,5 l0.82-1h12l0.93,1H5.12z" />
        </g>
      </g>
    </g>
  </svg>
);
export const UnarchiveIcon = (props: any) => (
  <Icon component={UnarchiveSvg} {...props} />
);
