import axios from "../apis/django.instance";

export const getListArrayService = () => {
  return axios.get("/api/projects/task_list/");
};

export const getListRecordByIdService: GetListRecordByIdService = (id) => {
  return axios.get(`/api/projects/task_list/${id}/`);
};

export const createListRecordService: CreateListRecordService = (data) => {
  return axios.post("/api/projects/task_list/", data);
};

export const updateWorkDayRecordService: UpdateListRecordService = (data) => {
  const { id, ...payload } = data;
  return axios.put(`/api/projects/task_list/${id}/`, payload); // must be in this format in order to mock implementation in unit test
};

export const getListRemovedSectionsService = (listId: ListObject["id"]) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.get(`/api/projects/task_list_removed_sections/${listId}`);
};

export const getListSectionsWithArchivedTasksService = (
  listId: ListObject["id"]
) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.get(
    `/api/projects/task_list_sections_with_archived_tasks/${listId}`
  );
};

export const unarchiveTasksInSectionService = (
  sectionId: SectionObject["id"]
) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.put(`projects/unarchive_section_tasks/${sectionId}`);
};
