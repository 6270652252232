import { Dispatch } from "redux";

import { getWorkShiftScheduleService } from "../services/work-shift-schedule.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchWorkShiftSchedules = () => ({
  type: "REQUEST_FETCH_WORK_SHIFT_SCHEDULES",
});
export const requestFetchWorkShiftSchedulesSuccess = (
  workShiftSchedules: WorkShiftScheduleArray
) => ({
  type: "REQUEST_FETCH_WORK_SHIFT_SCHEDULES_SUCCESS",
  workShiftSchedules,
});
export const requestFetchWorkShiftSchedulesFailed = (error: string) => ({
  type: "REQUEST_FETCH_WORK_SHIFT_SCHEDULES_FAILED",
  error,
});
export const startPopulateWorkShiftSchedules = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchWorkShiftSchedules());
      const response = await getWorkShiftScheduleService();
      if (response.status === 200) {
        dispatch(requestFetchWorkShiftSchedulesSuccess(response.data));
      } else {
        const error = "getWorkShiftScheduleService status is not 200";
        dispatch(requestFetchWorkShiftSchedulesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchWorkShiftSchedulesFailed(error));
      dispatchError({
        e,
        title: "Populate work shift schedules error",
      });
    }
  };
};
