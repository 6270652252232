import React from "react";

import { NoTaskIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * Default display
 * Displays when global state tasks today_yn count is 0
 */
const TodayEmptyState = () => {
  return (
    <div className="TodayEmptyState">
      <div className="TodayEmptyState__Message">
        <NoTaskIcon className="TodayEmptyState__Message__Icon" />
        <span className="TodayEmptyState__Message__Title">
          No tasks in Today
        </span>
        <span className="TodayEmptyState__Message__Text">
          Here you can find all tasks that are scheduled for today or were
          tracked today.
        </span>
      </div>
    </div>
  );
};

export default TodayEmptyState;
