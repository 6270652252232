import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import cn from "classnames";
import moment from "moment";

import useOutsideClickCallback from "../../hooks/useOutsideClickCallback.hook";
import usePreviousHook from "../../hooks/usePrevious.hook";
import useRedirect from "../../hooks/useRedirect.hook";

import { LoadingIcon } from "../CustomIcons/CustomIcons.component";
import OverviewHeader from "./OverviewHeader/OverviewHeader.component";
import TaskListTable from "./TaskListTable/TaskListTable.component";
import MembersTable from "./MembersTable/MembersTable.component";
import RadioGroup from "../RadioGroup/RadioGroup.component";
import Modal from "../General/Modal/Modal";
import MembersSearchBar from "./MembersSearchBar/MembersSearchBar.component";
import GetStartedMessage from "./GetStartedMessage/GetStartedMessage.component";

import {
  startCreateListRecord,
  startPopulateLists,
  startUpdateListRecord,
  toggleListSuccess,
} from "../../actions/list.action";
import {
  startPopulateProjects,
  startUpdateProject,
} from "../../actions/projects.action";
import { startPopulateWorkspaceUsers } from "../../actions/workspace-users.action";
import { defaultPage } from "../../constants/constants";
import { selectListsState } from "../../reducers/list.reducer";
import { selectProjectsState } from "../../reducers/projects.reducer";
import { selectStatusState } from "../../reducers/status.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectWorkspaceUsersState } from "../../reducers/workspace-users.reducer";
import { selectListsInProject } from "../../selectors/list.selector";
import {
  getUsersByProjectService,
  addUserToProjectService,
  addUserToTaskListService,
  deleteUserFromProjectService,
  deleteUserFromTaskListService,
  updateUserofProjectService,
  updateUserofTaskListService,
  getBulkTaskListMembershipService,
} from "../../services/users.service";

import ws from "../../sockets/websockets";

const { TextArea } = Input;
type TaskListType = "active" | "archived";

/**
 * ProjectOverview is the component to display the project overview info.
 * It includes the project description component, task_list table and a members table.
 * If the current login user is the owner of this project, he is able to edit the project details;
 * Otherwise he is only allow to view the overview of a project and update the description.
 */
const ProjectOverview = () => {
  const redirect = useRedirect();
  
  const { projectId } = useParams<{ projectId: string }>();
  const currentUser = useSelector(selectUserDetailsState).data;
  const projects = useSelector(selectProjectsState).data;
  const status = useSelector(selectStatusState).data;
  const { data: lists, loading: listsLoading } = useSelector(selectListsState);
  const workspaceUsers = useSelector(selectWorkspaceUsersState).data;
  const dispatch = useDispatch();

  const project = React.useMemo(
    () => projects.find((p) => p.id === projectId),
    [projects, projectId]
  );

  const { loading: projectLoading } = project || {};

  // useMemo to avoid infinite loop
  const taskLists = React.useMemo(() => selectListsInProject(project, lists), [
    project,
    lists,
  ]);
  const descRef = useRef(null);
  const [editingDesc, setEditingDesc] = useState(false);
  const [desc, setDesc] = useState("");
  const [openTaskList, setOpenTaskList] = useState(false);
  const [editTaskList, setEditTaskList] = useState(false);
  const [taskListType, setTaskListType] = useState<TaskListType>("active");
  const [listLoading, setListLoading] = useState(false);
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const [projectMembers, setProjectMembers] = useState<ProjectUser[]>([]);
  const [listMembers, setListMembers] = useState<ListUser[]>([]);
  const [taskListForm] = Form.useForm();
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingLists, setLoadingLists] = useState(false);

  const accessType: "owner" | "member" = (
    projectMembers.find((member) => member.id === currentUser.id) || {
      accessType: "member",
    }
  ).accessType;

  // hide message after 1 week from project creation
  const hideMessageAt =
    project && project.created_date
      ? moment(project.created_date).add(1, "week")
      : moment();
  const isMessageHide = moment().isAfter(hideMessageAt);

  const taskListsData = taskLists
    .filter(({ archived_yn }) =>
      taskListType === "archived" ? archived_yn : !archived_yn
    )
    .map((list) => {
      const members = listMembers.filter((item) => item.taskListId === list.id);
      return {
        ...list,
        key: list.id,
        tasks: list.task_count,
        members: members.map((item) => {
          const curMember = projectMembers.find(
            (member) => member.id === item.id
          ) || { accessType: "", projectRole: "" };
          return {
            ...item,
            taskCount: list.task_count,
            accessType: curMember.accessType,
            projectRole: curMember.projectRole,
          };
        }),
        lastActive: list.latest_activity,
      };
    });

  const activeCount = taskLists.filter(({ archived_yn }) => !archived_yn)
    .length;
  const archivedCount = taskLists.filter(({ archived_yn }) => archived_yn)
    .length;
  const taskListOptions = [
    { title: "Active", subtitle: activeCount.toString(), value: "active" },
    {
      title: "Archived",
      subtitle: archivedCount.toString(),
      value: "archived",
    },
  ];
  const searchableMembers = workspaceUsers.filter(
    (user) => !projectMembers.find((item) => item.id === user.id)
  );

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType, meta } = messageEventData;
        switch (eventType.trim()) {
          case "add_user_to_project":
          case "update_user_project_role":
          case "remove_user_from_project": {
            const { project_or_tasklist_ID, user } = meta;
            if (
              project_or_tasklist_ID === projectId &&
              user !== currentUser.id
            ) {
              const members = await getUsersByProjectService(
                project_or_tasklist_ID
              );
              setProjectMembers(members);
            }
            break;
          }
          case "add_user_to_tasklist":
          case "remove_user_from_tasklist": {
            const { project_or_tasklist_ID } = meta;
            const exist = !!taskLists.find(
              (list) => list.id === project_or_tasklist_ID
            );
            if (exist) {
              dispatch(startPopulateLists());
            }
            break;
          }
          case "new_status": {
            // FIXME: need more specific info
            const members = await getUsersByProjectService(projectId);
            setProjectMembers(members);
            break;
          }
          default:
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (project) {
      setDesc(project.description || "");
    }
  }, [project]);

  React.useEffect(() => {
    dispatch(startPopulateWorkspaceUsers());
  }, [dispatch]);

  React.useEffect(() => {
    // fetch members for this project
    if (projectId) {
      setLoadingMembers(true);
      getUsersByProjectService(projectId).then((response) => {
        setProjectMembers(response);
        setLoadingMembers(false);
      });
    }
    // clean previous members when project id and status changed.
    return () => {
      setProjectMembers([]);
    };
    // add display_name here so it can be refreshed once user update the display name
  }, [projectId, status, currentUser.display_name]);

  React.useEffect(() => {
    // clean previous full task list when project id changed.
    return () => {
      setListMembers([]);
    };
  }, [projectId]);

  const listIds = taskLists.map((list) => list.id);

  const fetchTaskLists = useCallback(async () => {
    setLoadingLists(true);
    const result = await getBulkTaskListMembershipService(listIds);
    setListMembers(result);
    setLoadingLists(false);
  }, [JSON.stringify(listIds)]);

  React.useEffect(() => {
    fetchTaskLists();
  }, [fetchTaskLists]);

  const prevDesc = usePreviousHook(desc);

  // FIXME: this will cause register new listener whenever desc changed.
  useOutsideClickCallback(
    () => {
      if (desc !== prevDesc) handleDescSave();
    },
    [desc, prevDesc],
    descRef
  );

  const handleDescClick = () => {
    setEditingDesc(true);
  };

  const handleDescSave = () => {
    const payload = {
      id: projectId,
      description: desc,
    } as ProjectObject;
    dispatch(startUpdateProject(payload));
    setEditingDesc(false);
  };

  const handleDescChange = (e: any) => {
    setDesc(e.target.value);
  };

  const resetForm = () => {
    taskListForm.resetFields();
  };

  const handleOpenList = () => {
    setEditTaskList(false);
    setOpenTaskList(true);
  };

  const handleListOk = async () => {
    const values = await taskListForm.validateFields();
    setListLoading(true);
    let response: any;
    if (editTaskList) {
      // update task list
      let payload =
        lists.find((list: ListObject) => list.id === selectedListId) ||
        ({} as ListObject);
      payload = {
        ...payload,
        title: values.taskListName,
      };
      response = await dispatch(startUpdateListRecord(payload));
    } else {
      // create task list
      const payload = {
        parent_project: projectId,
        parent_type_enum: "project",
        archived_yn: false,
        removed_yn: false,
        title: values.taskListName,
        parent_user: null,
      } as CreateListRecordServiceData;
      response = await dispatch(startCreateListRecord(payload));
    }
    setListLoading(false);
    if (response.success) {
      resetForm();
      setOpenTaskList(false);
    } else if (response.message === "duplicated") {
      taskListForm.setFields([
        {
          name: "taskListName",
          errors: [
            "A task list with this name already exists. Enter a different name.",
          ],
        },
      ]);
    }
  };

  const handleCloseList = () => {
    resetForm();
    setOpenTaskList(false);
    setEditTaskList(false);
  };

  const handleTaskListTypeChange = (value: string) => {
    setTaskListType(value as TaskListType);
  };

  const handleTaskRename = (id: string) => {
    setEditTaskList(true);
    setOpenTaskList(true);
    setSelectedListId(id);
    const list = lists.find((list: ListObject) => list.id === id);
    const title = list ? list.title : "";
    taskListForm.setFieldsValue({ taskListName: title });
  };

  const handleTaskArchive = (id: string, archived: boolean) => {
    const list =
      lists.find((list: ListObject) => list.id === id) || ({} as ListObject);
    const payload = {
      ...list,
      archived_yn: archived,
    };
    dispatch(startUpdateListRecord(payload));
  };

  const handleAddToProject = async (id: string) => {
    const payload: AddUserToProjectParams = {
      project: projectId,
      user: id,
      accessType: "member",
      projectRole: "",
    };
    const result = await addUserToProjectService(payload);
    if (result) {
      setLoadingMembers(true);
      const members = await getUsersByProjectService(projectId);
      setProjectMembers(members);
      setLoadingMembers(false);
    }
  };

  const handleUserDeleteFromProject = async (projectUser: ProjectUser) => {
    const result = await deleteUserFromProjectService(projectUser.membershipId);
    if (result) {
      // if current user is deleted, redirect to main page.
      if (projectUser.id === currentUser.id) {
        dispatch(startPopulateProjects());
        redirect({
          path: defaultPage,
        });
        return;
      }
      setProjectMembers((prev) =>
        prev.filter((item) => item.id !== projectUser.id)
      );
    }
  };

  const handleAddMemberToList = async (userId: string, listId: string) => {
    const payload = {
      taskListId: listId,
      userId,
      projectRole: "",
    };
    const result = await addUserToTaskListService(payload);
    if (result) {
      await fetchTaskLists();
    }
  };

  const handleDeleteMemberFromList = async (membershipId: string) => {
    const result = await deleteUserFromTaskListService(membershipId);
    if (result) {
      await fetchTaskLists();
    }
  };

  const handleProjectArchive = () => {
    const payload = {
      id: projectId,
      archived_yn: true,
    } as ProjectObject;
    dispatch(startUpdateProject(payload));
  };

  const handleUpdateUser = async (user: ProjectUser) => {
    const data = {
      user: user.id,
      project: projectId,
      accessType: user.accessType,
      projectRole: user.projectRole,
      membershipId: user.membershipId,
    };
    const result = await updateUserofProjectService(data);
    if (result) {
      setProjectMembers((prev) =>
        prev.map((item) => {
          if (item.id === user.id) {
            return {
              ...item,
              accessType: user.accessType,
              projectRole: user.projectRole,
            };
          }
          return item;
        })
      );
    }
  };
  const handleSwitchToggle = async (
    listId: string,
    membershipId: string,
    checked: boolean
  ) => {
    const result = await updateUserofTaskListService({
      membershipId,
      show_list_in_sidebar_yn: checked,
    });
    if (result) {
      dispatch(toggleListSuccess(listId));
    }
  };

  return (
    <section className="ProjectOverview">
      <OverviewHeader
        project={project}
        accessType={accessType}
        onArchive={handleProjectArchive}
      />
      <div className="ProjectOverview__Main">
        {project && project.show_get_started_message_yn && !isMessageHide && (
          <GetStartedMessage project={project} />
        )}

        <div className="ProjectOverview__Section">
          <h2 className="ProjectOverview__Section-title">Description</h2>
          {projectLoading && (
            <div className="ProjectOverview__Section-content">
              <LoadingIcon />
            </div>
          )}

          {!projectLoading && editingDesc && (
            <div ref={descRef}>
              <TextArea
                className="ProjectOverview__Section-textArea"
                autoFocus={true}
                autoSize={true}
                defaultValue={desc}
                placeholder="Enter a project description."
                onChange={handleDescChange}
                onFocus={(e: any) => {
                  // update the cursor to the end
                  const val = e.target.value;
                  e.target.value = "";
                  e.target.value = val;
                }}
              />
            </div>
          )}

          {!projectLoading && !editingDesc && (
            <div
              className={cn("ProjectOverview__Section-content", {
                "ProjectOverview__Section-empty": !desc,
              })}
              onClick={handleDescClick}
            >
              {desc || "Enter a project description."}
            </div>
          )}
        </div>

        <div className="ProjectOverview__Section">
          <div className="ProjectOverview__Section-header">
            <h2 className="ProjectOverview__Section-title">Task Lists</h2>
            <div className="ProjectOverview__Section-operations">
              {accessType === "owner" && (
                <Tag onClick={handleOpenList}>
                  <PlusOutlined style={{ color: "#40BF00" }} /> Create new list
                </Tag>
              )}
              <RadioGroup
                options={taskListOptions}
                onChange={handleTaskListTypeChange}
              />
            </div>
          </div>
          <TaskListTable
            projectId={projectId}
            loading={listsLoading || !project || loadingLists}
            type={taskListType}
            data={taskListsData}
            projectMembers={projectMembers}
            accessType={accessType}
            onRename={handleTaskRename}
            onArchive={handleTaskArchive}
            onAddMember={handleAddMemberToList}
            onDeleteMember={handleDeleteMemberFromList}
            onSwitchToggle={handleSwitchToggle}
          />
        </div>

        <div className="ProjectOverview__Section">
          <div className="ProjectOverview__Section-header">
            <h2 className="ProjectOverview__Section-title">Members</h2>
            {accessType === "owner" && (
              <div className="ProjectOverview__Section-operations">
                <MembersSearchBar
                  className="ProjectOverview__SearchBar"
                  members={searchableMembers}
                  type="project"
                  width={400}
                  onMemberAdd={handleAddToProject}
                />
              </div>
            )}
          </div>
          <MembersTable
            projectId={projectId}
            type="project"
            loading={!project || loadingMembers}
            title={project ? project.title : ""}
            data={projectMembers}
            accessType={accessType}
            onSave={handleUpdateUser}
            onDelete={handleUserDeleteFromProject}
          />
        </div>
      </div>

      <Modal
        className="TaskListModal"
        title={editTaskList ? "Rename task list" : "Create new task list"}
        confirmLoading={listLoading}
        visible={openTaskList}
        maskClosable={false}
        okText={editTaskList ? "Done" : "Create"}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseList}
        onOk={handleListOk}
      >
        <Form form={taskListForm}>
          <Form.Item
            name="taskListName"
            rules={[{ required: true, message: "Enter a task list title." }]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="Enter a task list name"
            />
          </Form.Item>
        </Form>
      </Modal>
    </section>
  );
};

export default ProjectOverview;
