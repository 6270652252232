import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Badge, Menu, SubMenuProps, Tooltip } from "antd";
import cn from "classnames";
import { Feature } from "flagged";

import { history } from "../../routers/AppRouter";

import useRedirect from "../../hooks/useRedirect.hook";

import {
  OfficeIcon,
  ReportsIcon,
  CheckoutIcon,
  TodayIcon,
  NextShiftIcon,
  StarFilledIcon,
  TeamIcon,
  CollapseIcon,
  ExpandIcon,
  CaretDownIcon,
  HistoryIcon,
  LoadingIcon,
  InfoCircleOutlinedIcon,
} from "../CustomIcons/CustomIcons.component";
import CheckoutStatusIcon from "../Checkout/CheckoutStatusIcon/CheckoutStatusIcon";
import ListUnreadActivitiesCount from "../List/ListUnreadActivitiesCount/ListUnreadActivitiesCount";
import ProjectInitial from "../Projects/ProjectInitial/ProjectInitial";
import TrackingTag from "../TaskTimeTracking/TrackingTag/TrackingTag";
import VerticalMenuHeader from "./VerticalMenuHeader/VerticalMenuHeader";

import {
  menuStaticKeys,
  personalTasksProjectObject,
  projectsDefaultColorInMenu,
} from "../../constants/constants";
import { selectProjectsState } from "../../reducers/projects.reducer";
import { selectNextCalendarScheduleState } from "../../reducers/third-party-calendar.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectListsInProject } from "../../selectors/list.selector";
import { selectListsState } from "../../reducers/list.reducer";
import { formatUrlTitle } from "../../utils/url.util";

const { SubMenu } = Menu;

/**
 * Main menu of [In The Office View]
 *
 * Default Display
 * Displays menu items and projects
 *
 * Functionality
 * Allows user to set nav global state depending on key of menu item selected and navigate different panel content
 */
export interface VerticalMenuProps {
  todayTasksCount?: number;
  nextWorkDayTasksCount?: number;
  starredTasksCount?: number;
}
const VerticalMenu: React.FC<VerticalMenuProps> = ({
  todayTasksCount,
  nextWorkDayTasksCount,
  starredTasksCount,
}) => {
  const redirect = useRedirect();

  const { pathname, search } = useLocation();
  const { projectId, listId } = useParams<{
    projectId?: string;
    listId?: string;
  }>();

  const urlTaskPage = new URLSearchParams(search).get("p");

  const { data: projectsData, loading: projectsLoading } = useSelector(
    selectProjectsState
  );
  const { data: lists } = useSelector(selectListsState);
  const { data: userDetails } = useSelector(selectUserDetailsState);

  const [openedProjects, setOpenedProjects] = React.useState<string[]>([]);

  const unarchivedProjects = React.useMemo(
    () => projectsData.filter(({ archived_yn }) => !archived_yn),
    [projectsData]
  );
  const unarchivedLists = React.useMemo(
    () => lists.filter(({ archived_yn }) => !archived_yn),
    [lists]
  );
  const hasProjects = unarchivedProjects.length > 0;
  const { team_page_access_granted_yn: hasTeamPageAccess } = userDetails;

  React.useEffect(() => {
    if (listId) {
      const list = unarchivedLists.find((l) => l.id === listId);
      if (list && list.parent_project) {
        setOpenedProjects([list.parent_project]);
      }
    }
  }, [listId, unarchivedLists]);

  const handleSelectMenu = ({ key }: { key: string }) => {
    const keys = Object.entries(menuStaticKeys).map(([_, value]) => value);
    if (keys.includes(key)) {
      redirect({
        path: key,
      });
    }
  };

  const handleClickCollapse = () => {
    setOpenedProjects([]);
  };

  const handleClickExpand = () => {
    setOpenedProjects(unarchivedProjects.map(({ id }) => `${id}`));
  };

  const handleSubMenuTitleClick = (project: ProjectObject) => {
    redirect({
      path: `/p/${project.id}/${formatUrlTitle(project.title)}`,
    });
  };

  const handleProjectToggle = (key: string) => {
    setOpenedProjects((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };

  const handleListTitleClick = (list: ListObject) => {
    redirect({
      path: `/l/${list.id}/${formatUrlTitle(list.title)}`,
    });
  };

  const handleClickStableVersion = () => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has("beta")) {
      queryParams.delete("beta");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const projectsJsx = unarchivedProjects.map((project: ProjectObject) => {
    const listsInProject = selectListsInProject(
      project,
      unarchivedLists
    ).filter((list) => list.show_list_in_sidebar_yn);
    const navIsProject = projectId === (project.id || "").toString();
    const projectColor =
      project.title === personalTasksProjectObject.title
        ? projectsDefaultColorInMenu
        : project.color.value;
    return (
      <SubMenu
        className={cn("VerticalMenu__SubMenu", {
          "VerticalMenu__SubMenu--Selected": navIsProject,
        })}
        onTitleClick={() => {
          handleProjectToggle(project.id.toString());
          handleSubMenuTitleClick(project);
        }}
        key={project.id}
        icon={
          <>
            {navIsProject && (
              <div
                className="VerticalMenu__SubMenu--Selected__ActiveProjectBorder"
                style={{
                  backgroundColor: projectColor,
                }}
              ></div>
            )}
            <ProjectInitial
              project={project}
              isMenu={true}
              isActive={navIsProject}
            />
          </>
        }
        title={project.title}
      >
        {listsInProject.map((list) => {
          const { title: listTitle, id } = list;
          const navIsList = listId === id;
          return (
            <Menu.ItemGroup key={id}>
              <Menu.Item
                key={id}
                onClick={() => handleListTitleClick(list)}
                style={
                  navIsList
                    ? {
                        borderLeft: `2px solid ${projectColor}`,
                      }
                    : undefined
                }
              >
                <span className="VerticalMenu__SubMenu__ListTitle">
                  {listTitle}
                </span>
                <ListUnreadActivitiesCount list={list} />
              </Menu.Item>
            </Menu.ItemGroup>
          );
        })}
      </SubMenu>
    );
  });

  return (
    <div className="VerticalMenu">
      <VerticalMenuHeader />
      <Menu
        openKeys={openedProjects}
        mode="inline"
        theme="dark"
        className="VerticalMenu__menu"
        onSelect={handleSelectMenu}
        selectedKeys={[urlTaskPage ? `/${urlTaskPage}` : pathname]}
        expandIcon={(
          props: SubMenuProps & { isSubMenu: boolean; eventKey: string }
        ) => (
          <CaretDownIcon
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleProjectToggle(props.eventKey);
            }}
          />
        )}
      >
        <Menu.Item
          key={menuStaticKeys.OFFICE}
          icon={<OfficeIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Office
        </Menu.Item>

        {hasTeamPageAccess && (
          <Menu.Item
            key={menuStaticKeys.TEAM}
            icon={<TeamIcon />}
            className="VerticalMenu__menu__menu-item"
          >
            Team
          </Menu.Item>
        )}

        <Menu.Item
          key={menuStaticKeys.UNREAD}
          icon={<ReportsIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Unread
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.CHECKOUT}
          icon={<CheckoutIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Checkout
          <div className="VerticalMenu__menu__menu-item__righthand-icon vertical-center">
            <CheckoutStatusIcon />
          </div>
        </Menu.Item>

        <Menu.Item
          key="MY_DESK"
          className="VerticalMenu__menu__menu-header"
          disabled
        >
          <span className="VerticalMenu__menu__menu-header__text">My Desk</span>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.TODAY}
          icon={<TodayIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Today
          <Badge
            className="VerticalMenu__menu__menu-item__righthand-badge"
            count={todayTasksCount}
            showZero={true}
          />
          <Feature name="under_development">
            <TrackingTag />
          </Feature>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.NEXT_SHIFT}
          icon={<NextShiftIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Next Workday
          <Badge
            className="VerticalMenu__menu__menu-item__righthand-badge"
            count={nextWorkDayTasksCount}
            showZero={true}
          />
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.STARRED}
          icon={<StarFilledIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          Starred
          <Badge
            className="VerticalMenu__menu__menu-item__righthand-badge"
            count={starredTasksCount}
            showZero={true}
          />
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.HISTORY}
          icon={<HistoryIcon />}
          className="VerticalMenu__menu__menu-item"
        >
          History
        </Menu.Item>

        <Menu.Item key="PROJECTS" className="VerticalMenu__menu__menu-header">
          <span className="VerticalMenu__menu__menu-header__text">
            Projects
          </span>
          <div className="VerticalMenu__menu__menu-header__icons">
            <CollapseIcon onClick={handleClickCollapse} />
            <ExpandIcon onClick={handleClickExpand} />
          </div>
        </Menu.Item>

        {projectsLoading && (
          <Menu.Item
            key="PROJECTS_LOADING"
            className="VerticalMenu__menu__menu-item VerticalMenu__menu__menu-item--loading"
          >
            <LoadingIcon />
          </Menu.Item>
        )}
        {!projectsLoading && hasProjects && projectsJsx}
      </Menu>

      <Feature name="under_development">
        <div className="VerticalMenu__BetaIndicator">
          <span>Beta Version</span>
          <Tooltip
            title={
              <u
                style={{ cursor: "pointer" }}
                onClick={handleClickStableVersion}
              >
                Go to the stable version
              </u>
            }
          >
            <InfoCircleOutlinedIcon />
          </Tooltip>
        </div>
      </Feature>
    </div>
  );
};

export { VerticalMenu as default };
