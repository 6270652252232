import { Dispatch } from "redux";
import { findIndex } from "lodash";

import { store } from "../store/configureStore";

import {
  getWorkShiftListService,
  updateWorkShiftRecordService,
  UpdateWorkShiftRecordServiceData,
} from "../services/work-shift.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchWorkShifts = () => ({
  type: "REQUEST_FETCH_WORK_SHIFTS",
});
export const requestFetchWorkShiftsSuccess = (workShifts: WorkShiftArray) => ({
  type: "REQUEST_FETCH_WORK_SHIFTS_SUCCESS",
  workShifts,
});
export const requestFetchWorkShiftsFailed = (error: string) => ({
  type: "REQUEST_FETCH_WORK_SHIFTS_FAILED",
  error,
});
export const startPopulateWorkShifts = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchWorkShifts());
      const response = await getWorkShiftListService();
      if (response.status === 200) {
        dispatch(requestFetchWorkShiftsSuccess(response.data));
      } else {
        const error = "getWorkShiftListService status is not 200";
        dispatch(requestFetchWorkShiftsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchWorkShiftsFailed(error));
      dispatchError({
        e,
        title: "Populate work shifts error",
      });
    }
  };
};

export const requestUpdateWorkShift = () => ({
  type: "REQUEST_UPDATE_WORK_SHIFT",
});
export const requestUpdateWorkShiftSuccess = (workShifts: WorkShiftArray) => ({
  type: "REQUEST_UPDATE_WORK_SHIFT_SUCCESS",
  workShifts,
});
export const requestUpdateWorkShiftFailed = (error: string) => ({
  type: "REQUEST_UPDATE_WORK_SHIFT_FAILED",
  error,
});
export const startUpdateWorkShiftRecord = (
  data: UpdateWorkShiftRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestUpdateWorkShift());
      const response = await updateWorkShiftRecordService(data);
      if (response.status === 200) {
        const updatedWorkShift = response.data;
        const workShiftStateData = store.getState().workShifts.data;
        const updatedWorkShiftIndex = findIndex(workShiftStateData, [
          "id",
          updatedWorkShift.work_day,
        ]);
        dispatch(
          requestUpdateWorkShiftSuccess(
            updatedWorkShiftIndex !== -1
              ? workShiftStateData.splice(
                  updatedWorkShiftIndex,
                  1,
                  updatedWorkShift
                )
              : workShiftStateData
          )
        );
      } else {
        const error = "updateWorkShiftRecordService status is not 200";
        dispatch(requestUpdateWorkShiftFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestUpdateWorkShiftFailed(error));
      dispatchError({
        e,
        title: "Update work shift record error",
      });
    }
  };
};
